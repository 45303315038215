import * as Sentry from '@sentry/browser'
import { immer } from 'zustand/middleware/immer'

import api, { Schemas } from '~/clients/api-client'
import { SomeEntity } from '~/clients/api-guards'
import { day } from '~/utils/extendedDayjs'

import { PatientGroupOutZod, patientGroupOutZodSchema } from '../schemas/patientGroupOutZodSchema'
import { Slice } from '../store'
import { walkEntities } from '../utils/walkEntities'

export const ENTITY_TYPE = 'entity_type' as const

type EntityId = number

type EntityTable<T> = {
    byId: Record<EntityId, T>
    allIds: EntityId[]
} & Record<string, unknown>

function createEntityState<T>(): EntityTable<T> {
    return {
        byId: {},
        allIds: [],
    }
}

export const entityKeys = [
    'blockLocks',
    'blockSchedules',
    'departmentLocationAssignments',
    'departmentPractitionerAssignments',
    'departments',
    'locations',
    'locationSchedules',
    'patientGroupDefinitions',
    'patientGroups',
    'practitioners',
    'practitionerScheduleLocations',
    'practitionerSchedules',
    'practitionerScheduleStatuses',
    'ruleDefinitions',
    'sections',
    'waitingListItems',
    'specialities',
    'ageGroups',
    'hospitalSurgeryTypes',
    'surgeryTypeGroups',
    'surgeryTypeGroupAgeRestrictions',
    'surgeryTypeGroupHierarchies',
    'surgeryTypeGroupSpecialities',
    'hospitalSurgeryTypeGroupAssociations',
] as const
export type DiEntityKeys = typeof entityKeys
export type DiEntityKey = DiEntityKeys[number]

const entityStates = {
    blockLocks: createEntityState<Schemas['BlockLockOut']>(),
    blockSchedules: createEntityState<Schemas['BlockScheduleOut']>(),
    departmentLocationAssignments: createEntityState<Schemas['DepartmentLocationAssignmentOut']>(),
    departmentPractitionerAssignments: createEntityState<Schemas['DepartmentPractitionerAssignmentOut']>(),
    departments: createEntityState<Schemas['DepartmentOut']>(),
    locations: createEntityState<Schemas['LocationOut']>(),
    locationSchedules: createEntityState<Schemas['LocationScheduleOut']>(),
    patientGroupDefinitions: createEntityState<Schemas['PatientGroupDefinitionOut']>(),
    patientGroups: createEntityState<PatientGroupOutZod>(),
    practitioners: createEntityState<Schemas['PractitionerOut']>(),
    practitionerScheduleLocations: createEntityState<Schemas['PractitionerScheduleLocationOut']>(),
    practitionerSchedules: createEntityState<Schemas['PractitionerScheduleOut']>(),
    practitionerScheduleStatuses: createEntityState<Schemas['PractitionerScheduleStatusOut']>(),
    ruleDefinitions: createEntityState<Schemas['RuleDefinitionOut']>(),
    sections: createEntityState<Schemas['SectionOut']>(),
    waitingListItems: createEntityState<Schemas['WaitingListItemOut']>(),
    specialities: createEntityState<Schemas['SpecialityOut']>(),
    ageGroups: createEntityState<Schemas['AgeGroupOut']>(),
    hospitalSurgeryTypes: createEntityState<Schemas['HospitalSurgeryTypeOut']>(),
    surgeryTypeGroups: createEntityState<Schemas['SurgeryTypeGroupOut']>(),
    surgeryTypeGroupAgeRestrictions: createEntityState<Schemas['SurgeryTypeGroupAgeRestrictionOut']>(),
    surgeryTypeGroupHierarchies: createEntityState<Schemas['SurgeryTypeGroupHierarchyOut']>(),
    surgeryTypeGroupSpecialities: createEntityState<Schemas['SurgeryTypeGroupSpecialityOut']>(),
    hospitalSurgeryTypeGroupAssociations: createEntityState<Schemas['HospitalSurgeryTypeGroupAssociationOut']>(),
} satisfies Record<DiEntityKey, EntityTable<SomeEntity>>

const entityApiGuards = {
    blockSchedules: api.isBlockScheduleOut,
    departments: api.isDepartmentOut,
    departmentLocationAssignments: api.isDepartmentLocationAssignmentOut,
    departmentPractitionerAssignments: api.isDepartmentPractitionerAssignmentOut,
    locations: api.isLocationOut,
    locationSchedules: api.isLocationScheduleOut,
    patientGroupDefinitions: api.isPatientGroupDefinitionOut,
    patientGroups: (data: unknown): data is PatientGroupOutZod => {
        // Doing basic checks first to avoid calling Zod, which is computationally expensive
        if (typeof data !== 'object' || data === null) {
            return false
        }

        if (!('entity_type' in data) || data.entity_type !== 'patient_group') {
            return false
        }

        try {
            patientGroupOutZodSchema.parse(data)
            return true
        } catch {
            return false
        }
    },
    practitioners: api.isPractitionerOut,
    practitionerSchedules: api.isPractitionerScheduleOut,
    practitionerScheduleStatuses: api.isPractitionerScheduleStatusOut,
    practitionerScheduleLocations: api.isPractitionerScheduleLocationOut,
    ruleDefinitions: api.isRuleDefinitionOut,
    sections: api.isSectionOut,
    blockLocks: api.isBlockLockOut,
    waitingListItems: api.isWaitingListItemOut,
    specialities: api.isSpecialityOut,
    ageGroups: api.isAgeGroupOut,
    surgeryTypeGroups: api.isSurgeryTypeGroupOut,
    hospitalSurgeryTypes: api.isHospitalSurgeryTypeOut,
    surgeryTypeGroupAgeRestrictions: api.isSurgeryTypeGroupAgeRestrictionOut,
    surgeryTypeGroupHierarchies: api.isSurgeryTypeGroupHierarchyOut,
    surgeryTypeGroupSpecialities: api.isSurgeryTypeGroupSpecialityOut,
    hospitalSurgeryTypeGroupAssociations: api.isHospitalSurgeryTypeGroupAssociationOut,
} satisfies Record<DiEntityKey, (entity: SomeEntity) => boolean>

type State = {
    entities: typeof entityStates
}

type Actions = {
    actions: {
        addEntities: (entities: SomeEntity[]) => void
        removeEntity: (key: DiEntityKey, id: EntityId) => void
        removeEntities: (key: DiEntityKey, ids: EntityId[]) => void
    }
}

export type DiSlice = {
    di: State & Actions
}

export const createDiSlice: Slice<DiSlice> = immer(set => ({
    di: {
        entities: entityStates,
        actions: {
            addEntities: entities => {
                Sentry.startSpan({ name: 'addEntities' }, () => {
                    set(state => {
                        const affectedKeys = new Set<DiEntityKey>()

                        walkEntities(entities, entity => {
                            for (const key of entityKeys) {
                                if (entityApiGuards[key](entity)) {
                                    const current = state.di.entities[key].byId[entity.id]

                                    if (current && (current.updated_at === entity.updated_at || day(current?.updated_at).isAfter(day(entity.updated_at)))) {
                                        return
                                    }

                                    affectedKeys.add(key)
                                    state.di.entities[key].byId[entity.id] = entity
                                }
                            }
                        })

                        for (const key of affectedKeys) {
                            state.di.entities[key].allIds = Object.keys(state.di.entities[key].byId).map(Number)
                        }
                    })
                })
            },
            removeEntity: (key, id) => {
                set(state => {
                    if (state.di.entities[key].byId[id]) {
                        delete state.di.entities[key].byId[id]
                    }

                    state.di.entities[key].allIds = Object.keys(state.di.entities[key].byId).map(Number)
                })
            },
            removeEntities: (key, ids) => {
                set(state => {
                    for (const id of ids) {
                        if (state.di.entities[key].byId[id]) {
                            delete state.di.entities[key].byId[id]
                        }
                    }

                    state.di.entities[key].allIds = Object.keys(state.di.entities[key].byId).map(Number)
                })
            },
        },
    },
}))

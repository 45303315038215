import { Dayjs } from 'dayjs'
import { createSelector } from 'reselect'

import { getAssistants, getSurgeons } from '~/utils/dips'

import { selectGetBlockSchedules } from './blockSchedules'
import { Location } from './entities'
import { evaluateBlock, findMismatchedSurgeries } from './internal/block_evaluations/rules'
import { selectGetResolvedPatientGroups } from './resolvedPatientGroups'
import { selectGetScheduledSurgeries } from './scheduledSurgeries'

export const selectGetOccupancies = createSelector(
    selectGetResolvedPatientGroups,
    selectGetBlockSchedules,
    selectGetScheduledSurgeries,
    (getResolvedPatientGroups, getBlockSchedules, getScheduledSurgeries) => {
        return {
            byDateAndLocation: (date: Dayjs, location: Location) => {
                const locationId = location.id
                const blockSchedule = getBlockSchedules.byDateAndLocationId(date, locationId)
                const bookedSurgeries = getScheduledSurgeries.byDateAndRoomCode(date, location.room_code)
                const allSurgeryResources = bookedSurgeries.flatMap(surgery => surgery.surgeryResources)

                const bookedSurgeons = getSurgeons(allSurgeryResources)
                const bookedAssistants = getAssistants(allSurgeryResources)
                const allBookedSurgeons = [...bookedSurgeons, ...bookedAssistants]

                const evaluations = evaluateBlock(blockSchedule, bookedSurgeries, getResolvedPatientGroups)
                const mismatchedSurgeries = findMismatchedSurgeries(bookedSurgeries, blockSchedule, getResolvedPatientGroups)

                return {
                    date,
                    location,
                    blockSchedule,
                    evaluations,
                    bookedSurgeries,
                    allBookedSurgeons,
                    mismatchedSurgeries,
                }
            },
        }
    }
)

type GetOccupancies = ReturnType<typeof selectGetOccupancies>
export type OccupancyData = ReturnType<GetOccupancies['byDateAndLocation']>

import { WaitingListItemOut } from '~/store/diApi'
import { createWaitingListItem, updateWaitingListItem } from '~/store/entity.api'

let timerId: NodeJS.Timeout | undefined = undefined

type Payload = { booking_id: string; comment: string }

export function useSaveComment(existingItem: WaitingListItemOut | undefined): (payload: Payload) => void {
    function saveComment(payload: Payload) {
        payload.comment = payload.comment.trim()

        if (!existingItem) {
            void createWaitingListItem(payload)
        } else {
            void updateWaitingListItem(existingItem.id, payload)
        }
    }

    return payload => {
        clearTimeout(timerId)

        timerId = setTimeout(() => saveComment(payload), 200)
    }
}

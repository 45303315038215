import stringify from 'fast-safe-stringify'
import isObject from 'lodash/isObject'

import { RuleEvaluation } from '~/store/utils/blockEvaluation'

import { CapacityRuleType } from './rules/blocks/types'

type CapacityRuleFingerprintV1 = Partial<Record<CapacityRuleType, number | null>>

type FingerprintV1 = {
    v: 1
    data: CapacityRuleFingerprintV1[]
}

/**
 * The fingerprint is used to determine if relevant information has changed in order
 * to decide if a BlockLock should be deleted, which will show availability in the calendar.
 *
 * Right now we're going with a simple approach where we just do a deep equality check between
 * the saved fingerprint and the actual fingerprint.
 *
 * Version 1 fingerprint is just availability data.
 */
export function createFingerprintV1(evaluations: RuleEvaluation[]): string {
    const data = evaluations
        .filter(evaluation => evaluation.status === 'Available' && evaluation.remaining !== null)
        .map(function (evaluation): CapacityRuleFingerprintV1 {
            if (evaluation.remaining === null || typeof evaluation.remaining === 'number') {
                return { [evaluation.type]: evaluation.remaining }
            }

            return { [evaluation.type]: evaluation.remaining.asHours() }
        })

    const fingerprint: FingerprintV1 = { v: 1, data }
    return stringify(fingerprint)
}

export function parseFingerprint(fingerprint: string | null): FingerprintV1 | null {
    if (!fingerprint) {
        return null
    }

    let parsed

    try {
        parsed = JSON.parse(fingerprint)
    } catch (e) {
        console.error('Failed to parse fingerprint', e)
        return null
    }

    if (!isObject(parsed)) {
        console.error('Fingerprint is not an object', parsed)
        return null
    }

    if (!('v' in parsed) || typeof parsed.v !== 'number') {
        console.error('Fingerprint is missing version', parsed)
        return null
    }

    if (parsed.v === 1) {
        return fingerprint as unknown as FingerprintV1
    }

    console.error('Unsupported fingerprint version', parsed.v)
    return null
}

import { SimplifyDeep } from 'type-fest'

import { DipsSchemas, getClient } from '~/clients/dips-client'
import env from '~/env'

import { useStore } from './store'
import { withFetchObserver } from './utils/fetchObserver'

export type FullScheduledSurgeryWithId = SimplifyDeep<{ id: string } & Omit<DipsSchemas['FullScheduledSurgery'], 'id'>>

function isFullScheduledSurgeryWithId(entity: DipsSchemas['FullScheduledSurgery']): entity is FullScheduledSurgeryWithId {
    return typeof entity.id === 'string'
}

export const importFullScheduledSurgeries = withFetchObserver(
    async (startTime: string, endTime: string, treatmentLocationId?: number) => {
        const response = await getClient().GET('/api/v1/surgery/scheduled', {
            params: {
                query: {
                    ...(treatmentLocationId && { TreatmentLocationId: treatmentLocationId }),
                    StartTime: startTime,
                    EndTime: endTime,
                    View: 'full',
                },
            },
        })

        const entities = response.data ?? []
        const validEntities = entities.filter(isFullScheduledSurgeryWithId)

        if (validEntities.length !== entities.length) {
            console.warn('Some full scheduled surgeries are missing ids')
        }

        useStore.getState().dips.actions.addFullScheduledSurgeries(validEntities)

        return validEntities
    },
    'id',
    {
        abandoned: ids => {
            useStore.getState().dips.actions.removeFullScheduledSurgeries(ids)
        },
    }
)

export type UnScheduledSurgeryWithBookingId = SimplifyDeep<{ bookingId: string } & Omit<DipsSchemas['UnScheduledSurgery'], 'bookingId'>>

function isUnScheduledSurgeryWithBookingId(entity: DipsSchemas['UnScheduledSurgery']): entity is UnScheduledSurgeryWithBookingId {
    return typeof entity.bookingId === 'string'
}

export const importUnScheduledSurgeries = async () => {
    const response = await getClient().GET('/api/v1/surgery/waiting', {
        params: { query: { TreatmentLocationId: Number(env.VITE_DIPS_WAITINGLIST_ID) } },
    })

    const entities = response.data?.surgeryWaitingList ?? []
    const validEntities = entities.filter(isUnScheduledSurgeryWithBookingId)

    if (validEntities.length !== entities.length) {
        console.warn('Some unscheduled surgeries are missing bookingIds')
    }

    useStore.getState().dips.actions.addUnScheduledSurgeries(validEntities)
}

export type SurgeryTypeWithId = SimplifyDeep<{ id: number } & Omit<DipsSchemas['CodeListDto'], 'id'>>

function isSurgeryTypeWithId(entity: DipsSchemas['CodeListDto']): entity is SurgeryTypeWithId {
    return typeof entity.id === 'number'
}

export const importSurgeryTypes = async () => {
    const response = await getClient().GET('/api/v1/organization/code-list/{codelistid}', {
        params: { path: { codelistid: 2507 } },
    })

    const entities = response.data?.codes ?? []
    const validEntities = entities.filter(isSurgeryTypeWithId)

    if (validEntities.length !== entities.length) {
        console.warn('Some surgery types are missing ids')
    }

    useStore.getState().dips.actions.addSurgeryTypes(validEntities)
}

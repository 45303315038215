/* eslint-disable @typescript-eslint/no-redundant-type-constituents */

import { Schemas } from '~/clients/api-client'
import { isNullish } from '~/utils/guards'

import {
    MaxCount,
    MaxCountOfSurgeriesPerSurgeryTypeGroup,
    MaxCountOfSurgeriesPerSurgeryTypeGroup2,
    MaxCountRuleCreate,
    MaxCountRuleOut,
    MaxDuration,
    MaxDurationAndCountRuleCreate,
    MaxDurationAndCountRuleOut,
    MaxDurationAndMaxCount,
    MaxDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup,
    MaxDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup2,
    MaxDurationOfSurgeriesPerSurgeryTypeGroup,
    MaxDurationOfSurgeriesPerSurgeryTypeGroup2,
    MaxDurationRuleCreate,
    MaxDurationRuleOut,
    MaxKnifeDuration,
    MaxKnifeDurationAndCountRuleCreate,
    MaxKnifeDurationAndCountRuleOut,
    MaxKnifeDurationAndMaxCount,
    MaxKnifeDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup,
    MaxKnifeDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup2,
    MaxKnifeDurationOfSurgeriesPerSurgeryTypeGroup,
    MaxKnifeDurationOfSurgeriesPerSurgeryTypeGroup2,
    MaxKnifeDurationRuleCreate,
    MaxKnifeDurationRuleOut,
    MaxWeight,
    MaxWeightOfSurgeriesPerSurgeryTypeGroup,
    MaxWeightOfSurgeriesPerSurgeryTypeGroup2,
    MaxWeightRuleCreate,
    MaxWeightRuleOut,
} from '../diApi'

export type RuleDefinitionId =
    | 'max_count_of_surgeries_per_patient_group'
    | 'max_weight_of_surgeries_per_patient_group'
    | 'max_duration_of_surgeries_per_patient_group'
    | 'max_duration_and_max_count_of_surgeries_per_patient_group'
    | 'max_knife_time_of_surgeries_per_patient_group'
    | 'max_knife_time_and_max_count_of_surgeries_per_patient_group'

export function isRuleDefinitionId(ruleId: string | undefined | null): ruleId is RuleDefinitionId {
    if (isNullish(ruleId)) {
        return false
    }

    return [
        'max_count_of_surgeries_per_patient_group',
        'max_weight_of_surgeries_per_patient_group',
        'max_duration_of_surgeries_per_patient_group',
        'max_duration_and_max_count_of_surgeries_per_patient_group',
        'max_knife_time_of_surgeries_per_patient_group',
        'max_knife_time_and_max_count_of_surgeries_per_patient_group',
    ].includes(ruleId)
}

export type RuleInstanceConstraints = MaxCount | MaxWeight | MaxDuration | MaxDurationAndMaxCount | MaxKnifeDuration | MaxKnifeDurationAndMaxCount

export type RuleInstanceParameters =
    | MaxCountOfSurgeriesPerSurgeryTypeGroup
    | MaxWeightOfSurgeriesPerSurgeryTypeGroup
    | MaxDurationOfSurgeriesPerSurgeryTypeGroup
    | MaxDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup
    | MaxKnifeDurationOfSurgeriesPerSurgeryTypeGroup
    | MaxKnifeDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup
    | undefined
    | null

export type RuleInstanceCreateParameters =
    | MaxCountOfSurgeriesPerSurgeryTypeGroup2
    | MaxWeightOfSurgeriesPerSurgeryTypeGroup2
    | MaxDurationOfSurgeriesPerSurgeryTypeGroup2
    | MaxDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup2
    | MaxKnifeDurationOfSurgeriesPerSurgeryTypeGroup2
    | MaxKnifeDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup2

export type RuleInstanceCreate =
    | MaxCountRuleCreate
    | MaxWeightRuleCreate
    | MaxDurationRuleCreate
    | MaxDurationAndCountRuleCreate
    | MaxKnifeDurationRuleCreate
    | MaxKnifeDurationAndCountRuleCreate
    | undefined
    | null

export type RuleInstanceOut =
    | MaxCountRuleOut
    | MaxWeightRuleOut
    | MaxDurationRuleOut
    | MaxDurationAndCountRuleOut
    | MaxKnifeDurationRuleOut
    | MaxKnifeDurationAndCountRuleOut
    | undefined
    | null

export type RuleInstanceSchema =
    | Schemas['MaxCountRuleOut']
    | Schemas['MaxWeightRuleOut']
    | Schemas['MaxDurationRuleOut']
    | Schemas['MaxDurationAndCountRuleOut']
    | Schemas['MaxKnifeDurationRuleOut']
    | Schemas['MaxKnifeDurationAndCountRuleOut']

export function createRuleInstance(localRuleId: string | undefined, ruleParameters: RuleInstanceCreateParameters | undefined): RuleInstanceCreate | undefined {
    if (!localRuleId || !ruleParameters) {
        return undefined
    }

    if (localRuleId === 'max_count_of_surgeries_per_patient_group') {
        return {
            name: '',
            rule_definition_id: localRuleId,
            parameters: ruleParameters,
        } as MaxCountRuleCreate
    }
    if (localRuleId === 'max_weight_of_surgeries_per_patient_group') {
        return {
            name: '',
            rule_definition_id: localRuleId,
            parameters: ruleParameters,
        } as MaxWeightRuleCreate
    }
    if (localRuleId === 'max_duration_of_surgeries_per_patient_group') {
        return {
            name: '',
            rule_definition_id: localRuleId,
            parameters: ruleParameters,
        } as MaxDurationRuleCreate
    }
    if (localRuleId === 'max_duration_and_max_count_of_surgeries_per_patient_group') {
        return {
            name: '',
            rule_definition_id: localRuleId,
            parameters: ruleParameters,
        } as MaxDurationAndCountRuleCreate
    }
    if (localRuleId === 'max_knife_time_of_surgeries_per_patient_group') {
        return {
            name: '',
            rule_definition_id: localRuleId,
            parameters: ruleParameters,
        } as MaxKnifeDurationRuleCreate
    }
    if (localRuleId === 'max_knife_time_and_max_count_of_surgeries_per_patient_group') {
        return {
            name: '',
            rule_definition_id: localRuleId,
            parameters: ruleParameters,
        } as MaxKnifeDurationAndCountRuleCreate
    }

    return undefined
}

import { Drawer } from '~/components'
import { CloseOutlined } from '~/icons'
import { useStore } from '~/store/store'

import { Contents } from './Contents'
import { WaitingListDrawerHeader } from './Header'

export const WaitingListDrawer = () => {
    const { setDrawerProps } = useStore(state => state.waitingList.actions)
    const drawerProps = useStore(state => state.waitingList.drawerProps)

    function onClose() {
        setDrawerProps(null)
    }

    return (
        <Drawer key={drawerProps?.item.BookingId} isOpen={drawerProps !== null} onClose={() => onClose()}>
            <div className="flex h-full flex-col">
                <div className="border-b px-6 py-4">
                    <div onClick={onClose} className="absolute right-3 top-2 cursor-pointer rounded-full p-2 hover:bg-slate-300">
                        <CloseOutlined />
                    </div>
                    <WaitingListDrawerHeader />
                </div>

                <div className="overflow-auto px-6 py-4">
                    <Contents />
                </div>
            </div>
        </Drawer>
    )
}

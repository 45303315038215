import { MaxWeightOfSurgeriesPerSurgeryTypeGroup } from '~/store/diApi'
import { GetResolvedPatientGroups } from '~/store/selectors'
import { isNullish } from '~/utils/guards'

import { maxWeightRule } from '../../rules/implementations/maxWeightRule'
import { CapacityRule } from '../types'

/**
 * implementation of the block rule: max_weight_of_surgeries_per_patient_group
 * @param parameters shall contain the parameters of the rule as an array of type {max_count: number, patient_group: string}
 */
export function getMaxWeightRules(parameters: MaxWeightOfSurgeriesPerSurgeryTypeGroup, getPatientGroups: GetResolvedPatientGroups): CapacityRule[] {
    const capacityRules: CapacityRule[] = []

    for (const constraint of parameters.constraints) {
        const patientGroup = getPatientGroups.byConstraints(constraint.patient_group)

        if (isNullish(patientGroup?.surgeryTypeGroup)) {
            console.error(`Surgery Type group "${constraint.patient_group.patient_group_id}" not found (max_weight_of_surgeries_per_patient_group)`)
        } else {
            const rule = maxWeightRule(constraint.max_weight, patientGroup)
            capacityRules.push(rule)
        }
    }

    return capacityRules
}

import dayjs from 'dayjs'
import { useState } from 'react'

import { Button, CollapsibleCard, PractitionerTags, Show, Tag } from '~/components'
import env from '~/env'
import { CalendarTodayOutlined, EventAvailableOutlined } from '~/icons'
import { FullScheduledSurgery, UnscheduledSurgery } from '~/store/dipsApi'
import { getDayOvernight } from '~/store/slices/filterSlice'
import { formatPersonalIdWithLastName, getAssistants, getSurgeons } from '~/utils/dips'
import { day, format, formatDuration } from '~/utils/extendedDayjs'

import { BookingDialog } from './BookingDialog/BookingDialog'

export const zeroDurationToken = 'P0DT0H00M0.000000S'

type Props = {
    surgery: { type: 'scheduled'; value: FullScheduledSurgery } | { type: 'unscheduled'; value: UnscheduledSurgery }
}

export const SurgeryCard = ({ surgery }: Props) => {
    const [showBookingDialog, setShowBookingDialog] = useState<boolean>(false)

    const info = surgery.value
    const patient = info.patient

    const asa = info.surgeryOrderDetails?.asa
    const nprCodeName = info.contact?.levelOfCareNpr?.nprCodeName
    const dayOvernight = getDayOvernight(nprCodeName)

    const checkInTime = format(day(info.contact?.checkInTime), 'HH:mm')
    const plannedStartTime = 'plannedStartTime' in info ? format(day(info.plannedStartTime), 'HH:mm') : '--:--'
    const knifeTime = dayjs.duration(info.plannedProcedureDuration ?? zeroDurationToken)

    const practitioners = [...getSurgeons(info.surgeryResources), ...getAssistants(info.surgeryResources)]
    const onCloseDialog = () => setShowBookingDialog(false)

    const CardContent = () => (
        <div className="pb-6 pt-4">
            {info.contact?.coordinationComment && (
                <div className="flex flex-col gap-1 pb-2">
                    <p className="text-sm font-semibold">Oppmøtekommentar:</p>
                    <p className="text-sm"> {info.contact?.coordinationComment}</p>
                </div>
            )}
            <PractitionerTags practitioners={practitioners} style="blue" />
            {env.VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_BOOKING_BUTTON && surgery.type === 'unscheduled' && (
                <Button color="secondary" onClick={() => setShowBookingDialog(true)} className="mt-4" data-test="order-button">
                    Bestill operasjon
                </Button>
            )}
        </div>
    )

    return (
        <>
            <CollapsibleCard
                variant={surgery.type === 'unscheduled' ? 'light' : 'default'}
                title={info.surgeryType?.name ?? ''}
                icon={surgery.type === 'scheduled' ? <EventAvailableOutlined width={20} height={20} /> : <CalendarTodayOutlined width={20} height={20} />}
                secondaryIcon={
                    dayOvernight && (
                        <div className="flex items-center gap-1">
                            {dayOvernight.icon}
                            <p>{dayOvernight.label}</p>
                        </div>
                    )
                }
                collapsedContent={
                    <div className="flex flex-col gap-4">
                        <div className="flex items-center justify-between">
                            <p className="">{formatPersonalIdWithLastName(patient)}</p>
                            <Show condition={!!asa}>
                                <Tag color="ASA" textSize="sm">
                                    {asa}
                                </Tag>
                            </Show>
                        </div>

                        <div className="flex justify-between">
                            <p className="text-sm">Oppmøte: {checkInTime}</p>
                            <p className="text-sm">Stue inn: {plannedStartTime}</p>
                            <p className="text-sm">Knivtid: {knifeTime.asSeconds() > 0 ? formatDuration(knifeTime.toISOString()) : '- min'}</p>
                        </div>
                    </div>
                }
            >
                <CardContent />
            </CollapsibleCard>
            <div data-test={surgery.type === 'unscheduled' ? 'BookingDrawerPatients' : 'ScheduledSurgery'} hidden />
            {surgery.type === 'unscheduled' && <BookingDialog isOpen={showBookingDialog} onCloseDialog={onCloseDialog} waitingListItem={info} />}
        </>
    )
}

import { makePatientGroupKey, ResolvedPatientGroup, ScheduledSurgery } from '~/store/selectors'
import { isNotNullish } from '~/utils/guards'

import { CapacityRule } from '../../blocks/types'
import { getPatientGroupToDisplay } from '../../patient_groups/patientGroups'
import { newCountBasedRule, SurgeryCounts } from '../countBasedRule'

export function maxCountRule(maxCount: number | null, patientGroup: ResolvedPatientGroup | null): CapacityRule {
    return newCountBasedRule(patientGroup, maxCount, (surgery: ScheduledSurgery, patientGroup?: ResolvedPatientGroup | null) => {
        const occupancy: SurgeryCounts = new Map()
        const patientGroupToDisplay = getPatientGroupToDisplay(surgery, patientGroup)
        if (isNotNullish(patientGroupToDisplay)) {
            const key = makePatientGroupKey(patientGroupToDisplay)
            occupancy.set(key, { patientGroup: patientGroupToDisplay, value: 1, bookingIds: [surgery.id] })
        }
        return occupancy
    })
}

import { useMemo } from 'react'

import { MultipleSelectWithTitle } from '~/components'
import { selectGetUnScheduledSurgeries } from '~/store/selectors'
import { selectOperationTypeValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

function useGetPatientGroupsOptions() {
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const getUnScheduleSurgeries = useStore(selectGetUnScheduledSurgeries)
    const unScheduledSurgeries = getUnScheduleSurgeries.byDepartmentKey(departmentKey)

    const surgeryNames = useMemo(() => unScheduledSurgeries.map(({ surgeryType }) => surgeryType?.name).filter(Boolean) ?? [], [unScheduledSurgeries])

    const uniqueSurgeryNames = useMemo(() => Array.from(new Set(surgeryNames)), [surgeryNames])

    return uniqueSurgeryNames.map(name => ({ value: name, label: name }))
}

export const OperationTypesFilter = () => {
    const patientGroupsListOptions = useGetPatientGroupsOptions()
    const operationTypeValues = useStore(selectOperationTypeValues)
    const toggleFilter = useStore(state => state.appFilters.actions.toggleFilter)
    const removeAllFilterByType = useStore(state => state.appFilters.actions.removeAllFilterByType)
    const setSelectedPage = useStore(state => state.waitingList.actions.setSelectedPage)

    const handleOnChange = (value: string, label: string) => {
        toggleFilter({ type: 'operation-type', value, label })
        setSelectedPage(1)
    }

    const handleEmptyFilter = () => {
        removeAllFilterByType('operation-type')
        setSelectedPage(1)
    }

    return (
        <MultipleSelectWithTitle
            title="Operasjonstype"
            values={operationTypeValues}
            options={patientGroupsListOptions}
            onChange={({ value, label }) => handleOnChange(value, label)}
            emptyFilter={handleEmptyFilter}
        />
    )
}

import { GetResolvedPatientGroups, PatientGroupConstraints } from '~/store/selectors/resolvedPatientGroups'
import { isNullish } from '~/utils/guards'

import { getMaxCountRules } from './implementations/maxCountRules'
import { getMaxDurationAndMaxCountRules as getMaxDurationAndMaxCountRules } from './implementations/maxDurationAndMaxCountRules'
import { getMaxDurationRules as getMaxDurationRules } from './implementations/maxDurationRules'
import { getMaxKnifeTimeAndMaxCountRules as getMaxKnifeDurationAndMaxCountRules } from './implementations/maxKnifeTimeAndMaxCountRules'
import { getMaxKnifeTimeRules as getMaxKnifeDurationRules } from './implementations/maxKnifeTimeRules'
import { getMaxWeightRules } from './implementations/maxWeightRules'
import {
    BlockRule,
    CapacityRule,
    isMaxCountRule,
    isMaxDurationAndCountRule,
    isMaxDurationRule,
    isMaxKnifeDurationAndCountRule,
    isMaxKnifeDurationRule,
    isMaxWeightRule,
} from './types'

export function getAllPatientGroupConstraints(blockRule: BlockRule): PatientGroupConstraints[] {
    if (isNullish(blockRule)) {
        return []
    }

    if (isMaxCountRule(blockRule) || isMaxWeightRule(blockRule)) {
        return blockRule.parameters.constraints.map(constraint => constraint.patient_group)
    }

    return blockRule.parameters.constraints?.patient_groups?.map(constraint => constraint.patient_group) ?? []
}

/**
 * Returns the implementation of the block as a composition of rules
 * @param blockRule
 * @returns a list of rules
 */
export function getCapacityRules(blockRule: BlockRule, selectGetPatientGroups: GetResolvedPatientGroups): CapacityRule[] {
    if (isMaxCountRule(blockRule)) {
        return getMaxCountRules(blockRule.parameters, selectGetPatientGroups)
    }
    if (isMaxWeightRule(blockRule)) {
        return getMaxWeightRules(blockRule.parameters, selectGetPatientGroups)
    }
    if (isMaxDurationRule(blockRule)) {
        return getMaxDurationRules(blockRule.parameters, selectGetPatientGroups)
    }
    if (isMaxDurationAndCountRule(blockRule)) {
        return getMaxDurationAndMaxCountRules(blockRule.parameters, selectGetPatientGroups)
    }
    if (isMaxKnifeDurationRule(blockRule)) {
        return getMaxKnifeDurationRules(blockRule.parameters, selectGetPatientGroups)
    }
    if (isMaxKnifeDurationAndCountRule(blockRule)) {
        return getMaxKnifeDurationAndMaxCountRules(blockRule.parameters, selectGetPatientGroups)
    }

    return []
}

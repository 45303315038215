import dayjs from 'dayjs'

import { MaxKnifeDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup } from '~/store/diApi'
import { GetResolvedPatientGroups } from '~/store/selectors'
import { isNullish } from '~/utils/guards'

import { maxCountRule } from '../../rules/implementations/maxCountRule'
import { maxKnifeTimeRule } from '../../rules/implementations/maxKnifeTimeRule'
import { CapacityRule } from '../types'

/**
 * implementation of the block rule: max_knife_time_and_max_count_of_surgeries_per_patient_group
 * @param parameters shall contain the parameters of the rule as an array of type {max_duration: duration, patient_groups: {patient_group: string, max_count: number}[]}
 * @param allPatientGroups a list of all patient groups
 */
export function getMaxKnifeTimeAndMaxCountRules(
    parameters: MaxKnifeDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup,
    getPatientGroups: GetResolvedPatientGroups
): CapacityRule[] {
    const capacityRules: CapacityRule[] = []

    for (const constraint of parameters.constraints.patient_groups ?? []) {
        const patientGroup = getPatientGroups.byConstraints(constraint.patient_group)
        if (isNullish(patientGroup?.surgeryTypeGroup)) {
            console.error(
                `Surgery Type group "${constraint.patient_group.patient_group_id}" not found (max_knife_time_and_max_count_of_surgeries_per_patient_group)`
            )
        } else {
            capacityRules.push(maxCountRule(constraint.max_count, patientGroup))
        }
    }

    const maxDuration = dayjs.duration(parameters.constraints.max_duration)
    capacityRules.push(maxKnifeTimeRule(maxDuration))

    return capacityRules
}

import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'

import { importUnScheduledSurgeries } from '~/store/dips-entity.api'
import { UnscheduledSurgery } from '~/store/dipsApi'
import { OccupancyData, Practitioner, selectGetUnScheduledSurgeries, UnScheduledSurgery } from '~/store/selectors'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'
import { isCountBasedRuleEvaluation } from '~/store/utils/blockEvaluation'
import { getSurgeons } from '~/utils/dips'

const sortByOrderedDate = (surg1: UnscheduledSurgery, surg2: UnscheduledSurgery) =>
    dayjs(surg1.surgeryOrderDetails?.orderedDate)?.unix() - dayjs(surg2.surgeryOrderDetails?.orderedDate).unix()

type AvailabilityProps = { scheduledPractitioners: Practitioner[]; currentOccupancy: OccupancyData | null }

// Gets Waiting list items that can fit a surgery slot in an available block
// given the surgeons are available and the patient group matches
// is ordered by the showing the oldest waiting list items first
export const useGetAvailableWaitingListItems = ({ scheduledPractitioners, currentOccupancy }: AvailabilityProps) => {
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const getUnScheduleSurgeries = useStore(selectGetUnScheduledSurgeries)
    const unScheduledSurgeries = getUnScheduleSurgeries.byDepartmentKey(departmentKey)

    const { isError, isLoading } = useImportEntities(
        () => [importUnScheduledSurgeries()],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
        { hasAlreadyData: Boolean(unScheduledSurgeries.length) }
    )

    const availablePatientGroups = useMemo(
        () =>
            currentOccupancy?.evaluations
                .filter(isCountBasedRuleEvaluation)
                .map(evaluation => evaluation.filteredByPatientGroup)
                .filter(Boolean) ?? [],
        [currentOccupancy]
    )

    const filterBySurgeons = useCallback(
        (surgery: UnscheduledSurgery) => {
            const waitinglistPractitioners = getSurgeons(surgery.surgeryResources)

            return waitinglistPractitioners.some(practitioner => {
                return scheduledPractitioners.map(p => p.short_name.toLocaleLowerCase()).includes(practitioner.short_name.toLocaleLowerCase())
            })
        },
        [scheduledPractitioners]
    )

    const filterByAvailablePatientGroups = useCallback(
        (surgery: UnScheduledSurgery) => {
            const surgeryTypeId = surgery.surgeryType?.id
            return surgeryTypeId && availablePatientGroups.some(pg => pg?.surgeryTypeGroup?.resolvedSurgeryTypes?.hasOwnProperty(surgeryTypeId))
        },
        [availablePatientGroups]
    )

    // Only show the waiting list items that are in the same department and have a surgeon that is available
    // show first, the waiting list items that have been waiting the longest
    const availableWaitingList = useMemo(
        () => unScheduledSurgeries.filter(filterBySurgeons).filter(filterByAvailablePatientGroups).sort(sortByOrderedDate),
        [unScheduledSurgeries, filterByAvailablePatientGroups, filterBySurgeons]
    )

    return { availableWaitingList, isError, isLoading }
}

import { Dayjs } from 'dayjs'
import groupBy from 'lodash/groupBy'

import { BlockSchedule, GetResolvedSurgeryTypeGroup, IdDate } from '~/store/selectors'
import { isHoliday } from '~/utils/extendedDayjs'
import { ResolvedConstraintObject, rulesResolver } from '~/utils/rulesResolver'

import { groupByDepartment, sumSpecialityCounts, sumSurgeryTypeGroupCounts } from './utils'

export function resolveWeeklyCounts(
    weekIdDates: IdDate[],
    getBlockSchedulesByDate: (day: Dayjs) => BlockSchedule[],
    activeFilteredLocationIds: number[],
    getSurgeryTypeGroup: GetResolvedSurgeryTypeGroup
) {
    const allConstraints: ResolvedConstraintObject[] = []

    for (const weekIdDate of weekIdDates) {
        // Not include public holidays in the weekly counts, even if blocks exist.
        if (isHoliday(weekIdDate.id)) {
            continue
        }

        const blockSchedules = getBlockSchedulesByDate(weekIdDate.date)

        for (const blockSchedule of blockSchedules) {
            // Check that we exclude block schedules that are not in the filtered location list from the total count
            if (!activeFilteredLocationIds.includes(Number(blockSchedule?.location_id))) {
                continue
            }

            const rules = blockSchedule?.rule_instance
            const resolvedParameters = rulesResolver({ rules, getSurgeryTypeGroup })

            // Filter out duration constraints
            allConstraints.push(...resolvedParameters.filter(con => con.surgeryTypeGroupLabel !== ''))
        }
    }

    const constraintsBySurgeryTypeGroup = groupBy(allConstraints, value => value.surgeryTypeGroupLabel)
    const surgeryTypeGroupTotals = Object.values(constraintsBySurgeryTypeGroup).map(groupConstraints => sumSurgeryTypeGroupCounts(groupConstraints))

    const constraintsByDepartment = groupByDepartment(surgeryTypeGroupTotals)
    const specialityTotals = Object.keys(constraintsByDepartment).map(key => sumSpecialityCounts(key, constraintsByDepartment[key] ?? []))

    return {
        specialityConstraints: constraintsByDepartment,
        specialityCounts: specialityTotals,
    }
}

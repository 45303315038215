import dayjs from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'

import { MinimalScheduledSurgery } from '~/store/dipsApi'

import { CapacityRule, CapacityRuleTypes } from '../../blocks/types'
import { newDurationBasedRule } from '../durationBasedRule'

const marginInMinutes = 30

export function getRoomDuration(surgery: MinimalScheduledSurgery): Duration {
    return surgery.plannedDuration ? dayjs.duration(surgery.plannedDuration) : dayjs.duration(0, 'minutes')
}

export function maxRoomDurationRule(maxDuration: Duration): CapacityRule {
    return newDurationBasedRule(CapacityRuleTypes.RoomDurationBased, null, maxDuration, getRoomDuration, marginInMinutes)
}

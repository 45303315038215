import dayjs from 'dayjs'
import { useState } from 'react'

import { createBatchBlockSchedule, updateBatchBlockSchedule } from '~/store/entity.api'
import { selectGetBlockSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { createRuleInstance, RuleDefinitionId, RuleInstanceParameters } from '~/store/utils/ruleInstance'
import { day, format } from '~/utils/extendedDayjs'

import { BookedDay } from './MultipleDatePicker'

export const useCreateMultipleBlockSchedules = () => {
    const [isLoading, setIsLoading] = useState(false)
    const addToast = useStore(state => state.toaster.actions.addToast)
    const getBlockSchedules = useStore(selectGetBlockSchedules)

    async function createMultipleBlockSchedules(
        ruleId: RuleDefinitionId,
        parameters: RuleInstanceParameters,
        dates: BookedDay[],
        locationId: number | null,
        closeModal: () => void
    ) {
        if (!parameters) return

        if (dates.length === 0) {
            addToast({ type: 'error', message: 'Du må velge minst en dato' })
            return
        }

        try {
            setIsLoading(true)
            const ruleInstance = createRuleInstance(ruleId, parameters)
            if (!ruleInstance) return
            if (dates.length === 0) return

            const bookedDates = dates.filter(({ isBooked }) => isBooked).map(({ date }) => date)
            const freeDates = dates.filter(({ isBooked }) => !isBooked).map(({ date }) => date)

            if (bookedDates.length > 0) {
                const allBookedBlockSchedules = bookedDates.map(date => getBlockSchedules.byDateAndLocationId(date, Number(locationId))).filter(Boolean)

                await updateBatchBlockSchedule(
                    allBookedBlockSchedules.map(blockSchedule => ({
                        id: blockSchedule.id,
                        body: {
                            tzid: dayjs.tz.guess(),
                            all_day: true,
                            start_time: format(day(blockSchedule.start_time)),
                            location_id: locationId,
                            rule_instance: ruleInstance,
                        },
                    }))
                )
            }

            if (freeDates.length > 0) {
                await createBatchBlockSchedule(
                    freeDates.map(date => ({
                        tzid: dayjs.tz.guess(),
                        all_day: true,
                        start_time: format(date),
                        location_id: locationId,
                        rule_instance: ruleInstance,
                    }))
                )
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
            closeModal()
        }
    }

    return { createMultipleBlockSchedules, isLoading }
}

import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { jwtDecode } from 'jwt-decode'
import { useEffect, useState } from 'react'
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom'

import { pages, redirects } from '~/app/routes'
import env from '~/env'
import { rtkStore } from '~/store'
import { useGetUserinfoOauthUserinfoGetQuery, UserInfoResponse } from '~/store/diApi'
import { selectLandingPage } from '~/store/slices/featureFlagsSlice'
import { clearPartialStorage, useStore } from '~/store/store'
import { analytics, anonymizedUser } from '~/utils/analytics'
import { scheduleRefreshingAccessToken } from '~/utils/token'

import { MainLayout } from './MainLayout/MainLayout'
import AdminPanel from './pages/Admin/AdminPanel.page'
import AuthPage from './pages/Auth/Auth.page'
import DownloadPage from './pages/Download/Download.page'
import MasterScheduler from './pages/MasterScheduler/MasterScheduler.page'
import NotFoundPage from './pages/NotFound/NotFound.page'
import OperationalPlannerPage from './pages/OperationalPlanner/OperationalPlanner.page'
import SurgeonSchedulerPage from './pages/SurgeonScheduler/SurgeonScheduler.page'
import WaitingListPage from './pages/WaitingList/WaitingList.page'
import PKCEAuthenticationFlow from './PKCEAuthenticationFlow'

// The following call allows the use of `refetchOnFocus` and `refetchOnReconnect` options in the useQuery hook
// More information: https://redux-toolkit.js.org/rtk-query/api/setupListeners
setupListeners(rtkStore.dispatch)

function useRemoveDILoaderElementEffect(isLoading: boolean): void {
    useEffect(() => {
        if (!isLoading) {
            document.getElementById('di-loader')?.remove()
        }
    }, [isLoading])
}

function useSetAuthenticationData(data: UserInfoResponse | undefined): void {
    const { setAuth } = useStore(state => state.user.actions)
    useEffect(() => {
        if (data === undefined) return

        setAuth({
            name: data?.userinfo.name ?? null,
            accessToken: data?.access_token ?? null,
        })
    }, [data, setAuth])
}

function useScheduleRefreshingAccessToken(featureFlagRefreshToken: boolean, data: UserInfoResponse | undefined) {
    const { setAuth } = useStore(state => state.user.actions)
    const [isRefreshingAccessToken, setIsRefreshingAccessToken] = useState(false)

    useEffect(() => {
        if (!featureFlagRefreshToken || data === undefined || data.access_token === undefined) {
            return
        }

        // schedule a periodic refreshing of the access token
        if (!isRefreshingAccessToken) {
            setIsRefreshingAccessToken(true)
            scheduleRefreshingAccessToken(data, newAccessToken => {
                setAuth({ accessToken: newAccessToken?.access_token ?? null })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featureFlagRefreshToken, isRefreshingAccessToken, data])
}

const App = () => {
    const { data, isLoading } = useGetUserinfoOauthUserinfoGetQuery()
    const dipsApiAccessToken = useStore(state => state.user.dipsApiAccessToken)
    const featureFlags = useStore(state => state.featureFlags)
    const landingPage = useStore(selectLandingPage)

    useRemoveDILoaderElementEffect(isLoading)
    useSetAuthenticationData(data)
    useScheduleRefreshingAccessToken(featureFlags.refreshToken, data)

    useEffect(() => {
        async function identifyUser() {
            if (dipsApiAccessToken) {
                const idToken = jwtDecode(dipsApiAccessToken)
                const user = await analytics?.user()
                const anonymousUserId = anonymizedUser(idToken.sub ?? user?.anonymousId() ?? 'anonymous')
                void analytics?.identify(anonymousUserId, { tenant: env.VITE_SEGMENT_TENANT_NAME, environment: env.DEV ? 'development' : 'production' })
            }
        }
        void identifyUser()
    }, [dipsApiAccessToken])

    if (isLoading) {
        return null
    }

    if (data === undefined || data.userinfo === undefined || data.access_token === undefined) {
        clearPartialStorage()
        return <AuthPage />
    }

    if (!dipsApiAccessToken && (data.issuer !== 'google' || env.VITE_PKCE_FOR_DEVS)) {
        if (env.VITE_PKCE_AUTHORIZE_URL) {
            return <PKCEAuthenticationFlow />
        } else {
            return null // prevents race condition where state.app.dipsApiAccessToken is still null (in non-PKCE flow). If we don't wait, a component below can call DIPS API → responds with 401 → we get logged out
        }
    }

    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path="/" element={<MainLayout />}>
                    {landingPage && (
                        <>
                            <Route path="/" element={<Navigate to={landingPage} replace={true} />} />
                            {featureFlags.MASTER_SURGERY_SCHEDULER && (
                                <Route path={pages.MASTER_SURGERY_SCHEDULER.views['master-scheduler'].path} element={<MasterScheduler />} />
                            )}
                            {featureFlags.SURGEON_SCHEDULER && (
                                <>
                                    <Route
                                        path={redirects.SURGEON_SCHEDULER}
                                        element={<Navigate to={pages.SURGEON_SCHEDULER.views.locations.path} replace={true} />}
                                    />
                                    {Object.values(pages.SURGEON_SCHEDULER.views).map(({ path }) => (
                                        <Route key={path} path={path} element={<SurgeonSchedulerPage />} />
                                    ))}
                                </>
                            )}
                            {featureFlags.OPERATIONAL_PLANNER && (
                                <>
                                    <Route
                                        path={redirects.OPERATIONAL_PLANNER}
                                        element={<Navigate to={pages.OPERATIONAL_PLANNER.views.calendar.path} replace={true} />}
                                    />
                                    {Object.values(pages.OPERATIONAL_PLANNER.views).map(({ path }) => (
                                        <Route key={path} path={path} element={<OperationalPlannerPage />} />
                                    ))}
                                </>
                            )}
                            <Route path={redirects.WAITING_LIST} element={<Navigate to={pages.WAITING_LIST.views.unscheduled.path} replace={true} />} />
                            {Object.values(pages.WAITING_LIST.views).map(({ path }) => (
                                <Route key={path} path={path} element={<WaitingListPage />} />
                            ))}
                            <Route path={pages.DOWNLOAD.views.download.path} element={<DownloadPage />} />
                            <Route path={pages.ADMIN_PANEL.views['admin-panel'].path} element={<AdminPanel />} />
                        </>
                    )}
                </Route>
                <Route path="*" element={<NotFoundPage />} />
            </>
        )
    )
    return <RouterProvider router={router} />
}

export default App

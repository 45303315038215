import { Duration } from 'dayjs/plugin/duration'

import { ResolvedPatientGroup } from '~/store/selectors'
import { isNotNullish } from '~/utils/guards'

import { CapacityRuleType, CapacityRuleTypes } from '../../selectors/internal/block_evaluations/blocks/types'
import { SurgeryCounts } from '../../selectors/internal/block_evaluations/rules/countBasedRule'
import { compareSurgeryTypeGroups } from '../resolvedPatientGroups'
import { OccupancyStatus, OccupancyStatusType } from './occupancyStatus'

type RuleEvaluationBase<T, Z, D extends CapacityRuleType> = {
    type: D // discriminator field
    value: T
    remaining: Z | null
    maxValue: Z | null
    filteredByPatientGroup: ResolvedPatientGroup | null
    bookingIds: string[]
    status: OccupancyStatusType
}

type RoomDurationRuleEvaluation = RuleEvaluationBase<Duration, Duration, typeof CapacityRuleTypes.RoomDurationBased>
type KnifeTimeRuleEvaluation = RuleEvaluationBase<Duration, Duration, typeof CapacityRuleTypes.KnifeTimeBased>

export type DurationRuleEvaluation = RuleEvaluationBase<
    Duration,
    Duration,
    typeof CapacityRuleTypes.RoomDurationBased | typeof CapacityRuleTypes.KnifeTimeBased
>
export type CountRuleEvaluation = RuleEvaluationBase<SurgeryCounts, number, typeof CapacityRuleTypes.CountBased>
export type RuleEvaluation = CountRuleEvaluation | DurationRuleEvaluation

export function isCountBasedRuleEvaluation(evaluation: RuleEvaluation): evaluation is CountRuleEvaluation {
    return evaluation.type === CapacityRuleTypes.CountBased
}

export function isRoomDurationBasedRuleEvaluation(evaluation: RuleEvaluation): evaluation is RoomDurationRuleEvaluation {
    return evaluation.type === CapacityRuleTypes.RoomDurationBased
}

export function isKnifeTimeBasedRuleEvaluation(evaluation: RuleEvaluation): evaluation is KnifeTimeRuleEvaluation {
    return evaluation.type === CapacityRuleTypes.KnifeTimeBased
}

export function isDurationRuleEvaluation(evaluation: RuleEvaluation): evaluation is DurationRuleEvaluation {
    return isRoomDurationBasedRuleEvaluation(evaluation) || isKnifeTimeBasedRuleEvaluation(evaluation)
}

export function hasAvailability(evaluation: RuleEvaluation): boolean {
    return evaluation?.status === OccupancyStatus.Available
}

export function isFilled(evaluation: RuleEvaluation): boolean {
    return evaluation.status === OccupancyStatus.Filled
}

export function isOverbooked(evaluation: RuleEvaluation): boolean {
    return evaluation.status === OccupancyStatus.Overbooked
}

/**
 * summarizeCapacityEvaluations returns the occupancy status of a block based on the occupancy status of its rules.
 * @param evaluations a list of capacity evaluations
 * @returns the status of the block
 * @see https://deepinsight.atlassian.net/wiki/spaces/CapPlan/pages/2638151709/Planning+rules#Evaluation-of-a-block
 */
export function summarizeCapacityEvaluations(evaluations: RuleEvaluation[]): OccupancyStatusType {
    if (evaluations.length === 0) {
        // there are no rules in this block
        return OccupancyStatus.Filled
    }

    const countBasedEvaluations = evaluations.filter(isCountBasedRuleEvaluation)
    const durationBasedEvaluations = evaluations.filter(isDurationRuleEvaluation)
    if (durationBasedEvaluations.length > 1) {
        console.error('Unexpected multiple duration-based rules.')
    }

    const durationBasedEvaluation = durationBasedEvaluations.length > 0 ? durationBasedEvaluations[0] : undefined
    if (isNotNullish(durationBasedEvaluation)) {
        if (countBasedEvaluations.length === 0) {
            return durationBasedEvaluation.status
        }

        switch (durationBasedEvaluation.status) {
            case OccupancyStatus.Available:
                if (countBasedEvaluations.some(hasAvailability)) {
                    return OccupancyStatus.Available
                }
                break

            case OccupancyStatus.Filled:
                return OccupancyStatus.Filled

            case OccupancyStatus.Overbooked:
                return OccupancyStatus.Overbooked
        }
    }

    // the entire block is filled if all count-based rules evaluate as filled
    if (countBasedEvaluations.every(isFilled)) {
        return OccupancyStatus.Filled
    }

    // the entire block has availability if at least one of the count-based rules evaluates as empty/available
    if (countBasedEvaluations.some(hasAvailability)) {
        return OccupancyStatus.Available
    }

    // the entire block is overbooked if at least one of the count-based rules evaluates as overbooked
    if (countBasedEvaluations.some(isOverbooked)) {
        return OccupancyStatus.Overbooked
    }

    // This code should be unreachable, i.e. some error has occurred.
    // At least it's certain that there is no available slot, nor overbooked one. Whatever happened, there is nothing to book, so it's filled:
    return OccupancyStatus.Filled
}

/** A comparison function to order evaluations by patient group names. */
export function compareEvaluationsByPatientGroupNames(a: RuleEvaluation, b: RuleEvaluation) {
    return compareSurgeryTypeGroups(a.filteredByPatientGroup, b.filteredByPatientGroup)
}

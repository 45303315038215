import { Duration } from 'dayjs/plugin/duration'

import {
    MaxCountRuleCreate,
    MaxCountRuleOut,
    MaxDurationAndCountRuleCreate,
    MaxDurationAndCountRuleOut,
    MaxDurationRuleCreate,
    MaxDurationRuleOut,
    MaxKnifeDurationAndCountRuleCreate,
    MaxKnifeDurationAndCountRuleOut,
    MaxKnifeDurationRuleCreate,
    MaxKnifeDurationRuleOut,
    MaxWeightRuleCreate,
    MaxWeightRuleOut,
    RuleDefinitionOut,
} from '~/store/diApi'
import { ResolvedPatientGroup, ScheduledSurgery } from '~/store/selectors'
import { OccupancyStatusType } from '~/store/utils/blockEvaluation'

import { SurgeryCounts } from '../rules/countBasedRule'

export const CapacityRuleTypes = {
    CountBased: 'CountBased',
    RoomDurationBased: 'RoomDurationBased',
    KnifeTimeBased: 'KnifeTimeBased',
} as const
export type CapacityRuleType = (typeof CapacityRuleTypes)[keyof typeof CapacityRuleTypes]

export type CapacityRuleBase<T, Z, D extends CapacityRuleType> = {
    type: D // discriminator field
    patientGroupFilter: ResolvedPatientGroup | null
    getSurgeryOccupancy: (surgery: ScheduledSurgery, patientGroup: ResolvedPatientGroup | null) => T
    accumulate(a: T, b: T): T
    subtract(a: Z, b: T): Z
    evaluate(a: T): OccupancyStatusType
    filledBlockThreshold: Z | null
    zeroValue: T
}

export type CountBasedRule = CapacityRuleBase<SurgeryCounts, number, typeof CapacityRuleTypes.CountBased>
export type DurationBasedRule = CapacityRuleBase<Duration, Duration, typeof CapacityRuleTypes.RoomDurationBased | typeof CapacityRuleTypes.KnifeTimeBased>
export type CapacityRule = CountBasedRule | DurationBasedRule

export function isCountBasedRule(rule: CapacityRule): rule is CountBasedRule {
    return rule.type === CapacityRuleTypes.CountBased
}
export function isDurationBasedRule(rule: CapacityRule): rule is DurationBasedRule {
    return rule.type === CapacityRuleTypes.KnifeTimeBased || rule.type === CapacityRuleTypes.RoomDurationBased
}
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
type BlockRuleOut =
    | (MaxCountRuleOut & { definition: RuleDefinitionOut })
    | (MaxWeightRuleOut & { definition: RuleDefinitionOut })
    | (MaxDurationRuleOut & { definition: RuleDefinitionOut })
    | (MaxDurationAndCountRuleOut & { definition: RuleDefinitionOut })
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    | (MaxKnifeDurationRuleOut & { definition: RuleDefinitionOut })
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents, @typescript-eslint/no-duplicate-type-constituents
    | (MaxKnifeDurationAndCountRuleOut & { definition: RuleDefinitionOut })

type BlockRuleCreate =
    | MaxCountRuleCreate
    | MaxWeightRuleCreate
    | MaxDurationRuleCreate
    | MaxDurationAndCountRuleCreate
    | MaxKnifeDurationRuleCreate
    | MaxKnifeDurationAndCountRuleCreate
    | undefined
    | null

export type BlockRule = BlockRuleOut | BlockRuleCreate

type MaxCountRule = MaxCountRuleOut | MaxCountRuleCreate
type MaxWeightRule = MaxWeightRuleOut | MaxWeightRuleCreate
type MaxDurationRule = MaxDurationRuleOut | MaxDurationRuleCreate
type MaxDurationAndCountRule = MaxDurationAndCountRuleOut | MaxDurationAndCountRuleCreate
type MaxKnifeDurationRule = MaxKnifeDurationRuleOut | MaxKnifeDurationRuleCreate
type MaxKnifeDurationAndCountRule = MaxKnifeDurationAndCountRuleOut | MaxKnifeDurationAndCountRuleCreate

export function isMaxCountRule(blockRule: BlockRule): blockRule is MaxCountRule {
    return blockRule?.rule_definition_id === 'max_count_of_surgeries_per_patient_group'
}

export function isMaxWeightRule(blockRule: BlockRule): blockRule is MaxWeightRule {
    return blockRule?.rule_definition_id === 'max_weight_of_surgeries_per_patient_group'
}

export function isMaxDurationRule(blockRule: BlockRule): blockRule is MaxDurationRule {
    return blockRule?.rule_definition_id === 'max_duration_of_surgeries_per_patient_group'
}

export function isMaxDurationAndCountRule(blockRule: BlockRule): blockRule is MaxDurationAndCountRule {
    return blockRule?.rule_definition_id === 'max_duration_and_max_count_of_surgeries_per_patient_group'
}

export function isMaxKnifeDurationRule(blockRule: BlockRule): blockRule is MaxKnifeDurationRule {
    return blockRule?.rule_definition_id === 'max_knife_time_of_surgeries_per_patient_group'
}

export function isMaxKnifeDurationAndCountRule(blockRule: BlockRule): blockRule is MaxKnifeDurationAndCountRule {
    return blockRule?.rule_definition_id === 'max_knife_time_and_max_count_of_surgeries_per_patient_group'
}

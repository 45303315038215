import { emptyDIApi as api } from "./emptyDIApi";
export const addTagTypes = [
  "age_groups" as const,
  "deprecated" as const,
  "block_exceptions" as const,
  "block_locks" as const,
  "block_schedules" as const,
  "department_location_assignments" as const,
  "department_practitioner_assignments" as const,
  "departments" as const,
  "health" as const,
  "hospital_surgery_type_group_associations" as const,
  "hospital_surgery_types" as const,
  "location_schedules" as const,
  "locations" as const,
  "authentication" as const,
  "on_call_schedules" as const,
  "patient_group_age_restrictions" as const,
  "patient_group_definitions" as const,
  "patient_groups" as const,
  "practitioner_schedule_locations" as const,
  "practitioner_schedule_statuses" as const,
  "practitioner_schedules" as const,
  "practitioners" as const,
  "rule_definitions" as const,
  "sections" as const,
  "specialities" as const,
  "surgery_type_group_age_restrictions" as const,
  "surgery_type_group_hierarchies" as const,
  "surgery_type_group_specialities" as const,
  "surgery_type_groups" as const,
  "version" as const,
  "waiting_list_items" as const,
];
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getAgeGroups: build.query<GetAgeGroupsApiResponse, GetAgeGroupsApiArg>({
        query: (queryArg) => ({
          url: `/age_groups/`,
          params: {
            age_group_code: queryArg.ageGroupCode,
            "age_group_code:in": queryArg["age_group_code:in"],
            display_name: queryArg.displayName,
            "display_name:startsWith": queryArg["display_name:startsWith"],
            "display_name:endsWith": queryArg["display_name:endsWith"],
            "display_name:contains": queryArg["display_name:contains"],
            "display_name:in": queryArg["display_name:in"],
            minimum_age: queryArg.minimumAge,
            "minimum_age:gt": queryArg["minimum_age:gt"],
            "minimum_age:lt": queryArg["minimum_age:lt"],
            "minimum_age:gte": queryArg["minimum_age:gte"],
            "minimum_age:lte": queryArg["minimum_age:lte"],
            "minimum_age:in": queryArg["minimum_age:in"],
            maximum_age: queryArg.maximumAge,
            "maximum_age:gt": queryArg["maximum_age:gt"],
            "maximum_age:lt": queryArg["maximum_age:lt"],
            "maximum_age:gte": queryArg["maximum_age:gte"],
            "maximum_age:lte": queryArg["maximum_age:lte"],
            "maximum_age:in": queryArg["maximum_age:in"],
            id: queryArg.id,
            "id:in": queryArg["id:in"],
          },
        }),
        providesTags: ["age_groups"],
      }),
      createAgeGroup: build.mutation<
        CreateAgeGroupApiResponse,
        CreateAgeGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/age_groups/`,
          method: "POST",
          body: queryArg.ageGroupCreate,
        }),
        invalidatesTags: ["age_groups"],
      }),
      createBatchAgeGroup: build.mutation<
        CreateBatchAgeGroupApiResponse,
        CreateBatchAgeGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/age_groups/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["age_groups"],
      }),
      deleteBatchAgeGroup: build.mutation<
        DeleteBatchAgeGroupApiResponse,
        DeleteBatchAgeGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/age_groups/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["age_groups"],
      }),
      updateBatchAgeGroup: build.mutation<
        UpdateBatchAgeGroupApiResponse,
        UpdateBatchAgeGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/age_groups/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["age_groups"],
      }),
      deleteAgeGroup: build.mutation<
        DeleteAgeGroupApiResponse,
        DeleteAgeGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/age_groups/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["age_groups"],
      }),
      getAgeGroup: build.query<GetAgeGroupApiResponse, GetAgeGroupApiArg>({
        query: (queryArg) => ({ url: `/age_groups/${queryArg.id}` }),
        providesTags: ["age_groups"],
      }),
      updateAgeGroup: build.mutation<
        UpdateAgeGroupApiResponse,
        UpdateAgeGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/age_groups/${queryArg.id}`,
          method: "PUT",
          body: queryArg.ageGroupCreate,
        }),
        invalidatesTags: ["age_groups"],
      }),
      authRedirectAuthGet: build.query<
        AuthRedirectAuthGetApiResponse,
        AuthRedirectAuthGetApiArg
      >({
        query: () => ({ url: `/auth` }),
        providesTags: ["deprecated"],
      }),
      getBlockExceptions: build.query<
        GetBlockExceptionsApiResponse,
        GetBlockExceptionsApiArg
      >({
        query: (queryArg) => ({
          url: `/block_exceptions/`,
          params: {
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            rule_instance_id: queryArg.ruleInstanceId,
            "rule_instance_id:in": queryArg["rule_instance_id:in"],
            "rule_instance.rule_definition_id":
              queryArg["rule_instance.rule_definition_id"],
            "rule_instance.rule_definition_id:in":
              queryArg["rule_instance.rule_definition_id:in"],
            "rule_instance.name": queryArg["rule_instance.name"],
            "rule_instance.name:startsWith":
              queryArg["rule_instance.name:startsWith"],
            "rule_instance.name:endsWith":
              queryArg["rule_instance.name:endsWith"],
            "rule_instance.name:contains":
              queryArg["rule_instance.name:contains"],
            "rule_instance.name:in": queryArg["rule_instance.name:in"],
            "rule_instance.comment": queryArg["rule_instance.comment"],
            "rule_instance.comment:startsWith":
              queryArg["rule_instance.comment:startsWith"],
            "rule_instance.comment:endsWith":
              queryArg["rule_instance.comment:endsWith"],
            "rule_instance.comment:contains":
              queryArg["rule_instance.comment:contains"],
            "rule_instance.comment:in": queryArg["rule_instance.comment:in"],
            "rule_instance.id": queryArg["rule_instance.id"],
            "rule_instance.id:in": queryArg["rule_instance.id:in"],
            exclude_rule_instance: queryArg.excludeRuleInstance,
            block_schedule_id: queryArg.blockScheduleId,
            "block_schedule_id:in": queryArg["block_schedule_id:in"],
            start_time: queryArg.startTime,
            "start_time:gt": queryArg["start_time:gt"],
            "start_time:lt": queryArg["start_time:lt"],
            "start_time:gte": queryArg["start_time:gte"],
            "start_time:lte": queryArg["start_time:lte"],
            "start_time:in": queryArg["start_time:in"],
            end_time: queryArg.endTime,
            "end_time:gt": queryArg["end_time:gt"],
            "end_time:lt": queryArg["end_time:lt"],
            "end_time:gte": queryArg["end_time:gte"],
            "end_time:lte": queryArg["end_time:lte"],
            "end_time:in": queryArg["end_time:in"],
          },
        }),
        providesTags: ["block_exceptions"],
      }),
      createBlockException: build.mutation<
        CreateBlockExceptionApiResponse,
        CreateBlockExceptionApiArg
      >({
        query: (queryArg) => ({
          url: `/block_exceptions/`,
          method: "POST",
          body: queryArg.blockExceptionCreate,
        }),
        invalidatesTags: ["block_exceptions"],
      }),
      createBatchBlockException: build.mutation<
        CreateBatchBlockExceptionApiResponse,
        CreateBatchBlockExceptionApiArg
      >({
        query: (queryArg) => ({
          url: `/block_exceptions/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["block_exceptions"],
      }),
      deleteBatchBlockException: build.mutation<
        DeleteBatchBlockExceptionApiResponse,
        DeleteBatchBlockExceptionApiArg
      >({
        query: (queryArg) => ({
          url: `/block_exceptions/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["block_exceptions"],
      }),
      updateBatchBlockException: build.mutation<
        UpdateBatchBlockExceptionApiResponse,
        UpdateBatchBlockExceptionApiArg
      >({
        query: (queryArg) => ({
          url: `/block_exceptions/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["block_exceptions"],
      }),
      deleteBlockException: build.mutation<
        DeleteBlockExceptionApiResponse,
        DeleteBlockExceptionApiArg
      >({
        query: (queryArg) => ({
          url: `/block_exceptions/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["block_exceptions"],
      }),
      getBlockException: build.query<
        GetBlockExceptionApiResponse,
        GetBlockExceptionApiArg
      >({
        query: (queryArg) => ({
          url: `/block_exceptions/${queryArg.id}`,
          params: { exclude_rule_instance: queryArg.excludeRuleInstance },
        }),
        providesTags: ["block_exceptions"],
      }),
      updateBlockException: build.mutation<
        UpdateBlockExceptionApiResponse,
        UpdateBlockExceptionApiArg
      >({
        query: (queryArg) => ({
          url: `/block_exceptions/${queryArg.id}`,
          method: "PUT",
          body: queryArg.blockExceptionCreate,
        }),
        invalidatesTags: ["block_exceptions"],
      }),
      getBlockLocks: build.query<GetBlockLocksApiResponse, GetBlockLocksApiArg>(
        {
          query: (queryArg) => ({
            url: `/block_locks/`,
            params: {
              id: queryArg.id,
              "id:in": queryArg["id:in"],
              start_time: queryArg.startTime,
              "start_time:gt": queryArg["start_time:gt"],
              "start_time:lt": queryArg["start_time:lt"],
              "start_time:gte": queryArg["start_time:gte"],
              "start_time:lte": queryArg["start_time:lte"],
              "start_time:in": queryArg["start_time:in"],
              end_time: queryArg.endTime,
              "end_time:gt": queryArg["end_time:gt"],
              "end_time:lt": queryArg["end_time:lt"],
              "end_time:gte": queryArg["end_time:gte"],
              "end_time:lte": queryArg["end_time:lte"],
              "end_time:in": queryArg["end_time:in"],
              location_id: queryArg.locationId,
              "location_id:in": queryArg["location_id:in"],
              "location.id": queryArg["location.id"],
              "location.id:in": queryArg["location.id:in"],
              "location.room_code": queryArg["location.room_code"],
              "location.room_code:in": queryArg["location.room_code:in"],
              exclude_location: queryArg.excludeLocation,
            },
          }),
          providesTags: ["block_locks"],
        }
      ),
      createBlockLock: build.mutation<
        CreateBlockLockApiResponse,
        CreateBlockLockApiArg
      >({
        query: (queryArg) => ({
          url: `/block_locks/`,
          method: "POST",
          body: queryArg.blockLockCreate,
        }),
        invalidatesTags: ["block_locks"],
      }),
      createBatchBlockLock: build.mutation<
        CreateBatchBlockLockApiResponse,
        CreateBatchBlockLockApiArg
      >({
        query: (queryArg) => ({
          url: `/block_locks/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["block_locks"],
      }),
      deleteBatchBlockLock: build.mutation<
        DeleteBatchBlockLockApiResponse,
        DeleteBatchBlockLockApiArg
      >({
        query: (queryArg) => ({
          url: `/block_locks/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["block_locks"],
      }),
      updateBatchBlockLock: build.mutation<
        UpdateBatchBlockLockApiResponse,
        UpdateBatchBlockLockApiArg
      >({
        query: (queryArg) => ({
          url: `/block_locks/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["block_locks"],
      }),
      deleteBlockLock: build.mutation<
        DeleteBlockLockApiResponse,
        DeleteBlockLockApiArg
      >({
        query: (queryArg) => ({
          url: `/block_locks/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["block_locks"],
      }),
      getBlockLock: build.query<GetBlockLockApiResponse, GetBlockLockApiArg>({
        query: (queryArg) => ({
          url: `/block_locks/${queryArg.id}`,
          params: { exclude_location: queryArg.excludeLocation },
        }),
        providesTags: ["block_locks"],
      }),
      updateBlockLock: build.mutation<
        UpdateBlockLockApiResponse,
        UpdateBlockLockApiArg
      >({
        query: (queryArg) => ({
          url: `/block_locks/${queryArg.id}`,
          method: "PUT",
          body: queryArg.blockLockCreate,
        }),
        invalidatesTags: ["block_locks"],
      }),
      getBlockSchedules: build.query<
        GetBlockSchedulesApiResponse,
        GetBlockSchedulesApiArg
      >({
        query: (queryArg) => ({
          url: `/block_schedules/`,
          params: {
            start_time: queryArg.startTime,
            "start_time:gt": queryArg["start_time:gt"],
            "start_time:lt": queryArg["start_time:lt"],
            "start_time:gte": queryArg["start_time:gte"],
            "start_time:lte": queryArg["start_time:lte"],
            "start_time:in": queryArg["start_time:in"],
            end_time: queryArg.endTime,
            "end_time:gt": queryArg["end_time:gt"],
            "end_time:lt": queryArg["end_time:lt"],
            "end_time:gte": queryArg["end_time:gte"],
            "end_time:lte": queryArg["end_time:lte"],
            "end_time:in": queryArg["end_time:in"],
            duration: queryArg.duration,
            "duration:eq": queryArg["duration:eq"],
            tzid: queryArg.tzid,
            "tzid:startsWith": queryArg["tzid:startsWith"],
            "tzid:endsWith": queryArg["tzid:endsWith"],
            "tzid:contains": queryArg["tzid:contains"],
            "tzid:in": queryArg["tzid:in"],
            all_day: queryArg.allDay,
            "all_day:eq": queryArg["all_day:eq"],
            comment: queryArg.comment,
            "comment:startsWith": queryArg["comment:startsWith"],
            "comment:endsWith": queryArg["comment:endsWith"],
            "comment:contains": queryArg["comment:contains"],
            "comment:in": queryArg["comment:in"],
            "rule_instance.rule_definition_id":
              queryArg["rule_instance.rule_definition_id"],
            "rule_instance.rule_definition_id:in":
              queryArg["rule_instance.rule_definition_id:in"],
            "rule_instance.name": queryArg["rule_instance.name"],
            "rule_instance.name:startsWith":
              queryArg["rule_instance.name:startsWith"],
            "rule_instance.name:endsWith":
              queryArg["rule_instance.name:endsWith"],
            "rule_instance.name:contains":
              queryArg["rule_instance.name:contains"],
            "rule_instance.name:in": queryArg["rule_instance.name:in"],
            "rule_instance.comment": queryArg["rule_instance.comment"],
            "rule_instance.comment:startsWith":
              queryArg["rule_instance.comment:startsWith"],
            "rule_instance.comment:endsWith":
              queryArg["rule_instance.comment:endsWith"],
            "rule_instance.comment:contains":
              queryArg["rule_instance.comment:contains"],
            "rule_instance.comment:in": queryArg["rule_instance.comment:in"],
            "rule_instance.id": queryArg["rule_instance.id"],
            "rule_instance.id:in": queryArg["rule_instance.id:in"],
            exclude_rule_instance: queryArg.excludeRuleInstance,
            location_id: queryArg.locationId,
            "location_id:in": queryArg["location_id:in"],
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            rule_instance_id: queryArg.ruleInstanceId,
            "rule_instance_id:in": queryArg["rule_instance_id:in"],
            "location.id": queryArg["location.id"],
            "location.id:in": queryArg["location.id:in"],
            "location.room_code": queryArg["location.room_code"],
            "location.room_code:in": queryArg["location.room_code:in"],
            exclude_location: queryArg.excludeLocation,
          },
        }),
        providesTags: ["block_schedules"],
      }),
      createBlockSchedule: build.mutation<
        CreateBlockScheduleApiResponse,
        CreateBlockScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/block_schedules/`,
          method: "POST",
          body: queryArg.blockScheduleCreate,
        }),
        invalidatesTags: ["block_schedules"],
      }),
      createBatchBlockSchedule: build.mutation<
        CreateBatchBlockScheduleApiResponse,
        CreateBatchBlockScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/block_schedules/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["block_schedules"],
      }),
      deleteBatchBlockSchedule: build.mutation<
        DeleteBatchBlockScheduleApiResponse,
        DeleteBatchBlockScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/block_schedules/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["block_schedules"],
      }),
      updateBatchBlockSchedule: build.mutation<
        UpdateBatchBlockScheduleApiResponse,
        UpdateBatchBlockScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/block_schedules/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["block_schedules"],
      }),
      deleteBlockSchedule: build.mutation<
        DeleteBlockScheduleApiResponse,
        DeleteBlockScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/block_schedules/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["block_schedules"],
      }),
      getBlockSchedule: build.query<
        GetBlockScheduleApiResponse,
        GetBlockScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/block_schedules/${queryArg.id}`,
          params: {
            exclude_rule_instance: queryArg.excludeRuleInstance,
            exclude_location: queryArg.excludeLocation,
          },
        }),
        providesTags: ["block_schedules"],
      }),
      updateBlockSchedule: build.mutation<
        UpdateBlockScheduleApiResponse,
        UpdateBlockScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/block_schedules/${queryArg.id}`,
          method: "PUT",
          body: queryArg.blockScheduleCreate,
        }),
        invalidatesTags: ["block_schedules"],
      }),
      getDepartmentLocationAssignments: build.query<
        GetDepartmentLocationAssignmentsApiResponse,
        GetDepartmentLocationAssignmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/department_location_assignments/`,
          params: {
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            department_id: queryArg.departmentId,
            "department_id:in": queryArg["department_id:in"],
            location_id: queryArg.locationId,
            "location_id:in": queryArg["location_id:in"],
          },
        }),
        providesTags: ["department_location_assignments"],
      }),
      createDepartmentLocationAssignment: build.mutation<
        CreateDepartmentLocationAssignmentApiResponse,
        CreateDepartmentLocationAssignmentApiArg
      >({
        query: (queryArg) => ({
          url: `/department_location_assignments/`,
          method: "POST",
          body: queryArg.departmentLocationAssignmentCreate,
        }),
        invalidatesTags: ["department_location_assignments"],
      }),
      createBatchDepartmentLocationAssignment: build.mutation<
        CreateBatchDepartmentLocationAssignmentApiResponse,
        CreateBatchDepartmentLocationAssignmentApiArg
      >({
        query: (queryArg) => ({
          url: `/department_location_assignments/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["department_location_assignments"],
      }),
      deleteBatchDepartmentLocationAssignment: build.mutation<
        DeleteBatchDepartmentLocationAssignmentApiResponse,
        DeleteBatchDepartmentLocationAssignmentApiArg
      >({
        query: (queryArg) => ({
          url: `/department_location_assignments/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["department_location_assignments"],
      }),
      updateBatchDepartmentLocationAssignment: build.mutation<
        UpdateBatchDepartmentLocationAssignmentApiResponse,
        UpdateBatchDepartmentLocationAssignmentApiArg
      >({
        query: (queryArg) => ({
          url: `/department_location_assignments/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["department_location_assignments"],
      }),
      deleteDepartmentLocationAssignment: build.mutation<
        DeleteDepartmentLocationAssignmentApiResponse,
        DeleteDepartmentLocationAssignmentApiArg
      >({
        query: (queryArg) => ({
          url: `/department_location_assignments/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["department_location_assignments"],
      }),
      getDepartmentLocationAssignment: build.query<
        GetDepartmentLocationAssignmentApiResponse,
        GetDepartmentLocationAssignmentApiArg
      >({
        query: (queryArg) => ({
          url: `/department_location_assignments/${queryArg.id}`,
        }),
        providesTags: ["department_location_assignments"],
      }),
      updateDepartmentLocationAssignment: build.mutation<
        UpdateDepartmentLocationAssignmentApiResponse,
        UpdateDepartmentLocationAssignmentApiArg
      >({
        query: (queryArg) => ({
          url: `/department_location_assignments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.departmentLocationAssignmentCreate,
        }),
        invalidatesTags: ["department_location_assignments"],
      }),
      getDepartmentPractitionerAssignments: build.query<
        GetDepartmentPractitionerAssignmentsApiResponse,
        GetDepartmentPractitionerAssignmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/department_practitioner_assignments/`,
          params: {
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            department_id: queryArg.departmentId,
            "department_id:in": queryArg["department_id:in"],
            practitioner_id: queryArg.practitionerId,
            "practitioner_id:in": queryArg["practitioner_id:in"],
          },
        }),
        providesTags: ["department_practitioner_assignments"],
      }),
      createDepartmentPractitionerAssignment: build.mutation<
        CreateDepartmentPractitionerAssignmentApiResponse,
        CreateDepartmentPractitionerAssignmentApiArg
      >({
        query: (queryArg) => ({
          url: `/department_practitioner_assignments/`,
          method: "POST",
          body: queryArg.departmentPractitionerAssignmentCreate,
        }),
        invalidatesTags: ["department_practitioner_assignments"],
      }),
      createBatchDepartmentPractitionerAssignment: build.mutation<
        CreateBatchDepartmentPractitionerAssignmentApiResponse,
        CreateBatchDepartmentPractitionerAssignmentApiArg
      >({
        query: (queryArg) => ({
          url: `/department_practitioner_assignments/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["department_practitioner_assignments"],
      }),
      deleteBatchDepartmentPractitionerAssignment: build.mutation<
        DeleteBatchDepartmentPractitionerAssignmentApiResponse,
        DeleteBatchDepartmentPractitionerAssignmentApiArg
      >({
        query: (queryArg) => ({
          url: `/department_practitioner_assignments/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["department_practitioner_assignments"],
      }),
      updateBatchDepartmentPractitionerAssignment: build.mutation<
        UpdateBatchDepartmentPractitionerAssignmentApiResponse,
        UpdateBatchDepartmentPractitionerAssignmentApiArg
      >({
        query: (queryArg) => ({
          url: `/department_practitioner_assignments/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["department_practitioner_assignments"],
      }),
      deleteDepartmentPractitionerAssignment: build.mutation<
        DeleteDepartmentPractitionerAssignmentApiResponse,
        DeleteDepartmentPractitionerAssignmentApiArg
      >({
        query: (queryArg) => ({
          url: `/department_practitioner_assignments/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["department_practitioner_assignments"],
      }),
      getDepartmentPractitionerAssignment: build.query<
        GetDepartmentPractitionerAssignmentApiResponse,
        GetDepartmentPractitionerAssignmentApiArg
      >({
        query: (queryArg) => ({
          url: `/department_practitioner_assignments/${queryArg.id}`,
        }),
        providesTags: ["department_practitioner_assignments"],
      }),
      updateDepartmentPractitionerAssignment: build.mutation<
        UpdateDepartmentPractitionerAssignmentApiResponse,
        UpdateDepartmentPractitionerAssignmentApiArg
      >({
        query: (queryArg) => ({
          url: `/department_practitioner_assignments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.departmentPractitionerAssignmentCreate,
        }),
        invalidatesTags: ["department_practitioner_assignments"],
      }),
      getDepartments: build.query<
        GetDepartmentsApiResponse,
        GetDepartmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/departments/`,
          params: {
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            name: queryArg.name,
            "name:startsWith": queryArg["name:startsWith"],
            "name:endsWith": queryArg["name:endsWith"],
            "name:contains": queryArg["name:contains"],
            "name:in": queryArg["name:in"],
            dips_id: queryArg.dipsId,
            "dips_id:in": queryArg["dips_id:in"],
            "locations.id": queryArg["locations.id"],
            "locations.id:in": queryArg["locations.id:in"],
            "locations.room_code": queryArg["locations.room_code"],
            "locations.room_code:in": queryArg["locations.room_code:in"],
            exclude_locations: queryArg.excludeLocations,
            "practitioners.short_name": queryArg["practitioners.short_name"],
            "practitioners.short_name:in":
              queryArg["practitioners.short_name:in"],
            "practitioners.id": queryArg["practitioners.id"],
            "practitioners.id:in": queryArg["practitioners.id:in"],
            exclude_practitioners: queryArg.excludePractitioners,
            "sections.id": queryArg["sections.id"],
            "sections.id:in": queryArg["sections.id:in"],
            "sections.dips_section_id": queryArg["sections.dips_section_id"],
            "sections.dips_section_id:gt":
              queryArg["sections.dips_section_id:gt"],
            "sections.dips_section_id:lt":
              queryArg["sections.dips_section_id:lt"],
            "sections.dips_section_id:gte":
              queryArg["sections.dips_section_id:gte"],
            "sections.dips_section_id:lte":
              queryArg["sections.dips_section_id:lte"],
            "sections.dips_section_id:in":
              queryArg["sections.dips_section_id:in"],
            "sections.name": queryArg["sections.name"],
            "sections.name:startsWith": queryArg["sections.name:startsWith"],
            "sections.name:endsWith": queryArg["sections.name:endsWith"],
            "sections.name:contains": queryArg["sections.name:contains"],
            "sections.name:in": queryArg["sections.name:in"],
            "sections.department_id": queryArg["sections.department_id"],
            "sections.department_id:gt": queryArg["sections.department_id:gt"],
            "sections.department_id:lt": queryArg["sections.department_id:lt"],
            "sections.department_id:gte":
              queryArg["sections.department_id:gte"],
            "sections.department_id:lte":
              queryArg["sections.department_id:lte"],
            "sections.department_id:in": queryArg["sections.department_id:in"],
            exclude_sections: queryArg.excludeSections,
          },
        }),
        providesTags: ["departments"],
      }),
      createDepartment: build.mutation<
        CreateDepartmentApiResponse,
        CreateDepartmentApiArg
      >({
        query: (queryArg) => ({
          url: `/departments/`,
          method: "POST",
          body: queryArg.departmentCreate,
        }),
        invalidatesTags: ["departments"],
      }),
      createBatchDepartment: build.mutation<
        CreateBatchDepartmentApiResponse,
        CreateBatchDepartmentApiArg
      >({
        query: (queryArg) => ({
          url: `/departments/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["departments"],
      }),
      deleteBatchDepartment: build.mutation<
        DeleteBatchDepartmentApiResponse,
        DeleteBatchDepartmentApiArg
      >({
        query: (queryArg) => ({
          url: `/departments/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["departments"],
      }),
      updateBatchDepartment: build.mutation<
        UpdateBatchDepartmentApiResponse,
        UpdateBatchDepartmentApiArg
      >({
        query: (queryArg) => ({
          url: `/departments/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["departments"],
      }),
      deleteDepartment: build.mutation<
        DeleteDepartmentApiResponse,
        DeleteDepartmentApiArg
      >({
        query: (queryArg) => ({
          url: `/departments/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["departments"],
      }),
      getDepartment: build.query<GetDepartmentApiResponse, GetDepartmentApiArg>(
        {
          query: (queryArg) => ({
            url: `/departments/${queryArg.id}`,
            params: {
              exclude_locations: queryArg.excludeLocations,
              exclude_practitioners: queryArg.excludePractitioners,
              exclude_sections: queryArg.excludeSections,
            },
          }),
          providesTags: ["departments"],
        }
      ),
      updateDepartment: build.mutation<
        UpdateDepartmentApiResponse,
        UpdateDepartmentApiArg
      >({
        query: (queryArg) => ({
          url: `/departments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.departmentCreate,
        }),
        invalidatesTags: ["departments"],
      }),
      live: build.query<LiveApiResponse, LiveApiArg>({
        query: () => ({ url: `/health/live` }),
        providesTags: ["health"],
      }),
      ready: build.query<ReadyApiResponse, ReadyApiArg>({
        query: () => ({ url: `/health/ready` }),
        providesTags: ["health"],
      }),
      getHospitalSurgeryTypeGroupAssociations: build.query<
        GetHospitalSurgeryTypeGroupAssociationsApiResponse,
        GetHospitalSurgeryTypeGroupAssociationsApiArg
      >({
        query: (queryArg) => ({
          url: `/hospital_surgery_type_group_associations/`,
          params: {
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            surgery_type_group_id: queryArg.surgeryTypeGroupId,
            "surgery_type_group_id:in": queryArg["surgery_type_group_id:in"],
            hospital_surgery_type_id: queryArg.hospitalSurgeryTypeId,
            "hospital_surgery_type_id:in":
              queryArg["hospital_surgery_type_id:in"],
            weight: queryArg.weight,
            "weight:gt": queryArg["weight:gt"],
            "weight:lt": queryArg["weight:lt"],
            "weight:gte": queryArg["weight:gte"],
            "weight:lte": queryArg["weight:lte"],
            "weight:in": queryArg["weight:in"],
            "surgery_type.hospital_surgery_type_id":
              queryArg["surgery_type.hospital_surgery_type_id"],
            "surgery_type.hospital_surgery_type_id:in":
              queryArg["surgery_type.hospital_surgery_type_id:in"],
            "surgery_type.hospital_surgery_type_name":
              queryArg["surgery_type.hospital_surgery_type_name"],
            "surgery_type.hospital_surgery_type_name:startsWith":
              queryArg["surgery_type.hospital_surgery_type_name:startsWith"],
            "surgery_type.hospital_surgery_type_name:endsWith":
              queryArg["surgery_type.hospital_surgery_type_name:endsWith"],
            "surgery_type.hospital_surgery_type_name:contains":
              queryArg["surgery_type.hospital_surgery_type_name:contains"],
            "surgery_type.hospital_surgery_type_name:in":
              queryArg["surgery_type.hospital_surgery_type_name:in"],
            "surgery_type.id": queryArg["surgery_type.id"],
            "surgery_type.id:in": queryArg["surgery_type.id:in"],
            exclude_surgery_type: queryArg.excludeSurgeryType,
          },
        }),
        providesTags: ["hospital_surgery_type_group_associations"],
      }),
      createHospitalSurgeryTypeGroupAssociation: build.mutation<
        CreateHospitalSurgeryTypeGroupAssociationApiResponse,
        CreateHospitalSurgeryTypeGroupAssociationApiArg
      >({
        query: (queryArg) => ({
          url: `/hospital_surgery_type_group_associations/`,
          method: "POST",
          body: queryArg.hospitalSurgeryTypeGroupAssociationCreate,
        }),
        invalidatesTags: ["hospital_surgery_type_group_associations"],
      }),
      createBatchHospitalSurgeryTypeGroupAssociation: build.mutation<
        CreateBatchHospitalSurgeryTypeGroupAssociationApiResponse,
        CreateBatchHospitalSurgeryTypeGroupAssociationApiArg
      >({
        query: (queryArg) => ({
          url: `/hospital_surgery_type_group_associations/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["hospital_surgery_type_group_associations"],
      }),
      deleteBatchHospitalSurgeryTypeGroupAssociation: build.mutation<
        DeleteBatchHospitalSurgeryTypeGroupAssociationApiResponse,
        DeleteBatchHospitalSurgeryTypeGroupAssociationApiArg
      >({
        query: (queryArg) => ({
          url: `/hospital_surgery_type_group_associations/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["hospital_surgery_type_group_associations"],
      }),
      updateBatchHospitalSurgeryTypeGroupAssociation: build.mutation<
        UpdateBatchHospitalSurgeryTypeGroupAssociationApiResponse,
        UpdateBatchHospitalSurgeryTypeGroupAssociationApiArg
      >({
        query: (queryArg) => ({
          url: `/hospital_surgery_type_group_associations/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["hospital_surgery_type_group_associations"],
      }),
      deleteHospitalSurgeryTypeGroupAssociation: build.mutation<
        DeleteHospitalSurgeryTypeGroupAssociationApiResponse,
        DeleteHospitalSurgeryTypeGroupAssociationApiArg
      >({
        query: (queryArg) => ({
          url: `/hospital_surgery_type_group_associations/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["hospital_surgery_type_group_associations"],
      }),
      getHospitalSurgeryTypeGroupAssociation: build.query<
        GetHospitalSurgeryTypeGroupAssociationApiResponse,
        GetHospitalSurgeryTypeGroupAssociationApiArg
      >({
        query: (queryArg) => ({
          url: `/hospital_surgery_type_group_associations/${queryArg.id}`,
          params: { exclude_surgery_type: queryArg.excludeSurgeryType },
        }),
        providesTags: ["hospital_surgery_type_group_associations"],
      }),
      updateHospitalSurgeryTypeGroupAssociation: build.mutation<
        UpdateHospitalSurgeryTypeGroupAssociationApiResponse,
        UpdateHospitalSurgeryTypeGroupAssociationApiArg
      >({
        query: (queryArg) => ({
          url: `/hospital_surgery_type_group_associations/${queryArg.id}`,
          method: "PUT",
          body: queryArg.hospitalSurgeryTypeGroupAssociationCreate,
        }),
        invalidatesTags: ["hospital_surgery_type_group_associations"],
      }),
      getHospitalSurgeryTypes: build.query<
        GetHospitalSurgeryTypesApiResponse,
        GetHospitalSurgeryTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/hospital_surgery_types/`,
          params: {
            hospital_surgery_type_id: queryArg.hospitalSurgeryTypeId,
            "hospital_surgery_type_id:in":
              queryArg["hospital_surgery_type_id:in"],
            hospital_surgery_type_name: queryArg.hospitalSurgeryTypeName,
            "hospital_surgery_type_name:startsWith":
              queryArg["hospital_surgery_type_name:startsWith"],
            "hospital_surgery_type_name:endsWith":
              queryArg["hospital_surgery_type_name:endsWith"],
            "hospital_surgery_type_name:contains":
              queryArg["hospital_surgery_type_name:contains"],
            "hospital_surgery_type_name:in":
              queryArg["hospital_surgery_type_name:in"],
            id: queryArg.id,
            "id:in": queryArg["id:in"],
          },
        }),
        providesTags: ["hospital_surgery_types"],
      }),
      createHospitalSurgeryType: build.mutation<
        CreateHospitalSurgeryTypeApiResponse,
        CreateHospitalSurgeryTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/hospital_surgery_types/`,
          method: "POST",
          body: queryArg.hospitalSurgeryTypeCreate,
        }),
        invalidatesTags: ["hospital_surgery_types"],
      }),
      createBatchHospitalSurgeryType: build.mutation<
        CreateBatchHospitalSurgeryTypeApiResponse,
        CreateBatchHospitalSurgeryTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/hospital_surgery_types/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["hospital_surgery_types"],
      }),
      deleteBatchHospitalSurgeryType: build.mutation<
        DeleteBatchHospitalSurgeryTypeApiResponse,
        DeleteBatchHospitalSurgeryTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/hospital_surgery_types/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["hospital_surgery_types"],
      }),
      updateBatchHospitalSurgeryType: build.mutation<
        UpdateBatchHospitalSurgeryTypeApiResponse,
        UpdateBatchHospitalSurgeryTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/hospital_surgery_types/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["hospital_surgery_types"],
      }),
      deleteHospitalSurgeryType: build.mutation<
        DeleteHospitalSurgeryTypeApiResponse,
        DeleteHospitalSurgeryTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/hospital_surgery_types/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["hospital_surgery_types"],
      }),
      getHospitalSurgeryType: build.query<
        GetHospitalSurgeryTypeApiResponse,
        GetHospitalSurgeryTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/hospital_surgery_types/${queryArg.id}`,
        }),
        providesTags: ["hospital_surgery_types"],
      }),
      updateHospitalSurgeryType: build.mutation<
        UpdateHospitalSurgeryTypeApiResponse,
        UpdateHospitalSurgeryTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/hospital_surgery_types/${queryArg.id}`,
          method: "PUT",
          body: queryArg.hospitalSurgeryTypeCreate,
        }),
        invalidatesTags: ["hospital_surgery_types"],
      }),
      getLocationSchedules: build.query<
        GetLocationSchedulesApiResponse,
        GetLocationSchedulesApiArg
      >({
        query: (queryArg) => ({
          url: `/location_schedules/`,
          params: {
            start_time: queryArg.startTime,
            "start_time:gt": queryArg["start_time:gt"],
            "start_time:lt": queryArg["start_time:lt"],
            "start_time:gte": queryArg["start_time:gte"],
            "start_time:lte": queryArg["start_time:lte"],
            "start_time:in": queryArg["start_time:in"],
            end_time: queryArg.endTime,
            "end_time:gt": queryArg["end_time:gt"],
            "end_time:lt": queryArg["end_time:lt"],
            "end_time:gte": queryArg["end_time:gte"],
            "end_time:lte": queryArg["end_time:lte"],
            "end_time:in": queryArg["end_time:in"],
            duration: queryArg.duration,
            "duration:eq": queryArg["duration:eq"],
            tzid: queryArg.tzid,
            "tzid:startsWith": queryArg["tzid:startsWith"],
            "tzid:endsWith": queryArg["tzid:endsWith"],
            "tzid:contains": queryArg["tzid:contains"],
            "tzid:in": queryArg["tzid:in"],
            all_day: queryArg.allDay,
            "all_day:eq": queryArg["all_day:eq"],
            comment: queryArg.comment,
            "comment:startsWith": queryArg["comment:startsWith"],
            "comment:endsWith": queryArg["comment:endsWith"],
            "comment:contains": queryArg["comment:contains"],
            "comment:in": queryArg["comment:in"],
            location_id: queryArg.locationId,
            "location_id:in": queryArg["location_id:in"],
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            "location.id": queryArg["location.id"],
            "location.id:in": queryArg["location.id:in"],
            "location.room_code": queryArg["location.room_code"],
            "location.room_code:in": queryArg["location.room_code:in"],
            exclude_location: queryArg.excludeLocation,
          },
        }),
        providesTags: ["location_schedules"],
      }),
      createLocationSchedule: build.mutation<
        CreateLocationScheduleApiResponse,
        CreateLocationScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/location_schedules/`,
          method: "POST",
          body: queryArg.locationScheduleCreate,
        }),
        invalidatesTags: ["location_schedules"],
      }),
      createBatchLocationSchedule: build.mutation<
        CreateBatchLocationScheduleApiResponse,
        CreateBatchLocationScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/location_schedules/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["location_schedules"],
      }),
      deleteBatchLocationSchedule: build.mutation<
        DeleteBatchLocationScheduleApiResponse,
        DeleteBatchLocationScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/location_schedules/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["location_schedules"],
      }),
      updateBatchLocationSchedule: build.mutation<
        UpdateBatchLocationScheduleApiResponse,
        UpdateBatchLocationScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/location_schedules/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["location_schedules"],
      }),
      deleteLocationSchedule: build.mutation<
        DeleteLocationScheduleApiResponse,
        DeleteLocationScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/location_schedules/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["location_schedules"],
      }),
      getLocationSchedule: build.query<
        GetLocationScheduleApiResponse,
        GetLocationScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/location_schedules/${queryArg.id}`,
          params: { exclude_location: queryArg.excludeLocation },
        }),
        providesTags: ["location_schedules"],
      }),
      updateLocationSchedule: build.mutation<
        UpdateLocationScheduleApiResponse,
        UpdateLocationScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/location_schedules/${queryArg.id}`,
          method: "PUT",
          body: queryArg.locationScheduleCreate,
        }),
        invalidatesTags: ["location_schedules"],
      }),
      getLocations: build.query<GetLocationsApiResponse, GetLocationsApiArg>({
        query: (queryArg) => ({
          url: `/locations/`,
          params: {
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            room_code: queryArg.roomCode,
            "room_code:in": queryArg["room_code:in"],
            "departments.id": queryArg["departments.id"],
            "departments.id:in": queryArg["departments.id:in"],
            "departments.name": queryArg["departments.name"],
            "departments.name:startsWith":
              queryArg["departments.name:startsWith"],
            "departments.name:endsWith": queryArg["departments.name:endsWith"],
            "departments.name:contains": queryArg["departments.name:contains"],
            "departments.name:in": queryArg["departments.name:in"],
            "departments.dips_id": queryArg["departments.dips_id"],
            "departments.dips_id:in": queryArg["departments.dips_id:in"],
            exclude_departments: queryArg.excludeDepartments,
          },
        }),
        providesTags: ["locations"],
      }),
      createLocation: build.mutation<
        CreateLocationApiResponse,
        CreateLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/locations/`,
          method: "POST",
          body: queryArg.locationCreate,
        }),
        invalidatesTags: ["locations"],
      }),
      createBatchLocation: build.mutation<
        CreateBatchLocationApiResponse,
        CreateBatchLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/locations/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["locations"],
      }),
      deleteBatchLocation: build.mutation<
        DeleteBatchLocationApiResponse,
        DeleteBatchLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/locations/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["locations"],
      }),
      updateBatchLocation: build.mutation<
        UpdateBatchLocationApiResponse,
        UpdateBatchLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/locations/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["locations"],
      }),
      deleteLocation: build.mutation<
        DeleteLocationApiResponse,
        DeleteLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/locations/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["locations"],
      }),
      getLocation: build.query<GetLocationApiResponse, GetLocationApiArg>({
        query: (queryArg) => ({
          url: `/locations/${queryArg.id}`,
          params: { exclude_departments: queryArg.excludeDepartments },
        }),
        providesTags: ["locations"],
      }),
      updateLocation: build.mutation<
        UpdateLocationApiResponse,
        UpdateLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/locations/${queryArg.id}`,
          method: "PUT",
          body: queryArg.locationCreate,
        }),
        invalidatesTags: ["locations"],
      }),
      authOauthAuthGet: build.query<
        AuthOauthAuthGetApiResponse,
        AuthOauthAuthGetApiArg
      >({
        query: () => ({ url: `/oauth/auth` }),
        providesTags: ["authentication"],
      }),
      login: build.query<LoginApiResponse, LoginApiArg>({
        query: (queryArg) => ({
          url: `/oauth/login`,
          params: {
            oauth_client: queryArg.oauthClient,
            redirect_url: queryArg.redirectUrl,
          },
        }),
        providesTags: ["authentication"],
      }),
      logoutOauthLogoutGet: build.query<
        LogoutOauthLogoutGetApiResponse,
        LogoutOauthLogoutGetApiArg
      >({
        query: () => ({ url: `/oauth/logout` }),
        providesTags: ["authentication"],
      }),
      revocationOauthRevocationGet: build.query<
        RevocationOauthRevocationGetApiResponse,
        RevocationOauthRevocationGetApiArg
      >({
        query: () => ({ url: `/oauth/revocation` }),
        providesTags: ["authentication"],
      }),
      getOrRefreshTokenOauthTokenGet: build.query<
        GetOrRefreshTokenOauthTokenGetApiResponse,
        GetOrRefreshTokenOauthTokenGetApiArg
      >({
        query: () => ({ url: `/oauth/token` }),
        providesTags: ["authentication"],
      }),
      getUserinfoOauthUserinfoGet: build.query<
        GetUserinfoOauthUserinfoGetApiResponse,
        GetUserinfoOauthUserinfoGetApiArg
      >({
        query: () => ({ url: `/oauth/userinfo` }),
        providesTags: ["authentication"],
      }),
      getOnCallSchedules: build.query<
        GetOnCallSchedulesApiResponse,
        GetOnCallSchedulesApiArg
      >({
        query: (queryArg) => ({
          url: `/on_call_schedules/`,
          params: {
            start_time: queryArg.startTime,
            "start_time:gt": queryArg["start_time:gt"],
            "start_time:lt": queryArg["start_time:lt"],
            "start_time:gte": queryArg["start_time:gte"],
            "start_time:lte": queryArg["start_time:lte"],
            "start_time:in": queryArg["start_time:in"],
            end_time: queryArg.endTime,
            "end_time:gt": queryArg["end_time:gt"],
            "end_time:lt": queryArg["end_time:lt"],
            "end_time:gte": queryArg["end_time:gte"],
            "end_time:lte": queryArg["end_time:lte"],
            "end_time:in": queryArg["end_time:in"],
            duration: queryArg.duration,
            "duration:eq": queryArg["duration:eq"],
            tzid: queryArg.tzid,
            "tzid:startsWith": queryArg["tzid:startsWith"],
            "tzid:endsWith": queryArg["tzid:endsWith"],
            "tzid:contains": queryArg["tzid:contains"],
            "tzid:in": queryArg["tzid:in"],
            all_day: queryArg.allDay,
            "all_day:eq": queryArg["all_day:eq"],
            comment: queryArg.comment,
            "comment:startsWith": queryArg["comment:startsWith"],
            "comment:endsWith": queryArg["comment:endsWith"],
            "comment:contains": queryArg["comment:contains"],
            "comment:in": queryArg["comment:in"],
            practitioner_id: queryArg.practitionerId,
            "practitioner_id:in": queryArg["practitioner_id:in"],
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            "practitioner.short_name": queryArg["practitioner.short_name"],
            "practitioner.short_name:in":
              queryArg["practitioner.short_name:in"],
            "practitioner.id": queryArg["practitioner.id"],
            "practitioner.id:in": queryArg["practitioner.id:in"],
            exclude_practitioner: queryArg.excludePractitioner,
          },
        }),
        providesTags: ["on_call_schedules"],
      }),
      createOnCallSchedule: build.mutation<
        CreateOnCallScheduleApiResponse,
        CreateOnCallScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/on_call_schedules/`,
          method: "POST",
          body: queryArg.onCallScheduleCreate,
        }),
        invalidatesTags: ["on_call_schedules"],
      }),
      createBatchOnCallSchedule: build.mutation<
        CreateBatchOnCallScheduleApiResponse,
        CreateBatchOnCallScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/on_call_schedules/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["on_call_schedules"],
      }),
      deleteBatchOnCallSchedule: build.mutation<
        DeleteBatchOnCallScheduleApiResponse,
        DeleteBatchOnCallScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/on_call_schedules/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["on_call_schedules"],
      }),
      updateBatchOnCallSchedule: build.mutation<
        UpdateBatchOnCallScheduleApiResponse,
        UpdateBatchOnCallScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/on_call_schedules/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["on_call_schedules"],
      }),
      deleteOnCallSchedule: build.mutation<
        DeleteOnCallScheduleApiResponse,
        DeleteOnCallScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/on_call_schedules/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["on_call_schedules"],
      }),
      getOnCallSchedule: build.query<
        GetOnCallScheduleApiResponse,
        GetOnCallScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/on_call_schedules/${queryArg.id}`,
          params: { exclude_practitioner: queryArg.excludePractitioner },
        }),
        providesTags: ["on_call_schedules"],
      }),
      updateOnCallSchedule: build.mutation<
        UpdateOnCallScheduleApiResponse,
        UpdateOnCallScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/on_call_schedules/${queryArg.id}`,
          method: "PUT",
          body: queryArg.onCallScheduleCreate,
        }),
        invalidatesTags: ["on_call_schedules"],
      }),
      getPatientGroupAgeRestrictions: build.query<
        GetPatientGroupAgeRestrictionsApiResponse,
        GetPatientGroupAgeRestrictionsApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_group_age_restrictions/`,
          params: {
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            patient_group_id: queryArg.patientGroupId,
            "patient_group_id:gt": queryArg["patient_group_id:gt"],
            "patient_group_id:lt": queryArg["patient_group_id:lt"],
            "patient_group_id:gte": queryArg["patient_group_id:gte"],
            "patient_group_id:lte": queryArg["patient_group_id:lte"],
            "patient_group_id:in": queryArg["patient_group_id:in"],
            age_group_id: queryArg.ageGroupId,
            "age_group_id:gt": queryArg["age_group_id:gt"],
            "age_group_id:lt": queryArg["age_group_id:lt"],
            "age_group_id:gte": queryArg["age_group_id:gte"],
            "age_group_id:lte": queryArg["age_group_id:lte"],
            "age_group_id:in": queryArg["age_group_id:in"],
          },
        }),
        providesTags: ["patient_group_age_restrictions"],
      }),
      createPatientGroupAgeRestriction: build.mutation<
        CreatePatientGroupAgeRestrictionApiResponse,
        CreatePatientGroupAgeRestrictionApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_group_age_restrictions/`,
          method: "POST",
          body: queryArg.patientGroupAgeRestrictionCreate,
        }),
        invalidatesTags: ["patient_group_age_restrictions"],
      }),
      createBatchPatientGroupAgeRestriction: build.mutation<
        CreateBatchPatientGroupAgeRestrictionApiResponse,
        CreateBatchPatientGroupAgeRestrictionApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_group_age_restrictions/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["patient_group_age_restrictions"],
      }),
      deleteBatchPatientGroupAgeRestriction: build.mutation<
        DeleteBatchPatientGroupAgeRestrictionApiResponse,
        DeleteBatchPatientGroupAgeRestrictionApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_group_age_restrictions/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["patient_group_age_restrictions"],
      }),
      updateBatchPatientGroupAgeRestriction: build.mutation<
        UpdateBatchPatientGroupAgeRestrictionApiResponse,
        UpdateBatchPatientGroupAgeRestrictionApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_group_age_restrictions/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["patient_group_age_restrictions"],
      }),
      deletePatientGroupAgeRestriction: build.mutation<
        DeletePatientGroupAgeRestrictionApiResponse,
        DeletePatientGroupAgeRestrictionApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_group_age_restrictions/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["patient_group_age_restrictions"],
      }),
      getPatientGroupAgeRestriction: build.query<
        GetPatientGroupAgeRestrictionApiResponse,
        GetPatientGroupAgeRestrictionApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_group_age_restrictions/${queryArg.id}`,
        }),
        providesTags: ["patient_group_age_restrictions"],
      }),
      updatePatientGroupAgeRestriction: build.mutation<
        UpdatePatientGroupAgeRestrictionApiResponse,
        UpdatePatientGroupAgeRestrictionApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_group_age_restrictions/${queryArg.id}`,
          method: "PUT",
          body: queryArg.patientGroupAgeRestrictionCreate,
        }),
        invalidatesTags: ["patient_group_age_restrictions"],
      }),
      getPatientGroupDefinitions: build.query<
        GetPatientGroupDefinitionsApiResponse,
        GetPatientGroupDefinitionsApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_group_definitions/`,
          params: {
            patient_group_definition_id: queryArg.patientGroupDefinitionId,
            "patient_group_definition_id:in":
              queryArg["patient_group_definition_id:in"],
            name: queryArg.name,
            "name:startsWith": queryArg["name:startsWith"],
            "name:endsWith": queryArg["name:endsWith"],
            "name:contains": queryArg["name:contains"],
            "name:in": queryArg["name:in"],
            id: queryArg.id,
            "id:in": queryArg["id:in"],
          },
        }),
        providesTags: ["patient_group_definitions"],
      }),
      createPatientGroupDefinition: build.mutation<
        CreatePatientGroupDefinitionApiResponse,
        CreatePatientGroupDefinitionApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_group_definitions/`,
          method: "POST",
          body: queryArg.patientGroupDefinitionCreate,
        }),
        invalidatesTags: ["patient_group_definitions"],
      }),
      createBatchPatientGroupDefinition: build.mutation<
        CreateBatchPatientGroupDefinitionApiResponse,
        CreateBatchPatientGroupDefinitionApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_group_definitions/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["patient_group_definitions"],
      }),
      deleteBatchPatientGroupDefinition: build.mutation<
        DeleteBatchPatientGroupDefinitionApiResponse,
        DeleteBatchPatientGroupDefinitionApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_group_definitions/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["patient_group_definitions"],
      }),
      updateBatchPatientGroupDefinition: build.mutation<
        UpdateBatchPatientGroupDefinitionApiResponse,
        UpdateBatchPatientGroupDefinitionApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_group_definitions/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["patient_group_definitions"],
      }),
      deletePatientGroupDefinition: build.mutation<
        DeletePatientGroupDefinitionApiResponse,
        DeletePatientGroupDefinitionApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_group_definitions/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["patient_group_definitions"],
      }),
      getPatientGroupDefinition: build.query<
        GetPatientGroupDefinitionApiResponse,
        GetPatientGroupDefinitionApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_group_definitions/${queryArg.id}`,
        }),
        providesTags: ["patient_group_definitions"],
      }),
      updatePatientGroupDefinition: build.mutation<
        UpdatePatientGroupDefinitionApiResponse,
        UpdatePatientGroupDefinitionApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_group_definitions/${queryArg.id}`,
          method: "PUT",
          body: queryArg.patientGroupDefinitionCreate,
        }),
        invalidatesTags: ["patient_group_definitions"],
      }),
      getPatientGroups: build.query<
        GetPatientGroupsApiResponse,
        GetPatientGroupsApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_groups/`,
          params: {
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            patient_group_id: queryArg.patientGroupId,
            "patient_group_id:in": queryArg["patient_group_id:in"],
            display_name: queryArg.displayName,
            "display_name:startsWith": queryArg["display_name:startsWith"],
            "display_name:endsWith": queryArg["display_name:endsWith"],
            "display_name:contains": queryArg["display_name:contains"],
            "display_name:in": queryArg["display_name:in"],
            patient_group_definition_id: queryArg.patientGroupDefinitionId,
            "patient_group_definition_id:in":
              queryArg["patient_group_definition_id:in"],
            "age_restrictions.age_group_code":
              queryArg["age_restrictions.age_group_code"],
            "age_restrictions.age_group_code:in":
              queryArg["age_restrictions.age_group_code:in"],
            "age_restrictions.display_name":
              queryArg["age_restrictions.display_name"],
            "age_restrictions.display_name:startsWith":
              queryArg["age_restrictions.display_name:startsWith"],
            "age_restrictions.display_name:endsWith":
              queryArg["age_restrictions.display_name:endsWith"],
            "age_restrictions.display_name:contains":
              queryArg["age_restrictions.display_name:contains"],
            "age_restrictions.display_name:in":
              queryArg["age_restrictions.display_name:in"],
            "age_restrictions.minimum_age":
              queryArg["age_restrictions.minimum_age"],
            "age_restrictions.minimum_age:gt":
              queryArg["age_restrictions.minimum_age:gt"],
            "age_restrictions.minimum_age:lt":
              queryArg["age_restrictions.minimum_age:lt"],
            "age_restrictions.minimum_age:gte":
              queryArg["age_restrictions.minimum_age:gte"],
            "age_restrictions.minimum_age:lte":
              queryArg["age_restrictions.minimum_age:lte"],
            "age_restrictions.minimum_age:in":
              queryArg["age_restrictions.minimum_age:in"],
            "age_restrictions.maximum_age":
              queryArg["age_restrictions.maximum_age"],
            "age_restrictions.maximum_age:gt":
              queryArg["age_restrictions.maximum_age:gt"],
            "age_restrictions.maximum_age:lt":
              queryArg["age_restrictions.maximum_age:lt"],
            "age_restrictions.maximum_age:gte":
              queryArg["age_restrictions.maximum_age:gte"],
            "age_restrictions.maximum_age:lte":
              queryArg["age_restrictions.maximum_age:lte"],
            "age_restrictions.maximum_age:in":
              queryArg["age_restrictions.maximum_age:in"],
            "age_restrictions.id": queryArg["age_restrictions.id"],
            "age_restrictions.id:in": queryArg["age_restrictions.id:in"],
            exclude_age_restrictions: queryArg.excludeAgeRestrictions,
          },
        }),
        providesTags: ["patient_groups"],
      }),
      createPatientGroup: build.mutation<
        CreatePatientGroupApiResponse,
        CreatePatientGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_groups/`,
          method: "POST",
          body: queryArg.patientGroupCreate,
        }),
        invalidatesTags: ["patient_groups"],
      }),
      createBatchPatientGroup: build.mutation<
        CreateBatchPatientGroupApiResponse,
        CreateBatchPatientGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_groups/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["patient_groups"],
      }),
      deleteBatchPatientGroup: build.mutation<
        DeleteBatchPatientGroupApiResponse,
        DeleteBatchPatientGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_groups/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["patient_groups"],
      }),
      updateBatchPatientGroup: build.mutation<
        UpdateBatchPatientGroupApiResponse,
        UpdateBatchPatientGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_groups/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["patient_groups"],
      }),
      deletePatientGroup: build.mutation<
        DeletePatientGroupApiResponse,
        DeletePatientGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_groups/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["patient_groups"],
      }),
      getPatientGroup: build.query<
        GetPatientGroupApiResponse,
        GetPatientGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_groups/${queryArg.id}`,
          params: { exclude_age_restrictions: queryArg.excludeAgeRestrictions },
        }),
        providesTags: ["patient_groups"],
      }),
      updatePatientGroup: build.mutation<
        UpdatePatientGroupApiResponse,
        UpdatePatientGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/patient_groups/${queryArg.id}`,
          method: "PUT",
          body: queryArg.patientGroupCreate,
        }),
        invalidatesTags: ["patient_groups"],
      }),
      getPractitionerScheduleLocations: build.query<
        GetPractitionerScheduleLocationsApiResponse,
        GetPractitionerScheduleLocationsApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedule_locations/`,
          params: {
            practitioner_schedule_id: queryArg.practitionerScheduleId,
            "practitioner_schedule_id:in":
              queryArg["practitioner_schedule_id:in"],
            location_id: queryArg.locationId,
            "location_id:in": queryArg["location_id:in"],
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            "practitioner_schedule.start_time":
              queryArg["practitioner_schedule.start_time"],
            "practitioner_schedule.start_time:gt":
              queryArg["practitioner_schedule.start_time:gt"],
            "practitioner_schedule.start_time:lt":
              queryArg["practitioner_schedule.start_time:lt"],
            "practitioner_schedule.start_time:gte":
              queryArg["practitioner_schedule.start_time:gte"],
            "practitioner_schedule.start_time:lte":
              queryArg["practitioner_schedule.start_time:lte"],
            "practitioner_schedule.start_time:in":
              queryArg["practitioner_schedule.start_time:in"],
            "practitioner_schedule.end_time":
              queryArg["practitioner_schedule.end_time"],
            "practitioner_schedule.end_time:gt":
              queryArg["practitioner_schedule.end_time:gt"],
            "practitioner_schedule.end_time:lt":
              queryArg["practitioner_schedule.end_time:lt"],
            "practitioner_schedule.end_time:gte":
              queryArg["practitioner_schedule.end_time:gte"],
            "practitioner_schedule.end_time:lte":
              queryArg["practitioner_schedule.end_time:lte"],
            "practitioner_schedule.end_time:in":
              queryArg["practitioner_schedule.end_time:in"],
            "practitioner_schedule.duration":
              queryArg["practitioner_schedule.duration"],
            "practitioner_schedule.duration:eq":
              queryArg["practitioner_schedule.duration:eq"],
            "practitioner_schedule.tzid":
              queryArg["practitioner_schedule.tzid"],
            "practitioner_schedule.tzid:startsWith":
              queryArg["practitioner_schedule.tzid:startsWith"],
            "practitioner_schedule.tzid:endsWith":
              queryArg["practitioner_schedule.tzid:endsWith"],
            "practitioner_schedule.tzid:contains":
              queryArg["practitioner_schedule.tzid:contains"],
            "practitioner_schedule.tzid:in":
              queryArg["practitioner_schedule.tzid:in"],
            "practitioner_schedule.all_day":
              queryArg["practitioner_schedule.all_day"],
            "practitioner_schedule.all_day:eq":
              queryArg["practitioner_schedule.all_day:eq"],
            "practitioner_schedule.comment":
              queryArg["practitioner_schedule.comment"],
            "practitioner_schedule.comment:startsWith":
              queryArg["practitioner_schedule.comment:startsWith"],
            "practitioner_schedule.comment:endsWith":
              queryArg["practitioner_schedule.comment:endsWith"],
            "practitioner_schedule.comment:contains":
              queryArg["practitioner_schedule.comment:contains"],
            "practitioner_schedule.comment:in":
              queryArg["practitioner_schedule.comment:in"],
            "practitioner_schedule.practitioner_id":
              queryArg["practitioner_schedule.practitioner_id"],
            "practitioner_schedule.practitioner_id:in":
              queryArg["practitioner_schedule.practitioner_id:in"],
            "practitioner_schedule.location_id":
              queryArg["practitioner_schedule.location_id"],
            "practitioner_schedule.location_id:in":
              queryArg["practitioner_schedule.location_id:in"],
            "practitioner_schedule.id": queryArg["practitioner_schedule.id"],
            "practitioner_schedule.id:in":
              queryArg["practitioner_schedule.id:in"],
            exclude_practitioner_schedule: queryArg.excludePractitionerSchedule,
            "location.id": queryArg["location.id"],
            "location.id:in": queryArg["location.id:in"],
            "location.room_code": queryArg["location.room_code"],
            "location.room_code:in": queryArg["location.room_code:in"],
            exclude_location: queryArg.excludeLocation,
          },
        }),
        providesTags: ["practitioner_schedule_locations"],
      }),
      createPractitionerScheduleLocation: build.mutation<
        CreatePractitionerScheduleLocationApiResponse,
        CreatePractitionerScheduleLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedule_locations/`,
          method: "POST",
          body: queryArg.practitionerScheduleLocationCreate,
        }),
        invalidatesTags: ["practitioner_schedule_locations"],
      }),
      createBatchPractitionerScheduleLocation: build.mutation<
        CreateBatchPractitionerScheduleLocationApiResponse,
        CreateBatchPractitionerScheduleLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedule_locations/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["practitioner_schedule_locations"],
      }),
      deleteBatchPractitionerScheduleLocation: build.mutation<
        DeleteBatchPractitionerScheduleLocationApiResponse,
        DeleteBatchPractitionerScheduleLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedule_locations/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["practitioner_schedule_locations"],
      }),
      updateBatchPractitionerScheduleLocation: build.mutation<
        UpdateBatchPractitionerScheduleLocationApiResponse,
        UpdateBatchPractitionerScheduleLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedule_locations/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["practitioner_schedule_locations"],
      }),
      deletePractitionerScheduleLocation: build.mutation<
        DeletePractitionerScheduleLocationApiResponse,
        DeletePractitionerScheduleLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedule_locations/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["practitioner_schedule_locations"],
      }),
      getPractitionerScheduleLocation: build.query<
        GetPractitionerScheduleLocationApiResponse,
        GetPractitionerScheduleLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedule_locations/${queryArg.id}`,
          params: {
            exclude_practitioner_schedule: queryArg.excludePractitionerSchedule,
            exclude_location: queryArg.excludeLocation,
          },
        }),
        providesTags: ["practitioner_schedule_locations"],
      }),
      updatePractitionerScheduleLocation: build.mutation<
        UpdatePractitionerScheduleLocationApiResponse,
        UpdatePractitionerScheduleLocationApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedule_locations/${queryArg.id}`,
          method: "PUT",
          body: queryArg.practitionerScheduleLocationCreate,
        }),
        invalidatesTags: ["practitioner_schedule_locations"],
      }),
      getPractitionerScheduleStatuses: build.query<
        GetPractitionerScheduleStatusesApiResponse,
        GetPractitionerScheduleStatusesApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedule_statuses/`,
          params: {
            practitioner_schedule_id: queryArg.practitionerScheduleId,
            "practitioner_schedule_id:in":
              queryArg["practitioner_schedule_id:in"],
            status_code: queryArg.statusCode,
            "status_code:startsWith": queryArg["status_code:startsWith"],
            "status_code:endsWith": queryArg["status_code:endsWith"],
            "status_code:contains": queryArg["status_code:contains"],
            "status_code:in": queryArg["status_code:in"],
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            "practitioner_schedule.start_time":
              queryArg["practitioner_schedule.start_time"],
            "practitioner_schedule.start_time:gt":
              queryArg["practitioner_schedule.start_time:gt"],
            "practitioner_schedule.start_time:lt":
              queryArg["practitioner_schedule.start_time:lt"],
            "practitioner_schedule.start_time:gte":
              queryArg["practitioner_schedule.start_time:gte"],
            "practitioner_schedule.start_time:lte":
              queryArg["practitioner_schedule.start_time:lte"],
            "practitioner_schedule.start_time:in":
              queryArg["practitioner_schedule.start_time:in"],
            "practitioner_schedule.end_time":
              queryArg["practitioner_schedule.end_time"],
            "practitioner_schedule.end_time:gt":
              queryArg["practitioner_schedule.end_time:gt"],
            "practitioner_schedule.end_time:lt":
              queryArg["practitioner_schedule.end_time:lt"],
            "practitioner_schedule.end_time:gte":
              queryArg["practitioner_schedule.end_time:gte"],
            "practitioner_schedule.end_time:lte":
              queryArg["practitioner_schedule.end_time:lte"],
            "practitioner_schedule.end_time:in":
              queryArg["practitioner_schedule.end_time:in"],
            "practitioner_schedule.duration":
              queryArg["practitioner_schedule.duration"],
            "practitioner_schedule.duration:eq":
              queryArg["practitioner_schedule.duration:eq"],
            "practitioner_schedule.tzid":
              queryArg["practitioner_schedule.tzid"],
            "practitioner_schedule.tzid:startsWith":
              queryArg["practitioner_schedule.tzid:startsWith"],
            "practitioner_schedule.tzid:endsWith":
              queryArg["practitioner_schedule.tzid:endsWith"],
            "practitioner_schedule.tzid:contains":
              queryArg["practitioner_schedule.tzid:contains"],
            "practitioner_schedule.tzid:in":
              queryArg["practitioner_schedule.tzid:in"],
            "practitioner_schedule.all_day":
              queryArg["practitioner_schedule.all_day"],
            "practitioner_schedule.all_day:eq":
              queryArg["practitioner_schedule.all_day:eq"],
            "practitioner_schedule.comment":
              queryArg["practitioner_schedule.comment"],
            "practitioner_schedule.comment:startsWith":
              queryArg["practitioner_schedule.comment:startsWith"],
            "practitioner_schedule.comment:endsWith":
              queryArg["practitioner_schedule.comment:endsWith"],
            "practitioner_schedule.comment:contains":
              queryArg["practitioner_schedule.comment:contains"],
            "practitioner_schedule.comment:in":
              queryArg["practitioner_schedule.comment:in"],
            "practitioner_schedule.practitioner_id":
              queryArg["practitioner_schedule.practitioner_id"],
            "practitioner_schedule.practitioner_id:in":
              queryArg["practitioner_schedule.practitioner_id:in"],
            "practitioner_schedule.location_id":
              queryArg["practitioner_schedule.location_id"],
            "practitioner_schedule.location_id:in":
              queryArg["practitioner_schedule.location_id:in"],
            "practitioner_schedule.id": queryArg["practitioner_schedule.id"],
            "practitioner_schedule.id:in":
              queryArg["practitioner_schedule.id:in"],
            exclude_practitioner_schedule: queryArg.excludePractitionerSchedule,
          },
        }),
        providesTags: ["practitioner_schedule_statuses"],
      }),
      createPractitionerScheduleStatus: build.mutation<
        CreatePractitionerScheduleStatusApiResponse,
        CreatePractitionerScheduleStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedule_statuses/`,
          method: "POST",
          body: queryArg.practitionerScheduleStatusCreate,
        }),
        invalidatesTags: ["practitioner_schedule_statuses"],
      }),
      createBatchPractitionerScheduleStatus: build.mutation<
        CreateBatchPractitionerScheduleStatusApiResponse,
        CreateBatchPractitionerScheduleStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedule_statuses/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["practitioner_schedule_statuses"],
      }),
      deleteBatchPractitionerScheduleStatus: build.mutation<
        DeleteBatchPractitionerScheduleStatusApiResponse,
        DeleteBatchPractitionerScheduleStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedule_statuses/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["practitioner_schedule_statuses"],
      }),
      updateBatchPractitionerScheduleStatus: build.mutation<
        UpdateBatchPractitionerScheduleStatusApiResponse,
        UpdateBatchPractitionerScheduleStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedule_statuses/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["practitioner_schedule_statuses"],
      }),
      deletePractitionerScheduleStatus: build.mutation<
        DeletePractitionerScheduleStatusApiResponse,
        DeletePractitionerScheduleStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedule_statuses/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["practitioner_schedule_statuses"],
      }),
      getPractitionerScheduleStatus: build.query<
        GetPractitionerScheduleStatusApiResponse,
        GetPractitionerScheduleStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedule_statuses/${queryArg.id}`,
          params: {
            exclude_practitioner_schedule: queryArg.excludePractitionerSchedule,
          },
        }),
        providesTags: ["practitioner_schedule_statuses"],
      }),
      updatePractitionerScheduleStatus: build.mutation<
        UpdatePractitionerScheduleStatusApiResponse,
        UpdatePractitionerScheduleStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedule_statuses/${queryArg.id}`,
          method: "PUT",
          body: queryArg.practitionerScheduleStatusCreate,
        }),
        invalidatesTags: ["practitioner_schedule_statuses"],
      }),
      getPractitionerSchedules: build.query<
        GetPractitionerSchedulesApiResponse,
        GetPractitionerSchedulesApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedules/`,
          params: {
            start_time: queryArg.startTime,
            "start_time:gt": queryArg["start_time:gt"],
            "start_time:lt": queryArg["start_time:lt"],
            "start_time:gte": queryArg["start_time:gte"],
            "start_time:lte": queryArg["start_time:lte"],
            "start_time:in": queryArg["start_time:in"],
            end_time: queryArg.endTime,
            "end_time:gt": queryArg["end_time:gt"],
            "end_time:lt": queryArg["end_time:lt"],
            "end_time:gte": queryArg["end_time:gte"],
            "end_time:lte": queryArg["end_time:lte"],
            "end_time:in": queryArg["end_time:in"],
            duration: queryArg.duration,
            "duration:eq": queryArg["duration:eq"],
            tzid: queryArg.tzid,
            "tzid:startsWith": queryArg["tzid:startsWith"],
            "tzid:endsWith": queryArg["tzid:endsWith"],
            "tzid:contains": queryArg["tzid:contains"],
            "tzid:in": queryArg["tzid:in"],
            all_day: queryArg.allDay,
            "all_day:eq": queryArg["all_day:eq"],
            comment: queryArg.comment,
            "comment:startsWith": queryArg["comment:startsWith"],
            "comment:endsWith": queryArg["comment:endsWith"],
            "comment:contains": queryArg["comment:contains"],
            "comment:in": queryArg["comment:in"],
            practitioner_id: queryArg.practitionerId,
            "practitioner_id:in": queryArg["practitioner_id:in"],
            location_id: queryArg.locationId,
            "location_id:in": queryArg["location_id:in"],
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            "location.id": queryArg["location.id"],
            "location.id:in": queryArg["location.id:in"],
            "location.room_code": queryArg["location.room_code"],
            "location.room_code:in": queryArg["location.room_code:in"],
            exclude_location: queryArg.excludeLocation,
            "practitioner.short_name": queryArg["practitioner.short_name"],
            "practitioner.short_name:in":
              queryArg["practitioner.short_name:in"],
            "practitioner.id": queryArg["practitioner.id"],
            "practitioner.id:in": queryArg["practitioner.id:in"],
            exclude_practitioner: queryArg.excludePractitioner,
            "statuses.practitioner_schedule_id":
              queryArg["statuses.practitioner_schedule_id"],
            "statuses.practitioner_schedule_id:in":
              queryArg["statuses.practitioner_schedule_id:in"],
            "statuses.status_code": queryArg["statuses.status_code"],
            "statuses.status_code:startsWith":
              queryArg["statuses.status_code:startsWith"],
            "statuses.status_code:endsWith":
              queryArg["statuses.status_code:endsWith"],
            "statuses.status_code:contains":
              queryArg["statuses.status_code:contains"],
            "statuses.status_code:in": queryArg["statuses.status_code:in"],
            "statuses.id": queryArg["statuses.id"],
            "statuses.id:in": queryArg["statuses.id:in"],
            exclude_statuses: queryArg.excludeStatuses,
            "locations.practitioner_schedule_id":
              queryArg["locations.practitioner_schedule_id"],
            "locations.practitioner_schedule_id:in":
              queryArg["locations.practitioner_schedule_id:in"],
            "locations.location_id": queryArg["locations.location_id"],
            "locations.location_id:in": queryArg["locations.location_id:in"],
            "locations.id": queryArg["locations.id"],
            "locations.id:in": queryArg["locations.id:in"],
            exclude_locations: queryArg.excludeLocations,
          },
        }),
        providesTags: ["practitioner_schedules"],
      }),
      createPractitionerSchedule: build.mutation<
        CreatePractitionerScheduleApiResponse,
        CreatePractitionerScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedules/`,
          method: "POST",
          body: queryArg.practitionerScheduleCreate,
        }),
        invalidatesTags: ["practitioner_schedules"],
      }),
      createBatchPractitionerSchedule: build.mutation<
        CreateBatchPractitionerScheduleApiResponse,
        CreateBatchPractitionerScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedules/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["practitioner_schedules"],
      }),
      deleteBatchPractitionerSchedule: build.mutation<
        DeleteBatchPractitionerScheduleApiResponse,
        DeleteBatchPractitionerScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedules/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["practitioner_schedules"],
      }),
      updateBatchPractitionerSchedule: build.mutation<
        UpdateBatchPractitionerScheduleApiResponse,
        UpdateBatchPractitionerScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedules/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["practitioner_schedules"],
      }),
      deletePractitionerSchedule: build.mutation<
        DeletePractitionerScheduleApiResponse,
        DeletePractitionerScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedules/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["practitioner_schedules"],
      }),
      getPractitionerSchedule: build.query<
        GetPractitionerScheduleApiResponse,
        GetPractitionerScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedules/${queryArg.id}`,
          params: {
            exclude_location: queryArg.excludeLocation,
            exclude_practitioner: queryArg.excludePractitioner,
            exclude_statuses: queryArg.excludeStatuses,
            exclude_locations: queryArg.excludeLocations,
          },
        }),
        providesTags: ["practitioner_schedules"],
      }),
      updatePractitionerSchedule: build.mutation<
        UpdatePractitionerScheduleApiResponse,
        UpdatePractitionerScheduleApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioner_schedules/${queryArg.id}`,
          method: "PUT",
          body: queryArg.practitionerScheduleCreate,
        }),
        invalidatesTags: ["practitioner_schedules"],
      }),
      getPractitioners: build.query<
        GetPractitionersApiResponse,
        GetPractitionersApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioners/`,
          params: {
            short_name: queryArg.shortName,
            "short_name:in": queryArg["short_name:in"],
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            "departments.id": queryArg["departments.id"],
            "departments.id:in": queryArg["departments.id:in"],
            "departments.name": queryArg["departments.name"],
            "departments.name:startsWith":
              queryArg["departments.name:startsWith"],
            "departments.name:endsWith": queryArg["departments.name:endsWith"],
            "departments.name:contains": queryArg["departments.name:contains"],
            "departments.name:in": queryArg["departments.name:in"],
            "departments.dips_id": queryArg["departments.dips_id"],
            "departments.dips_id:in": queryArg["departments.dips_id:in"],
            exclude_departments: queryArg.excludeDepartments,
          },
        }),
        providesTags: ["practitioners"],
      }),
      createPractitioner: build.mutation<
        CreatePractitionerApiResponse,
        CreatePractitionerApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioners/`,
          method: "POST",
          body: queryArg.practitionerCreate,
        }),
        invalidatesTags: ["practitioners"],
      }),
      createBatchPractitioner: build.mutation<
        CreateBatchPractitionerApiResponse,
        CreateBatchPractitionerApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioners/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["practitioners"],
      }),
      deleteBatchPractitioner: build.mutation<
        DeleteBatchPractitionerApiResponse,
        DeleteBatchPractitionerApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioners/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["practitioners"],
      }),
      updateBatchPractitioner: build.mutation<
        UpdateBatchPractitionerApiResponse,
        UpdateBatchPractitionerApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioners/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["practitioners"],
      }),
      deletePractitioner: build.mutation<
        DeletePractitionerApiResponse,
        DeletePractitionerApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioners/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["practitioners"],
      }),
      getPractitioner: build.query<
        GetPractitionerApiResponse,
        GetPractitionerApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioners/${queryArg.id}`,
          params: { exclude_departments: queryArg.excludeDepartments },
        }),
        providesTags: ["practitioners"],
      }),
      updatePractitioner: build.mutation<
        UpdatePractitionerApiResponse,
        UpdatePractitionerApiArg
      >({
        query: (queryArg) => ({
          url: `/practitioners/${queryArg.id}`,
          method: "PUT",
          body: queryArg.practitionerCreate,
        }),
        invalidatesTags: ["practitioners"],
      }),
      getRuleDefinitions: build.query<
        GetRuleDefinitionsApiResponse,
        GetRuleDefinitionsApiArg
      >({
        query: (queryArg) => ({
          url: `/rule_definitions/`,
          params: {
            rule_id: queryArg.ruleId,
            "rule_id:in": queryArg["rule_id:in"],
            type: queryArg["type"],
            "type:in": queryArg["type:in"],
            name: queryArg.name,
            "name:startsWith": queryArg["name:startsWith"],
            "name:endsWith": queryArg["name:endsWith"],
            "name:contains": queryArg["name:contains"],
            "name:in": queryArg["name:in"],
            id: queryArg.id,
            "id:in": queryArg["id:in"],
          },
        }),
        providesTags: ["rule_definitions"],
      }),
      createRuleDefinition: build.mutation<
        CreateRuleDefinitionApiResponse,
        CreateRuleDefinitionApiArg
      >({
        query: (queryArg) => ({
          url: `/rule_definitions/`,
          method: "POST",
          body: queryArg.ruleDefinitionCreate,
        }),
        invalidatesTags: ["rule_definitions"],
      }),
      createBatchRuleDefinition: build.mutation<
        CreateBatchRuleDefinitionApiResponse,
        CreateBatchRuleDefinitionApiArg
      >({
        query: (queryArg) => ({
          url: `/rule_definitions/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["rule_definitions"],
      }),
      deleteBatchRuleDefinition: build.mutation<
        DeleteBatchRuleDefinitionApiResponse,
        DeleteBatchRuleDefinitionApiArg
      >({
        query: (queryArg) => ({
          url: `/rule_definitions/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["rule_definitions"],
      }),
      updateBatchRuleDefinition: build.mutation<
        UpdateBatchRuleDefinitionApiResponse,
        UpdateBatchRuleDefinitionApiArg
      >({
        query: (queryArg) => ({
          url: `/rule_definitions/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["rule_definitions"],
      }),
      deleteRuleDefinition: build.mutation<
        DeleteRuleDefinitionApiResponse,
        DeleteRuleDefinitionApiArg
      >({
        query: (queryArg) => ({
          url: `/rule_definitions/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["rule_definitions"],
      }),
      getRuleDefinition: build.query<
        GetRuleDefinitionApiResponse,
        GetRuleDefinitionApiArg
      >({
        query: (queryArg) => ({ url: `/rule_definitions/${queryArg.id}` }),
        providesTags: ["rule_definitions"],
      }),
      updateRuleDefinition: build.mutation<
        UpdateRuleDefinitionApiResponse,
        UpdateRuleDefinitionApiArg
      >({
        query: (queryArg) => ({
          url: `/rule_definitions/${queryArg.id}`,
          method: "PUT",
          body: queryArg.ruleDefinitionCreate,
        }),
        invalidatesTags: ["rule_definitions"],
      }),
      getSections: build.query<GetSectionsApiResponse, GetSectionsApiArg>({
        query: (queryArg) => ({
          url: `/sections/`,
          params: {
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            dips_section_id: queryArg.dipsSectionId,
            "dips_section_id:gt": queryArg["dips_section_id:gt"],
            "dips_section_id:lt": queryArg["dips_section_id:lt"],
            "dips_section_id:gte": queryArg["dips_section_id:gte"],
            "dips_section_id:lte": queryArg["dips_section_id:lte"],
            "dips_section_id:in": queryArg["dips_section_id:in"],
            name: queryArg.name,
            "name:startsWith": queryArg["name:startsWith"],
            "name:endsWith": queryArg["name:endsWith"],
            "name:contains": queryArg["name:contains"],
            "name:in": queryArg["name:in"],
            department_id: queryArg.departmentId,
            "department_id:gt": queryArg["department_id:gt"],
            "department_id:lt": queryArg["department_id:lt"],
            "department_id:gte": queryArg["department_id:gte"],
            "department_id:lte": queryArg["department_id:lte"],
            "department_id:in": queryArg["department_id:in"],
          },
        }),
        providesTags: ["sections"],
      }),
      createSection: build.mutation<
        CreateSectionApiResponse,
        CreateSectionApiArg
      >({
        query: (queryArg) => ({
          url: `/sections/`,
          method: "POST",
          body: queryArg.sectionCreate,
        }),
        invalidatesTags: ["sections"],
      }),
      createBatchSection: build.mutation<
        CreateBatchSectionApiResponse,
        CreateBatchSectionApiArg
      >({
        query: (queryArg) => ({
          url: `/sections/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["sections"],
      }),
      deleteBatchSection: build.mutation<
        DeleteBatchSectionApiResponse,
        DeleteBatchSectionApiArg
      >({
        query: (queryArg) => ({
          url: `/sections/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["sections"],
      }),
      updateBatchSection: build.mutation<
        UpdateBatchSectionApiResponse,
        UpdateBatchSectionApiArg
      >({
        query: (queryArg) => ({
          url: `/sections/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["sections"],
      }),
      deleteSection: build.mutation<
        DeleteSectionApiResponse,
        DeleteSectionApiArg
      >({
        query: (queryArg) => ({
          url: `/sections/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["sections"],
      }),
      getSection: build.query<GetSectionApiResponse, GetSectionApiArg>({
        query: (queryArg) => ({ url: `/sections/${queryArg.id}` }),
        providesTags: ["sections"],
      }),
      updateSection: build.mutation<
        UpdateSectionApiResponse,
        UpdateSectionApiArg
      >({
        query: (queryArg) => ({
          url: `/sections/${queryArg.id}`,
          method: "PUT",
          body: queryArg.sectionCreate,
        }),
        invalidatesTags: ["sections"],
      }),
      getSpecialities: build.query<
        GetSpecialitiesApiResponse,
        GetSpecialitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/specialities/`,
          params: {
            name: queryArg.name,
            "name:startsWith": queryArg["name:startsWith"],
            "name:endsWith": queryArg["name:endsWith"],
            "name:contains": queryArg["name:contains"],
            "name:in": queryArg["name:in"],
            id: queryArg.id,
            "id:in": queryArg["id:in"],
          },
        }),
        providesTags: ["specialities"],
      }),
      createSpeciality: build.mutation<
        CreateSpecialityApiResponse,
        CreateSpecialityApiArg
      >({
        query: (queryArg) => ({
          url: `/specialities/`,
          method: "POST",
          body: queryArg.specialityCreate,
        }),
        invalidatesTags: ["specialities"],
      }),
      createBatchSpeciality: build.mutation<
        CreateBatchSpecialityApiResponse,
        CreateBatchSpecialityApiArg
      >({
        query: (queryArg) => ({
          url: `/specialities/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["specialities"],
      }),
      deleteBatchSpeciality: build.mutation<
        DeleteBatchSpecialityApiResponse,
        DeleteBatchSpecialityApiArg
      >({
        query: (queryArg) => ({
          url: `/specialities/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["specialities"],
      }),
      updateBatchSpeciality: build.mutation<
        UpdateBatchSpecialityApiResponse,
        UpdateBatchSpecialityApiArg
      >({
        query: (queryArg) => ({
          url: `/specialities/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["specialities"],
      }),
      deleteSpeciality: build.mutation<
        DeleteSpecialityApiResponse,
        DeleteSpecialityApiArg
      >({
        query: (queryArg) => ({
          url: `/specialities/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["specialities"],
      }),
      getSpeciality: build.query<GetSpecialityApiResponse, GetSpecialityApiArg>(
        {
          query: (queryArg) => ({ url: `/specialities/${queryArg.id}` }),
          providesTags: ["specialities"],
        }
      ),
      updateSpeciality: build.mutation<
        UpdateSpecialityApiResponse,
        UpdateSpecialityApiArg
      >({
        query: (queryArg) => ({
          url: `/specialities/${queryArg.id}`,
          method: "PUT",
          body: queryArg.specialityCreate,
        }),
        invalidatesTags: ["specialities"],
      }),
      getSurgeryTypeGroupAgeRestrictions: build.query<
        GetSurgeryTypeGroupAgeRestrictionsApiResponse,
        GetSurgeryTypeGroupAgeRestrictionsApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_age_restrictions/`,
          params: {
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            surgery_type_group_id: queryArg.surgeryTypeGroupId,
            "surgery_type_group_id:gt": queryArg["surgery_type_group_id:gt"],
            "surgery_type_group_id:lt": queryArg["surgery_type_group_id:lt"],
            "surgery_type_group_id:gte": queryArg["surgery_type_group_id:gte"],
            "surgery_type_group_id:lte": queryArg["surgery_type_group_id:lte"],
            "surgery_type_group_id:in": queryArg["surgery_type_group_id:in"],
            age_group_id: queryArg.ageGroupId,
            "age_group_id:gt": queryArg["age_group_id:gt"],
            "age_group_id:lt": queryArg["age_group_id:lt"],
            "age_group_id:gte": queryArg["age_group_id:gte"],
            "age_group_id:lte": queryArg["age_group_id:lte"],
            "age_group_id:in": queryArg["age_group_id:in"],
          },
        }),
        providesTags: ["surgery_type_group_age_restrictions"],
      }),
      createSurgeryTypeGroupAgeRestriction: build.mutation<
        CreateSurgeryTypeGroupAgeRestrictionApiResponse,
        CreateSurgeryTypeGroupAgeRestrictionApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_age_restrictions/`,
          method: "POST",
          body: queryArg.surgeryTypeGroupAgeRestrictionCreate,
        }),
        invalidatesTags: ["surgery_type_group_age_restrictions"],
      }),
      createBatchSurgeryTypeGroupAgeRestriction: build.mutation<
        CreateBatchSurgeryTypeGroupAgeRestrictionApiResponse,
        CreateBatchSurgeryTypeGroupAgeRestrictionApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_age_restrictions/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["surgery_type_group_age_restrictions"],
      }),
      deleteBatchSurgeryTypeGroupAgeRestriction: build.mutation<
        DeleteBatchSurgeryTypeGroupAgeRestrictionApiResponse,
        DeleteBatchSurgeryTypeGroupAgeRestrictionApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_age_restrictions/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["surgery_type_group_age_restrictions"],
      }),
      updateBatchSurgeryTypeGroupAgeRestriction: build.mutation<
        UpdateBatchSurgeryTypeGroupAgeRestrictionApiResponse,
        UpdateBatchSurgeryTypeGroupAgeRestrictionApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_age_restrictions/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["surgery_type_group_age_restrictions"],
      }),
      deleteSurgeryTypeGroupAgeRestriction: build.mutation<
        DeleteSurgeryTypeGroupAgeRestrictionApiResponse,
        DeleteSurgeryTypeGroupAgeRestrictionApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_age_restrictions/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["surgery_type_group_age_restrictions"],
      }),
      getSurgeryTypeGroupAgeRestriction: build.query<
        GetSurgeryTypeGroupAgeRestrictionApiResponse,
        GetSurgeryTypeGroupAgeRestrictionApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_age_restrictions/${queryArg.id}`,
        }),
        providesTags: ["surgery_type_group_age_restrictions"],
      }),
      updateSurgeryTypeGroupAgeRestriction: build.mutation<
        UpdateSurgeryTypeGroupAgeRestrictionApiResponse,
        UpdateSurgeryTypeGroupAgeRestrictionApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_age_restrictions/${queryArg.id}`,
          method: "PUT",
          body: queryArg.surgeryTypeGroupAgeRestrictionCreate,
        }),
        invalidatesTags: ["surgery_type_group_age_restrictions"],
      }),
      getSurgeryTypeGroupHierarchies: build.query<
        GetSurgeryTypeGroupHierarchiesApiResponse,
        GetSurgeryTypeGroupHierarchiesApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_hierarchies/`,
          params: {
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            parent_surgery_type_group_id: queryArg.parentSurgeryTypeGroupId,
            "parent_surgery_type_group_id:in":
              queryArg["parent_surgery_type_group_id:in"],
            child_surgery_type_group_id: queryArg.childSurgeryTypeGroupId,
            "child_surgery_type_group_id:in":
              queryArg["child_surgery_type_group_id:in"],
          },
        }),
        providesTags: ["surgery_type_group_hierarchies"],
      }),
      createSurgeryTypeGroupHierarchy: build.mutation<
        CreateSurgeryTypeGroupHierarchyApiResponse,
        CreateSurgeryTypeGroupHierarchyApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_hierarchies/`,
          method: "POST",
          body: queryArg.surgeryTypeGroupHierarchyCreate,
        }),
        invalidatesTags: ["surgery_type_group_hierarchies"],
      }),
      createBatchSurgeryTypeGroupHierarchy: build.mutation<
        CreateBatchSurgeryTypeGroupHierarchyApiResponse,
        CreateBatchSurgeryTypeGroupHierarchyApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_hierarchies/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["surgery_type_group_hierarchies"],
      }),
      deleteBatchSurgeryTypeGroupHierarchy: build.mutation<
        DeleteBatchSurgeryTypeGroupHierarchyApiResponse,
        DeleteBatchSurgeryTypeGroupHierarchyApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_hierarchies/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["surgery_type_group_hierarchies"],
      }),
      updateBatchSurgeryTypeGroupHierarchy: build.mutation<
        UpdateBatchSurgeryTypeGroupHierarchyApiResponse,
        UpdateBatchSurgeryTypeGroupHierarchyApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_hierarchies/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["surgery_type_group_hierarchies"],
      }),
      deleteSurgeryTypeGroupHierarchy: build.mutation<
        DeleteSurgeryTypeGroupHierarchyApiResponse,
        DeleteSurgeryTypeGroupHierarchyApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_hierarchies/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["surgery_type_group_hierarchies"],
      }),
      getSurgeryTypeGroupHierarchy: build.query<
        GetSurgeryTypeGroupHierarchyApiResponse,
        GetSurgeryTypeGroupHierarchyApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_hierarchies/${queryArg.id}`,
        }),
        providesTags: ["surgery_type_group_hierarchies"],
      }),
      updateSurgeryTypeGroupHierarchy: build.mutation<
        UpdateSurgeryTypeGroupHierarchyApiResponse,
        UpdateSurgeryTypeGroupHierarchyApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_hierarchies/${queryArg.id}`,
          method: "PUT",
          body: queryArg.surgeryTypeGroupHierarchyCreate,
        }),
        invalidatesTags: ["surgery_type_group_hierarchies"],
      }),
      getSurgeryTypeGroupSpecialities: build.query<
        GetSurgeryTypeGroupSpecialitiesApiResponse,
        GetSurgeryTypeGroupSpecialitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_specialities/`,
          params: {
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            surgery_type_group_id: queryArg.surgeryTypeGroupId,
            "surgery_type_group_id:in": queryArg["surgery_type_group_id:in"],
            speciality_id: queryArg.specialityId,
            "speciality_id:in": queryArg["speciality_id:in"],
          },
        }),
        providesTags: ["surgery_type_group_specialities"],
      }),
      createSurgeryTypeGroupSpeciality: build.mutation<
        CreateSurgeryTypeGroupSpecialityApiResponse,
        CreateSurgeryTypeGroupSpecialityApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_specialities/`,
          method: "POST",
          body: queryArg.surgeryTypeGroupSpecialityCreate,
        }),
        invalidatesTags: ["surgery_type_group_specialities"],
      }),
      createBatchSurgeryTypeGroupSpeciality: build.mutation<
        CreateBatchSurgeryTypeGroupSpecialityApiResponse,
        CreateBatchSurgeryTypeGroupSpecialityApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_specialities/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["surgery_type_group_specialities"],
      }),
      deleteBatchSurgeryTypeGroupSpeciality: build.mutation<
        DeleteBatchSurgeryTypeGroupSpecialityApiResponse,
        DeleteBatchSurgeryTypeGroupSpecialityApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_specialities/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["surgery_type_group_specialities"],
      }),
      updateBatchSurgeryTypeGroupSpeciality: build.mutation<
        UpdateBatchSurgeryTypeGroupSpecialityApiResponse,
        UpdateBatchSurgeryTypeGroupSpecialityApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_specialities/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["surgery_type_group_specialities"],
      }),
      deleteSurgeryTypeGroupSpeciality: build.mutation<
        DeleteSurgeryTypeGroupSpecialityApiResponse,
        DeleteSurgeryTypeGroupSpecialityApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_specialities/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["surgery_type_group_specialities"],
      }),
      getSurgeryTypeGroupSpeciality: build.query<
        GetSurgeryTypeGroupSpecialityApiResponse,
        GetSurgeryTypeGroupSpecialityApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_specialities/${queryArg.id}`,
        }),
        providesTags: ["surgery_type_group_specialities"],
      }),
      updateSurgeryTypeGroupSpeciality: build.mutation<
        UpdateSurgeryTypeGroupSpecialityApiResponse,
        UpdateSurgeryTypeGroupSpecialityApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_group_specialities/${queryArg.id}`,
          method: "PUT",
          body: queryArg.surgeryTypeGroupSpecialityCreate,
        }),
        invalidatesTags: ["surgery_type_group_specialities"],
      }),
      getSurgeryTypeGroups: build.query<
        GetSurgeryTypeGroupsApiResponse,
        GetSurgeryTypeGroupsApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_groups/`,
          params: {
            code: queryArg.code,
            "code:in": queryArg["code:in"],
            display_name: queryArg.displayName,
            "display_name:startsWith": queryArg["display_name:startsWith"],
            "display_name:endsWith": queryArg["display_name:endsWith"],
            "display_name:contains": queryArg["display_name:contains"],
            "display_name:in": queryArg["display_name:in"],
            display_children: queryArg.displayChildren,
            "display_children:eq": queryArg["display_children:eq"],
            hidden: queryArg.hidden,
            "hidden:eq": queryArg["hidden:eq"],
            group_type: queryArg.groupType,
            "group_type:startsWith": queryArg["group_type:startsWith"],
            "group_type:endsWith": queryArg["group_type:endsWith"],
            "group_type:contains": queryArg["group_type:contains"],
            "group_type:in": queryArg["group_type:in"],
            id: queryArg.id,
            "id:in": queryArg["id:in"],
          },
        }),
        providesTags: ["surgery_type_groups"],
      }),
      createSurgeryTypeGroup: build.mutation<
        CreateSurgeryTypeGroupApiResponse,
        CreateSurgeryTypeGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_groups/`,
          method: "POST",
          body: queryArg.surgeryTypeGroupCreate,
        }),
        invalidatesTags: ["surgery_type_groups"],
      }),
      createBatchSurgeryTypeGroup: build.mutation<
        CreateBatchSurgeryTypeGroupApiResponse,
        CreateBatchSurgeryTypeGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_groups/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["surgery_type_groups"],
      }),
      deleteBatchSurgeryTypeGroup: build.mutation<
        DeleteBatchSurgeryTypeGroupApiResponse,
        DeleteBatchSurgeryTypeGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_groups/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["surgery_type_groups"],
      }),
      updateBatchSurgeryTypeGroup: build.mutation<
        UpdateBatchSurgeryTypeGroupApiResponse,
        UpdateBatchSurgeryTypeGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_groups/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["surgery_type_groups"],
      }),
      deleteSurgeryTypeGroup: build.mutation<
        DeleteSurgeryTypeGroupApiResponse,
        DeleteSurgeryTypeGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_groups/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["surgery_type_groups"],
      }),
      getSurgeryTypeGroup: build.query<
        GetSurgeryTypeGroupApiResponse,
        GetSurgeryTypeGroupApiArg
      >({
        query: (queryArg) => ({ url: `/surgery_type_groups/${queryArg.id}` }),
        providesTags: ["surgery_type_groups"],
      }),
      updateSurgeryTypeGroup: build.mutation<
        UpdateSurgeryTypeGroupApiResponse,
        UpdateSurgeryTypeGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/surgery_type_groups/${queryArg.id}`,
          method: "PUT",
          body: queryArg.surgeryTypeGroupCreate,
        }),
        invalidatesTags: ["surgery_type_groups"],
      }),
      version: build.query<VersionApiResponse, VersionApiArg>({
        query: () => ({ url: `/version` }),
        providesTags: ["version"],
      }),
      getWaitingListItems: build.query<
        GetWaitingListItemsApiResponse,
        GetWaitingListItemsApiArg
      >({
        query: (queryArg) => ({
          url: `/waiting_list_items/`,
          params: {
            id: queryArg.id,
            "id:in": queryArg["id:in"],
            booking_id: queryArg.bookingId,
            "booking_id:startsWith": queryArg["booking_id:startsWith"],
            "booking_id:endsWith": queryArg["booking_id:endsWith"],
            "booking_id:contains": queryArg["booking_id:contains"],
            "booking_id:in": queryArg["booking_id:in"],
            comment: queryArg.comment,
            "comment:startsWith": queryArg["comment:startsWith"],
            "comment:endsWith": queryArg["comment:endsWith"],
            "comment:contains": queryArg["comment:contains"],
            "comment:in": queryArg["comment:in"],
          },
        }),
        providesTags: ["waiting_list_items"],
      }),
      createWaitingListItem: build.mutation<
        CreateWaitingListItemApiResponse,
        CreateWaitingListItemApiArg
      >({
        query: (queryArg) => ({
          url: `/waiting_list_items/`,
          method: "POST",
          body: queryArg.waitingListItemCreate,
        }),
        invalidatesTags: ["waiting_list_items"],
      }),
      createBatchWaitingListItem: build.mutation<
        CreateBatchWaitingListItemApiResponse,
        CreateBatchWaitingListItemApiArg
      >({
        query: (queryArg) => ({
          url: `/waiting_list_items/batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["waiting_list_items"],
      }),
      deleteBatchWaitingListItem: build.mutation<
        DeleteBatchWaitingListItemApiResponse,
        DeleteBatchWaitingListItemApiArg
      >({
        query: (queryArg) => ({
          url: `/waiting_list_items/delete-batch`,
          method: "POST",
          body: queryArg.ids,
        }),
        invalidatesTags: ["waiting_list_items"],
      }),
      updateBatchWaitingListItem: build.mutation<
        UpdateBatchWaitingListItemApiResponse,
        UpdateBatchWaitingListItemApiArg
      >({
        query: (queryArg) => ({
          url: `/waiting_list_items/update-batch`,
          method: "POST",
          body: queryArg.inputs,
        }),
        invalidatesTags: ["waiting_list_items"],
      }),
      deleteWaitingListItem: build.mutation<
        DeleteWaitingListItemApiResponse,
        DeleteWaitingListItemApiArg
      >({
        query: (queryArg) => ({
          url: `/waiting_list_items/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["waiting_list_items"],
      }),
      getWaitingListItem: build.query<
        GetWaitingListItemApiResponse,
        GetWaitingListItemApiArg
      >({
        query: (queryArg) => ({ url: `/waiting_list_items/${queryArg.id}` }),
        providesTags: ["waiting_list_items"],
      }),
      updateWaitingListItem: build.mutation<
        UpdateWaitingListItemApiResponse,
        UpdateWaitingListItemApiArg
      >({
        query: (queryArg) => ({
          url: `/waiting_list_items/${queryArg.id}`,
          method: "PUT",
          body: queryArg.waitingListItemCreate,
        }),
        invalidatesTags: ["waiting_list_items"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as diApi };
export type GetAgeGroupsApiResponse =
  /** status 200 Successful Response */ AgeGroupOut[];
export type GetAgeGroupsApiArg = {
  ageGroupCode?: AgeGroupCode;
  "age_group_code:in"?: string;
  displayName?: string | null;
  "display_name:startsWith"?: string | null;
  "display_name:endsWith"?: string | null;
  "display_name:contains"?: string | null;
  "display_name:in"?: string;
  minimumAge?: number | null;
  "minimum_age:gt"?: number | null;
  "minimum_age:lt"?: number | null;
  "minimum_age:gte"?: number | null;
  "minimum_age:lte"?: number | null;
  "minimum_age:in"?: string;
  maximumAge?: number | null;
  "maximum_age:gt"?: number | null;
  "maximum_age:lt"?: number | null;
  "maximum_age:gte"?: number | null;
  "maximum_age:lte"?: number | null;
  "maximum_age:in"?: string;
  id?: number;
  "id:in"?: string;
};
export type CreateAgeGroupApiResponse =
  /** status 200 Successful Response */ AgeGroupOut;
export type CreateAgeGroupApiArg = {
  ageGroupCreate: AgeGroupCreate;
};
export type CreateBatchAgeGroupApiResponse =
  /** status 200 Successful Response */ AgeGroupOut[];
export type CreateBatchAgeGroupApiArg = {
  inputs: AgeGroupCreate[];
};
export type DeleteBatchAgeGroupApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchAgeGroupApiArg = {
  ids: number[];
};
export type UpdateBatchAgeGroupApiResponse =
  /** status 200 Successful Response */ AgeGroupOut[];
export type UpdateBatchAgeGroupApiArg = {
  inputs: BaseUpdateEntitySchemaAgeGroupCreate[];
};
export type DeleteAgeGroupApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteAgeGroupApiArg = {
  id: number;
};
export type GetAgeGroupApiResponse =
  /** status 200 Successful Response */ AgeGroupOut;
export type GetAgeGroupApiArg = {
  id: number;
};
export type UpdateAgeGroupApiResponse =
  /** status 200 Successful Response */ AgeGroupOut;
export type UpdateAgeGroupApiArg = {
  id: number;
  ageGroupCreate: AgeGroupCreate;
};
export type AuthRedirectAuthGetApiResponse =
  /** status 200 Successful Response */ any;
export type AuthRedirectAuthGetApiArg = void;
export type GetBlockExceptionsApiResponse =
  /** status 200 Successful Response */ BlockExceptionOutPopulated[];
export type GetBlockExceptionsApiArg = {
  id?: number;
  "id:in"?: string;
  ruleInstanceId?: number;
  "rule_instance_id:in"?: string;
  "rule_instance.rule_definition_id"?: "max_count_of_surgeries_per_patient_group";
  "rule_instance.rule_definition_id:in"?: string;
  "rule_instance.name"?: string;
  "rule_instance.name:startsWith"?: string;
  "rule_instance.name:endsWith"?: string;
  "rule_instance.name:contains"?: string;
  "rule_instance.name:in"?: string;
  "rule_instance.comment"?: string | null;
  "rule_instance.comment:startsWith"?: string | null;
  "rule_instance.comment:endsWith"?: string | null;
  "rule_instance.comment:contains"?: string | null;
  "rule_instance.comment:in"?: string;
  "rule_instance.id"?: number;
  "rule_instance.id:in"?: string;
  excludeRuleInstance?: boolean;
  blockScheduleId?: number;
  "block_schedule_id:in"?: string;
  startTime?: string;
  "start_time:gt"?: string;
  "start_time:lt"?: string;
  "start_time:gte"?: string;
  "start_time:lte"?: string;
  "start_time:in"?: string;
  endTime?: string;
  "end_time:gt"?: string;
  "end_time:lt"?: string;
  "end_time:gte"?: string;
  "end_time:lte"?: string;
  "end_time:in"?: string;
};
export type CreateBlockExceptionApiResponse =
  /** status 200 Successful Response */ BlockExceptionOut;
export type CreateBlockExceptionApiArg = {
  blockExceptionCreate: BlockExceptionCreate;
};
export type CreateBatchBlockExceptionApiResponse =
  /** status 200 Successful Response */ BlockExceptionOutPopulated[];
export type CreateBatchBlockExceptionApiArg = {
  inputs: BlockExceptionCreate[];
};
export type DeleteBatchBlockExceptionApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchBlockExceptionApiArg = {
  ids: number[];
};
export type UpdateBatchBlockExceptionApiResponse =
  /** status 200 Successful Response */ BlockExceptionOut[];
export type UpdateBatchBlockExceptionApiArg = {
  inputs: BaseUpdateEntitySchemaBlockExceptionCreate[];
};
export type DeleteBlockExceptionApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBlockExceptionApiArg = {
  id: number;
};
export type GetBlockExceptionApiResponse =
  /** status 200 Successful Response */ BlockExceptionOutPopulated;
export type GetBlockExceptionApiArg = {
  id: number;
  excludeRuleInstance?: boolean;
};
export type UpdateBlockExceptionApiResponse =
  /** status 200 Successful Response */ BlockExceptionOut;
export type UpdateBlockExceptionApiArg = {
  id: number;
  blockExceptionCreate: BlockExceptionCreate;
};
export type GetBlockLocksApiResponse =
  /** status 200 Successful Response */ BlockLockOutPopulated[];
export type GetBlockLocksApiArg = {
  id?: number;
  "id:in"?: string;
  startTime?: string;
  "start_time:gt"?: string;
  "start_time:lt"?: string;
  "start_time:gte"?: string;
  "start_time:lte"?: string;
  "start_time:in"?: string;
  endTime?: string;
  "end_time:gt"?: string;
  "end_time:lt"?: string;
  "end_time:gte"?: string;
  "end_time:lte"?: string;
  "end_time:in"?: string;
  locationId?: number;
  "location_id:in"?: string;
  "location.id"?: number;
  "location.id:in"?: string;
  "location.room_code"?: string;
  "location.room_code:in"?: string;
  excludeLocation?: boolean;
};
export type CreateBlockLockApiResponse =
  /** status 200 Successful Response */ BlockLockOut;
export type CreateBlockLockApiArg = {
  blockLockCreate: BlockLockCreate;
};
export type CreateBatchBlockLockApiResponse =
  /** status 200 Successful Response */ BlockLockOutPopulated[];
export type CreateBatchBlockLockApiArg = {
  inputs: BlockLockCreate[];
};
export type DeleteBatchBlockLockApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchBlockLockApiArg = {
  ids: number[];
};
export type UpdateBatchBlockLockApiResponse =
  /** status 200 Successful Response */ BlockLockOut[];
export type UpdateBatchBlockLockApiArg = {
  inputs: BaseUpdateEntitySchemaBlockLockCreate[];
};
export type DeleteBlockLockApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBlockLockApiArg = {
  id: number;
};
export type GetBlockLockApiResponse =
  /** status 200 Successful Response */ BlockLockOutPopulated;
export type GetBlockLockApiArg = {
  id: number;
  excludeLocation?: boolean;
};
export type UpdateBlockLockApiResponse =
  /** status 200 Successful Response */ BlockLockOut;
export type UpdateBlockLockApiArg = {
  id: number;
  blockLockCreate: BlockLockCreate;
};
export type GetBlockSchedulesApiResponse =
  /** status 200 Successful Response */ BlockScheduleOutPopulated[];
export type GetBlockSchedulesApiArg = {
  startTime?: string | null;
  "start_time:gt"?: string | null;
  "start_time:lt"?: string | null;
  "start_time:gte"?: string | null;
  "start_time:lte"?: string | null;
  "start_time:in"?: string;
  endTime?: string | null;
  "end_time:gt"?: string | null;
  "end_time:lt"?: string | null;
  "end_time:gte"?: string | null;
  "end_time:lte"?: string | null;
  "end_time:in"?: string;
  duration?: string | null;
  "duration:eq"?: string | null;
  tzid?: string | null;
  "tzid:startsWith"?: string | null;
  "tzid:endsWith"?: string | null;
  "tzid:contains"?: string | null;
  "tzid:in"?: string;
  allDay?: boolean | null;
  "all_day:eq"?: boolean | null;
  comment?: string | null;
  "comment:startsWith"?: string | null;
  "comment:endsWith"?: string | null;
  "comment:contains"?: string | null;
  "comment:in"?: string;
  "rule_instance.rule_definition_id"?: "max_count_of_surgeries_per_patient_group";
  "rule_instance.rule_definition_id:in"?: string;
  "rule_instance.name"?: string;
  "rule_instance.name:startsWith"?: string;
  "rule_instance.name:endsWith"?: string;
  "rule_instance.name:contains"?: string;
  "rule_instance.name:in"?: string;
  "rule_instance.comment"?: string | null;
  "rule_instance.comment:startsWith"?: string | null;
  "rule_instance.comment:endsWith"?: string | null;
  "rule_instance.comment:contains"?: string | null;
  "rule_instance.comment:in"?: string;
  "rule_instance.id"?: number;
  "rule_instance.id:in"?: string;
  excludeRuleInstance?: boolean;
  locationId?: number | null;
  "location_id:in"?: string;
  id?: number;
  "id:in"?: string;
  ruleInstanceId?: number | null;
  "rule_instance_id:in"?: string;
  "location.id"?: number;
  "location.id:in"?: string;
  "location.room_code"?: string;
  "location.room_code:in"?: string;
  excludeLocation?: boolean;
};
export type CreateBlockScheduleApiResponse =
  /** status 200 Successful Response */ BlockScheduleOut;
export type CreateBlockScheduleApiArg = {
  blockScheduleCreate: BlockScheduleCreate;
};
export type CreateBatchBlockScheduleApiResponse =
  /** status 200 Successful Response */ BlockScheduleOutPopulated[];
export type CreateBatchBlockScheduleApiArg = {
  inputs: BlockScheduleCreate[];
};
export type DeleteBatchBlockScheduleApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchBlockScheduleApiArg = {
  ids: number[];
};
export type UpdateBatchBlockScheduleApiResponse =
  /** status 200 Successful Response */ BlockScheduleOut[];
export type UpdateBatchBlockScheduleApiArg = {
  inputs: BaseUpdateEntitySchemaBlockScheduleCreate[];
};
export type DeleteBlockScheduleApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBlockScheduleApiArg = {
  id: number;
};
export type GetBlockScheduleApiResponse =
  /** status 200 Successful Response */ BlockScheduleOutPopulated;
export type GetBlockScheduleApiArg = {
  id: number;
  excludeRuleInstance?: boolean;
  excludeLocation?: boolean;
};
export type UpdateBlockScheduleApiResponse =
  /** status 200 Successful Response */ BlockScheduleOut;
export type UpdateBlockScheduleApiArg = {
  id: number;
  blockScheduleCreate: BlockScheduleCreate;
};
export type GetDepartmentLocationAssignmentsApiResponse =
  /** status 200 Successful Response */ DepartmentLocationAssignmentOut[];
export type GetDepartmentLocationAssignmentsApiArg = {
  id?: number;
  "id:in"?: string;
  departmentId?: number;
  "department_id:in"?: string;
  locationId?: number;
  "location_id:in"?: string;
};
export type CreateDepartmentLocationAssignmentApiResponse =
  /** status 200 Successful Response */ DepartmentLocationAssignmentOut;
export type CreateDepartmentLocationAssignmentApiArg = {
  departmentLocationAssignmentCreate: DepartmentLocationAssignmentCreate;
};
export type CreateBatchDepartmentLocationAssignmentApiResponse =
  /** status 200 Successful Response */ DepartmentLocationAssignmentOut[];
export type CreateBatchDepartmentLocationAssignmentApiArg = {
  inputs: DepartmentLocationAssignmentCreate[];
};
export type DeleteBatchDepartmentLocationAssignmentApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchDepartmentLocationAssignmentApiArg = {
  ids: number[];
};
export type UpdateBatchDepartmentLocationAssignmentApiResponse =
  /** status 200 Successful Response */ DepartmentLocationAssignmentOut[];
export type UpdateBatchDepartmentLocationAssignmentApiArg = {
  inputs: BaseUpdateEntitySchemaDepartmentLocationAssignmentCreate[];
};
export type DeleteDepartmentLocationAssignmentApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteDepartmentLocationAssignmentApiArg = {
  id: number;
};
export type GetDepartmentLocationAssignmentApiResponse =
  /** status 200 Successful Response */ DepartmentLocationAssignmentOut;
export type GetDepartmentLocationAssignmentApiArg = {
  id: number;
};
export type UpdateDepartmentLocationAssignmentApiResponse =
  /** status 200 Successful Response */ DepartmentLocationAssignmentOut;
export type UpdateDepartmentLocationAssignmentApiArg = {
  id: number;
  departmentLocationAssignmentCreate: DepartmentLocationAssignmentCreate;
};
export type GetDepartmentPractitionerAssignmentsApiResponse =
  /** status 200 Successful Response */ DepartmentPractitionerAssignmentOut[];
export type GetDepartmentPractitionerAssignmentsApiArg = {
  id?: number;
  "id:in"?: string;
  departmentId?: number;
  "department_id:in"?: string;
  practitionerId?: number;
  "practitioner_id:in"?: string;
};
export type CreateDepartmentPractitionerAssignmentApiResponse =
  /** status 200 Successful Response */ DepartmentPractitionerAssignmentOut;
export type CreateDepartmentPractitionerAssignmentApiArg = {
  departmentPractitionerAssignmentCreate: DepartmentPractitionerAssignmentCreate;
};
export type CreateBatchDepartmentPractitionerAssignmentApiResponse =
  /** status 200 Successful Response */ DepartmentPractitionerAssignmentOut[];
export type CreateBatchDepartmentPractitionerAssignmentApiArg = {
  inputs: DepartmentPractitionerAssignmentCreate[];
};
export type DeleteBatchDepartmentPractitionerAssignmentApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchDepartmentPractitionerAssignmentApiArg = {
  ids: number[];
};
export type UpdateBatchDepartmentPractitionerAssignmentApiResponse =
  /** status 200 Successful Response */ DepartmentPractitionerAssignmentOut[];
export type UpdateBatchDepartmentPractitionerAssignmentApiArg = {
  inputs: BaseUpdateEntitySchemaDepartmentPractitionerAssignmentCreate[];
};
export type DeleteDepartmentPractitionerAssignmentApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteDepartmentPractitionerAssignmentApiArg = {
  id: number;
};
export type GetDepartmentPractitionerAssignmentApiResponse =
  /** status 200 Successful Response */ DepartmentPractitionerAssignmentOut;
export type GetDepartmentPractitionerAssignmentApiArg = {
  id: number;
};
export type UpdateDepartmentPractitionerAssignmentApiResponse =
  /** status 200 Successful Response */ DepartmentPractitionerAssignmentOut;
export type UpdateDepartmentPractitionerAssignmentApiArg = {
  id: number;
  departmentPractitionerAssignmentCreate: DepartmentPractitionerAssignmentCreate;
};
export type GetDepartmentsApiResponse =
  /** status 200 Successful Response */ DepartmentOutPopulated[];
export type GetDepartmentsApiArg = {
  id?: number;
  "id:in"?: string;
  name?: string;
  "name:startsWith"?: string;
  "name:endsWith"?: string;
  "name:contains"?: string;
  "name:in"?: string;
  dipsId?: number | null;
  "dips_id:in"?: string;
  "locations.id"?: number;
  "locations.id:in"?: string;
  "locations.room_code"?: string;
  "locations.room_code:in"?: string;
  excludeLocations?: boolean;
  "practitioners.short_name"?: string;
  "practitioners.short_name:in"?: string;
  "practitioners.id"?: number;
  "practitioners.id:in"?: string;
  excludePractitioners?: boolean;
  "sections.id"?: number;
  "sections.id:in"?: string;
  "sections.dips_section_id"?: number;
  "sections.dips_section_id:gt"?: number;
  "sections.dips_section_id:lt"?: number;
  "sections.dips_section_id:gte"?: number;
  "sections.dips_section_id:lte"?: number;
  "sections.dips_section_id:in"?: string;
  "sections.name"?: string;
  "sections.name:startsWith"?: string;
  "sections.name:endsWith"?: string;
  "sections.name:contains"?: string;
  "sections.name:in"?: string;
  "sections.department_id"?: number | null;
  "sections.department_id:gt"?: number | null;
  "sections.department_id:lt"?: number | null;
  "sections.department_id:gte"?: number | null;
  "sections.department_id:lte"?: number | null;
  "sections.department_id:in"?: string;
  excludeSections?: boolean;
};
export type CreateDepartmentApiResponse =
  /** status 200 Successful Response */ DepartmentOut;
export type CreateDepartmentApiArg = {
  departmentCreate: DepartmentCreate;
};
export type CreateBatchDepartmentApiResponse =
  /** status 200 Successful Response */ DepartmentOutPopulated[];
export type CreateBatchDepartmentApiArg = {
  inputs: DepartmentCreate[];
};
export type DeleteBatchDepartmentApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchDepartmentApiArg = {
  ids: number[];
};
export type UpdateBatchDepartmentApiResponse =
  /** status 200 Successful Response */ DepartmentOut[];
export type UpdateBatchDepartmentApiArg = {
  inputs: BaseUpdateEntitySchemaDepartmentCreate[];
};
export type DeleteDepartmentApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteDepartmentApiArg = {
  id: number;
};
export type GetDepartmentApiResponse =
  /** status 200 Successful Response */ DepartmentOutPopulated;
export type GetDepartmentApiArg = {
  id: number;
  excludeLocations?: boolean;
  excludePractitioners?: boolean;
  excludeSections?: boolean;
};
export type UpdateDepartmentApiResponse =
  /** status 200 Successful Response */ DepartmentOut;
export type UpdateDepartmentApiArg = {
  id: number;
  departmentCreate: DepartmentCreate;
};
export type LiveApiResponse = /** status 200 Successful Response */ any;
export type LiveApiArg = void;
export type ReadyApiResponse = /** status 200 Successful Response */ any;
export type ReadyApiArg = void;
export type GetHospitalSurgeryTypeGroupAssociationsApiResponse =
  /** status 200 Successful Response */ HospitalSurgeryTypeGroupAssociationOut[];
export type GetHospitalSurgeryTypeGroupAssociationsApiArg = {
  id?: number;
  "id:in"?: string;
  surgeryTypeGroupId?: number;
  "surgery_type_group_id:in"?: string;
  hospitalSurgeryTypeId?: number;
  "hospital_surgery_type_id:in"?: string;
  weight?: number;
  "weight:gt"?: number;
  "weight:lt"?: number;
  "weight:gte"?: number;
  "weight:lte"?: number;
  "weight:in"?: string;
  "surgery_type.hospital_surgery_type_id"?: number;
  "surgery_type.hospital_surgery_type_id:in"?: string;
  "surgery_type.hospital_surgery_type_name"?: string;
  "surgery_type.hospital_surgery_type_name:startsWith"?: string;
  "surgery_type.hospital_surgery_type_name:endsWith"?: string;
  "surgery_type.hospital_surgery_type_name:contains"?: string;
  "surgery_type.hospital_surgery_type_name:in"?: string;
  "surgery_type.id"?: number;
  "surgery_type.id:in"?: string;
  excludeSurgeryType?: boolean;
};
export type CreateHospitalSurgeryTypeGroupAssociationApiResponse =
  /** status 200 Successful Response */ HospitalSurgeryTypeGroupAssociationOut;
export type CreateHospitalSurgeryTypeGroupAssociationApiArg = {
  hospitalSurgeryTypeGroupAssociationCreate: HospitalSurgeryTypeGroupAssociationCreate;
};
export type CreateBatchHospitalSurgeryTypeGroupAssociationApiResponse =
  /** status 200 Successful Response */ HospitalSurgeryTypeGroupAssociationOut[];
export type CreateBatchHospitalSurgeryTypeGroupAssociationApiArg = {
  inputs: HospitalSurgeryTypeGroupAssociationCreate[];
};
export type DeleteBatchHospitalSurgeryTypeGroupAssociationApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchHospitalSurgeryTypeGroupAssociationApiArg = {
  ids: number[];
};
export type UpdateBatchHospitalSurgeryTypeGroupAssociationApiResponse =
  /** status 200 Successful Response */ HospitalSurgeryTypeGroupAssociationOut[];
export type UpdateBatchHospitalSurgeryTypeGroupAssociationApiArg = {
  inputs: BaseUpdateEntitySchemaHospitalSurgeryTypeGroupAssociationCreate[];
};
export type DeleteHospitalSurgeryTypeGroupAssociationApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteHospitalSurgeryTypeGroupAssociationApiArg = {
  id: number;
};
export type GetHospitalSurgeryTypeGroupAssociationApiResponse =
  /** status 200 Successful Response */ HospitalSurgeryTypeGroupAssociationOut;
export type GetHospitalSurgeryTypeGroupAssociationApiArg = {
  id: number;
  excludeSurgeryType?: boolean;
};
export type UpdateHospitalSurgeryTypeGroupAssociationApiResponse =
  /** status 200 Successful Response */ HospitalSurgeryTypeGroupAssociationOut;
export type UpdateHospitalSurgeryTypeGroupAssociationApiArg = {
  id: number;
  hospitalSurgeryTypeGroupAssociationCreate: HospitalSurgeryTypeGroupAssociationCreate;
};
export type GetHospitalSurgeryTypesApiResponse =
  /** status 200 Successful Response */ HospitalSurgeryTypeOut[];
export type GetHospitalSurgeryTypesApiArg = {
  hospitalSurgeryTypeId?: number;
  "hospital_surgery_type_id:in"?: string;
  hospitalSurgeryTypeName?: string;
  "hospital_surgery_type_name:startsWith"?: string;
  "hospital_surgery_type_name:endsWith"?: string;
  "hospital_surgery_type_name:contains"?: string;
  "hospital_surgery_type_name:in"?: string;
  id?: number;
  "id:in"?: string;
};
export type CreateHospitalSurgeryTypeApiResponse =
  /** status 200 Successful Response */ HospitalSurgeryTypeOut;
export type CreateHospitalSurgeryTypeApiArg = {
  hospitalSurgeryTypeCreate: HospitalSurgeryTypeCreate;
};
export type CreateBatchHospitalSurgeryTypeApiResponse =
  /** status 200 Successful Response */ HospitalSurgeryTypeOut[];
export type CreateBatchHospitalSurgeryTypeApiArg = {
  inputs: HospitalSurgeryTypeCreate[];
};
export type DeleteBatchHospitalSurgeryTypeApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchHospitalSurgeryTypeApiArg = {
  ids: number[];
};
export type UpdateBatchHospitalSurgeryTypeApiResponse =
  /** status 200 Successful Response */ HospitalSurgeryTypeOut[];
export type UpdateBatchHospitalSurgeryTypeApiArg = {
  inputs: BaseUpdateEntitySchemaHospitalSurgeryTypeCreate[];
};
export type DeleteHospitalSurgeryTypeApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteHospitalSurgeryTypeApiArg = {
  id: number;
};
export type GetHospitalSurgeryTypeApiResponse =
  /** status 200 Successful Response */ HospitalSurgeryTypeOut;
export type GetHospitalSurgeryTypeApiArg = {
  id: number;
};
export type UpdateHospitalSurgeryTypeApiResponse =
  /** status 200 Successful Response */ HospitalSurgeryTypeOut;
export type UpdateHospitalSurgeryTypeApiArg = {
  id: number;
  hospitalSurgeryTypeCreate: HospitalSurgeryTypeCreate;
};
export type GetLocationSchedulesApiResponse =
  /** status 200 Successful Response */ LocationScheduleOutPopulated[];
export type GetLocationSchedulesApiArg = {
  startTime?: string | null;
  "start_time:gt"?: string | null;
  "start_time:lt"?: string | null;
  "start_time:gte"?: string | null;
  "start_time:lte"?: string | null;
  "start_time:in"?: string;
  endTime?: string | null;
  "end_time:gt"?: string | null;
  "end_time:lt"?: string | null;
  "end_time:gte"?: string | null;
  "end_time:lte"?: string | null;
  "end_time:in"?: string;
  duration?: string | null;
  "duration:eq"?: string | null;
  tzid?: string | null;
  "tzid:startsWith"?: string | null;
  "tzid:endsWith"?: string | null;
  "tzid:contains"?: string | null;
  "tzid:in"?: string;
  allDay?: boolean | null;
  "all_day:eq"?: boolean | null;
  comment?: string | null;
  "comment:startsWith"?: string | null;
  "comment:endsWith"?: string | null;
  "comment:contains"?: string | null;
  "comment:in"?: string;
  locationId?: number;
  "location_id:in"?: string;
  id?: number;
  "id:in"?: string;
  "location.id"?: number;
  "location.id:in"?: string;
  "location.room_code"?: string;
  "location.room_code:in"?: string;
  excludeLocation?: boolean;
};
export type CreateLocationScheduleApiResponse =
  /** status 200 Successful Response */ LocationScheduleOut;
export type CreateLocationScheduleApiArg = {
  locationScheduleCreate: LocationScheduleCreate;
};
export type CreateBatchLocationScheduleApiResponse =
  /** status 200 Successful Response */ LocationScheduleOutPopulated[];
export type CreateBatchLocationScheduleApiArg = {
  inputs: LocationScheduleCreate[];
};
export type DeleteBatchLocationScheduleApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchLocationScheduleApiArg = {
  ids: number[];
};
export type UpdateBatchLocationScheduleApiResponse =
  /** status 200 Successful Response */ LocationScheduleOut[];
export type UpdateBatchLocationScheduleApiArg = {
  inputs: BaseUpdateEntitySchemaLocationScheduleCreate[];
};
export type DeleteLocationScheduleApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteLocationScheduleApiArg = {
  id: number;
};
export type GetLocationScheduleApiResponse =
  /** status 200 Successful Response */ LocationScheduleOutPopulated;
export type GetLocationScheduleApiArg = {
  id: number;
  excludeLocation?: boolean;
};
export type UpdateLocationScheduleApiResponse =
  /** status 200 Successful Response */ LocationScheduleOut;
export type UpdateLocationScheduleApiArg = {
  id: number;
  locationScheduleCreate: LocationScheduleCreate;
};
export type GetLocationsApiResponse =
  /** status 200 Successful Response */ LocationOutPopulated[];
export type GetLocationsApiArg = {
  id?: number;
  "id:in"?: string;
  roomCode?: string;
  "room_code:in"?: string;
  "departments.id"?: number;
  "departments.id:in"?: string;
  "departments.name"?: string;
  "departments.name:startsWith"?: string;
  "departments.name:endsWith"?: string;
  "departments.name:contains"?: string;
  "departments.name:in"?: string;
  "departments.dips_id"?: number | null;
  "departments.dips_id:in"?: string;
  excludeDepartments?: boolean;
};
export type CreateLocationApiResponse =
  /** status 200 Successful Response */ LocationOut;
export type CreateLocationApiArg = {
  locationCreate: LocationCreate;
};
export type CreateBatchLocationApiResponse =
  /** status 200 Successful Response */ LocationOutPopulated[];
export type CreateBatchLocationApiArg = {
  inputs: LocationCreate[];
};
export type DeleteBatchLocationApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchLocationApiArg = {
  ids: number[];
};
export type UpdateBatchLocationApiResponse =
  /** status 200 Successful Response */ LocationOut[];
export type UpdateBatchLocationApiArg = {
  inputs: BaseUpdateEntitySchemaLocationCreate[];
};
export type DeleteLocationApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteLocationApiArg = {
  id: number;
};
export type GetLocationApiResponse =
  /** status 200 Successful Response */ LocationOutPopulated;
export type GetLocationApiArg = {
  id: number;
  excludeDepartments?: boolean;
};
export type UpdateLocationApiResponse =
  /** status 200 Successful Response */ LocationOut;
export type UpdateLocationApiArg = {
  id: number;
  locationCreate: LocationCreate;
};
export type AuthOauthAuthGetApiResponse =
  /** status 200 Successful Response */ any;
export type AuthOauthAuthGetApiArg = void;
export type LoginApiResponse = /** status 200 Successful Response */ any;
export type LoginApiArg = {
  oauthClient?: OAuthIssuerName;
  redirectUrl?: string | null;
};
export type LogoutOauthLogoutGetApiResponse =
  /** status 200 Successful Response */ any;
export type LogoutOauthLogoutGetApiArg = void;
export type RevocationOauthRevocationGetApiResponse =
  /** status 200 Successful Response */ any;
export type RevocationOauthRevocationGetApiArg = void;
export type GetOrRefreshTokenOauthTokenGetApiResponse =
  /** status 200 Successful Response */ AccessTokenResponse;
export type GetOrRefreshTokenOauthTokenGetApiArg = void;
export type GetUserinfoOauthUserinfoGetApiResponse =
  /** status 200 Successful Response */ UserInfoResponse;
export type GetUserinfoOauthUserinfoGetApiArg = void;
export type GetOnCallSchedulesApiResponse =
  /** status 200 Successful Response */ OnCallScheduleOutPopulated[];
export type GetOnCallSchedulesApiArg = {
  startTime?: string | null;
  "start_time:gt"?: string | null;
  "start_time:lt"?: string | null;
  "start_time:gte"?: string | null;
  "start_time:lte"?: string | null;
  "start_time:in"?: string;
  endTime?: string | null;
  "end_time:gt"?: string | null;
  "end_time:lt"?: string | null;
  "end_time:gte"?: string | null;
  "end_time:lte"?: string | null;
  "end_time:in"?: string;
  duration?: string | null;
  "duration:eq"?: string | null;
  tzid?: string | null;
  "tzid:startsWith"?: string | null;
  "tzid:endsWith"?: string | null;
  "tzid:contains"?: string | null;
  "tzid:in"?: string;
  allDay?: boolean | null;
  "all_day:eq"?: boolean | null;
  comment?: string | null;
  "comment:startsWith"?: string | null;
  "comment:endsWith"?: string | null;
  "comment:contains"?: string | null;
  "comment:in"?: string;
  practitionerId?: number;
  "practitioner_id:in"?: string;
  id?: number;
  "id:in"?: string;
  "practitioner.short_name"?: string;
  "practitioner.short_name:in"?: string;
  "practitioner.id"?: number;
  "practitioner.id:in"?: string;
  excludePractitioner?: boolean;
};
export type CreateOnCallScheduleApiResponse =
  /** status 200 Successful Response */ OnCallScheduleOut;
export type CreateOnCallScheduleApiArg = {
  onCallScheduleCreate: OnCallScheduleCreate;
};
export type CreateBatchOnCallScheduleApiResponse =
  /** status 200 Successful Response */ OnCallScheduleOutPopulated[];
export type CreateBatchOnCallScheduleApiArg = {
  inputs: OnCallScheduleCreate[];
};
export type DeleteBatchOnCallScheduleApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchOnCallScheduleApiArg = {
  ids: number[];
};
export type UpdateBatchOnCallScheduleApiResponse =
  /** status 200 Successful Response */ OnCallScheduleOut[];
export type UpdateBatchOnCallScheduleApiArg = {
  inputs: BaseUpdateEntitySchemaOnCallScheduleCreate[];
};
export type DeleteOnCallScheduleApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteOnCallScheduleApiArg = {
  id: number;
};
export type GetOnCallScheduleApiResponse =
  /** status 200 Successful Response */ OnCallScheduleOutPopulated;
export type GetOnCallScheduleApiArg = {
  id: number;
  excludePractitioner?: boolean;
};
export type UpdateOnCallScheduleApiResponse =
  /** status 200 Successful Response */ OnCallScheduleOut;
export type UpdateOnCallScheduleApiArg = {
  id: number;
  onCallScheduleCreate: OnCallScheduleCreate;
};
export type GetPatientGroupAgeRestrictionsApiResponse =
  /** status 200 Successful Response */ PatientGroupAgeRestrictionOut[];
export type GetPatientGroupAgeRestrictionsApiArg = {
  id?: number;
  "id:in"?: string;
  patientGroupId?: number;
  "patient_group_id:gt"?: number;
  "patient_group_id:lt"?: number;
  "patient_group_id:gte"?: number;
  "patient_group_id:lte"?: number;
  "patient_group_id:in"?: string;
  ageGroupId?: number;
  "age_group_id:gt"?: number;
  "age_group_id:lt"?: number;
  "age_group_id:gte"?: number;
  "age_group_id:lte"?: number;
  "age_group_id:in"?: string;
};
export type CreatePatientGroupAgeRestrictionApiResponse =
  /** status 200 Successful Response */ PatientGroupAgeRestrictionOut;
export type CreatePatientGroupAgeRestrictionApiArg = {
  patientGroupAgeRestrictionCreate: PatientGroupAgeRestrictionCreate;
};
export type CreateBatchPatientGroupAgeRestrictionApiResponse =
  /** status 200 Successful Response */ PatientGroupAgeRestrictionOut[];
export type CreateBatchPatientGroupAgeRestrictionApiArg = {
  inputs: PatientGroupAgeRestrictionCreate[];
};
export type DeleteBatchPatientGroupAgeRestrictionApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchPatientGroupAgeRestrictionApiArg = {
  ids: number[];
};
export type UpdateBatchPatientGroupAgeRestrictionApiResponse =
  /** status 200 Successful Response */ PatientGroupAgeRestrictionOut[];
export type UpdateBatchPatientGroupAgeRestrictionApiArg = {
  inputs: BaseUpdateEntitySchemaPatientGroupAgeRestrictionCreate[];
};
export type DeletePatientGroupAgeRestrictionApiResponse =
  /** status 200 Successful Response */ any;
export type DeletePatientGroupAgeRestrictionApiArg = {
  id: number;
};
export type GetPatientGroupAgeRestrictionApiResponse =
  /** status 200 Successful Response */ PatientGroupAgeRestrictionOut;
export type GetPatientGroupAgeRestrictionApiArg = {
  id: number;
};
export type UpdatePatientGroupAgeRestrictionApiResponse =
  /** status 200 Successful Response */ PatientGroupAgeRestrictionOut;
export type UpdatePatientGroupAgeRestrictionApiArg = {
  id: number;
  patientGroupAgeRestrictionCreate: PatientGroupAgeRestrictionCreate;
};
export type GetPatientGroupDefinitionsApiResponse =
  /** status 200 Successful Response */ PatientGroupDefinitionOut[];
export type GetPatientGroupDefinitionsApiArg = {
  patientGroupDefinitionId?: string;
  "patient_group_definition_id:in"?: string;
  name?: string;
  "name:startsWith"?: string;
  "name:endsWith"?: string;
  "name:contains"?: string;
  "name:in"?: string;
  id?: number;
  "id:in"?: string;
};
export type CreatePatientGroupDefinitionApiResponse =
  /** status 200 Successful Response */ PatientGroupDefinitionOut;
export type CreatePatientGroupDefinitionApiArg = {
  patientGroupDefinitionCreate: PatientGroupDefinitionCreate;
};
export type CreateBatchPatientGroupDefinitionApiResponse =
  /** status 200 Successful Response */ PatientGroupDefinitionOut[];
export type CreateBatchPatientGroupDefinitionApiArg = {
  inputs: PatientGroupDefinitionCreate[];
};
export type DeleteBatchPatientGroupDefinitionApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchPatientGroupDefinitionApiArg = {
  ids: number[];
};
export type UpdateBatchPatientGroupDefinitionApiResponse =
  /** status 200 Successful Response */ PatientGroupDefinitionOut[];
export type UpdateBatchPatientGroupDefinitionApiArg = {
  inputs: BaseUpdateEntitySchemaPatientGroupDefinitionCreate[];
};
export type DeletePatientGroupDefinitionApiResponse =
  /** status 200 Successful Response */ any;
export type DeletePatientGroupDefinitionApiArg = {
  id: number;
};
export type GetPatientGroupDefinitionApiResponse =
  /** status 200 Successful Response */ PatientGroupDefinitionOut;
export type GetPatientGroupDefinitionApiArg = {
  id: number;
};
export type UpdatePatientGroupDefinitionApiResponse =
  /** status 200 Successful Response */ PatientGroupDefinitionOut;
export type UpdatePatientGroupDefinitionApiArg = {
  id: number;
  patientGroupDefinitionCreate: PatientGroupDefinitionCreate;
};
export type GetPatientGroupsApiResponse =
  /** status 200 Successful Response */ PatientGroupOutPopulated[];
export type GetPatientGroupsApiArg = {
  id?: number;
  "id:in"?: string;
  patientGroupId?: string;
  "patient_group_id:in"?: string;
  displayName?: string;
  "display_name:startsWith"?: string;
  "display_name:endsWith"?: string;
  "display_name:contains"?: string;
  "display_name:in"?: string;
  patientGroupDefinitionId?: string;
  "patient_group_definition_id:in"?: string;
  "age_restrictions.age_group_code"?: AgeGroupCode;
  "age_restrictions.age_group_code:in"?: string;
  "age_restrictions.display_name"?: string | null;
  "age_restrictions.display_name:startsWith"?: string | null;
  "age_restrictions.display_name:endsWith"?: string | null;
  "age_restrictions.display_name:contains"?: string | null;
  "age_restrictions.display_name:in"?: string;
  "age_restrictions.minimum_age"?: number | null;
  "age_restrictions.minimum_age:gt"?: number | null;
  "age_restrictions.minimum_age:lt"?: number | null;
  "age_restrictions.minimum_age:gte"?: number | null;
  "age_restrictions.minimum_age:lte"?: number | null;
  "age_restrictions.minimum_age:in"?: string;
  "age_restrictions.maximum_age"?: number | null;
  "age_restrictions.maximum_age:gt"?: number | null;
  "age_restrictions.maximum_age:lt"?: number | null;
  "age_restrictions.maximum_age:gte"?: number | null;
  "age_restrictions.maximum_age:lte"?: number | null;
  "age_restrictions.maximum_age:in"?: string;
  "age_restrictions.id"?: number;
  "age_restrictions.id:in"?: string;
  excludeAgeRestrictions?: boolean;
};
export type CreatePatientGroupApiResponse =
  /** status 200 Successful Response */ PatientGroupOut;
export type CreatePatientGroupApiArg = {
  patientGroupCreate: PatientGroupCreate;
};
export type CreateBatchPatientGroupApiResponse =
  /** status 200 Successful Response */ PatientGroupOutPopulated[];
export type CreateBatchPatientGroupApiArg = {
  inputs: PatientGroupCreate[];
};
export type DeleteBatchPatientGroupApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchPatientGroupApiArg = {
  ids: number[];
};
export type UpdateBatchPatientGroupApiResponse =
  /** status 200 Successful Response */ PatientGroupOut[];
export type UpdateBatchPatientGroupApiArg = {
  inputs: BaseUpdateEntitySchemaPatientGroupCreate[];
};
export type DeletePatientGroupApiResponse =
  /** status 200 Successful Response */ any;
export type DeletePatientGroupApiArg = {
  id: number;
};
export type GetPatientGroupApiResponse =
  /** status 200 Successful Response */ PatientGroupOutPopulated;
export type GetPatientGroupApiArg = {
  id: number;
  excludeAgeRestrictions?: boolean;
};
export type UpdatePatientGroupApiResponse =
  /** status 200 Successful Response */ PatientGroupOut;
export type UpdatePatientGroupApiArg = {
  id: number;
  patientGroupCreate: PatientGroupCreate;
};
export type GetPractitionerScheduleLocationsApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleLocationOutPopulated[];
export type GetPractitionerScheduleLocationsApiArg = {
  practitionerScheduleId?: number;
  "practitioner_schedule_id:in"?: string;
  locationId?: number;
  "location_id:in"?: string;
  id?: number;
  "id:in"?: string;
  "practitioner_schedule.start_time"?: string | null;
  "practitioner_schedule.start_time:gt"?: string | null;
  "practitioner_schedule.start_time:lt"?: string | null;
  "practitioner_schedule.start_time:gte"?: string | null;
  "practitioner_schedule.start_time:lte"?: string | null;
  "practitioner_schedule.start_time:in"?: string;
  "practitioner_schedule.end_time"?: string | null;
  "practitioner_schedule.end_time:gt"?: string | null;
  "practitioner_schedule.end_time:lt"?: string | null;
  "practitioner_schedule.end_time:gte"?: string | null;
  "practitioner_schedule.end_time:lte"?: string | null;
  "practitioner_schedule.end_time:in"?: string;
  "practitioner_schedule.duration"?: string | null;
  "practitioner_schedule.duration:eq"?: string | null;
  "practitioner_schedule.tzid"?: string | null;
  "practitioner_schedule.tzid:startsWith"?: string | null;
  "practitioner_schedule.tzid:endsWith"?: string | null;
  "practitioner_schedule.tzid:contains"?: string | null;
  "practitioner_schedule.tzid:in"?: string;
  "practitioner_schedule.all_day"?: boolean | null;
  "practitioner_schedule.all_day:eq"?: boolean | null;
  "practitioner_schedule.comment"?: string | null;
  "practitioner_schedule.comment:startsWith"?: string | null;
  "practitioner_schedule.comment:endsWith"?: string | null;
  "practitioner_schedule.comment:contains"?: string | null;
  "practitioner_schedule.comment:in"?: string;
  "practitioner_schedule.practitioner_id"?: number;
  "practitioner_schedule.practitioner_id:in"?: string;
  "practitioner_schedule.location_id"?: number | null;
  "practitioner_schedule.location_id:in"?: string;
  "practitioner_schedule.id"?: number;
  "practitioner_schedule.id:in"?: string;
  excludePractitionerSchedule?: boolean;
  "location.id"?: number;
  "location.id:in"?: string;
  "location.room_code"?: string;
  "location.room_code:in"?: string;
  excludeLocation?: boolean;
};
export type CreatePractitionerScheduleLocationApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleLocationOut;
export type CreatePractitionerScheduleLocationApiArg = {
  practitionerScheduleLocationCreate: PractitionerScheduleLocationCreate;
};
export type CreateBatchPractitionerScheduleLocationApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleLocationOutPopulated[];
export type CreateBatchPractitionerScheduleLocationApiArg = {
  inputs: PractitionerScheduleLocationCreate[];
};
export type DeleteBatchPractitionerScheduleLocationApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchPractitionerScheduleLocationApiArg = {
  ids: number[];
};
export type UpdateBatchPractitionerScheduleLocationApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleLocationOut[];
export type UpdateBatchPractitionerScheduleLocationApiArg = {
  inputs: BaseUpdateEntitySchemaPractitionerScheduleLocationCreate[];
};
export type DeletePractitionerScheduleLocationApiResponse =
  /** status 200 Successful Response */ any;
export type DeletePractitionerScheduleLocationApiArg = {
  id: number;
};
export type GetPractitionerScheduleLocationApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleLocationOutPopulated;
export type GetPractitionerScheduleLocationApiArg = {
  id: number;
  excludePractitionerSchedule?: boolean;
  excludeLocation?: boolean;
};
export type UpdatePractitionerScheduleLocationApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleLocationOut;
export type UpdatePractitionerScheduleLocationApiArg = {
  id: number;
  practitionerScheduleLocationCreate: PractitionerScheduleLocationCreate;
};
export type GetPractitionerScheduleStatusesApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleStatusOutPopulated[];
export type GetPractitionerScheduleStatusesApiArg = {
  practitionerScheduleId?: number;
  "practitioner_schedule_id:in"?: string;
  statusCode?: string;
  "status_code:startsWith"?: string;
  "status_code:endsWith"?: string;
  "status_code:contains"?: string;
  "status_code:in"?: string;
  id?: number;
  "id:in"?: string;
  "practitioner_schedule.start_time"?: string | null;
  "practitioner_schedule.start_time:gt"?: string | null;
  "practitioner_schedule.start_time:lt"?: string | null;
  "practitioner_schedule.start_time:gte"?: string | null;
  "practitioner_schedule.start_time:lte"?: string | null;
  "practitioner_schedule.start_time:in"?: string;
  "practitioner_schedule.end_time"?: string | null;
  "practitioner_schedule.end_time:gt"?: string | null;
  "practitioner_schedule.end_time:lt"?: string | null;
  "practitioner_schedule.end_time:gte"?: string | null;
  "practitioner_schedule.end_time:lte"?: string | null;
  "practitioner_schedule.end_time:in"?: string;
  "practitioner_schedule.duration"?: string | null;
  "practitioner_schedule.duration:eq"?: string | null;
  "practitioner_schedule.tzid"?: string | null;
  "practitioner_schedule.tzid:startsWith"?: string | null;
  "practitioner_schedule.tzid:endsWith"?: string | null;
  "practitioner_schedule.tzid:contains"?: string | null;
  "practitioner_schedule.tzid:in"?: string;
  "practitioner_schedule.all_day"?: boolean | null;
  "practitioner_schedule.all_day:eq"?: boolean | null;
  "practitioner_schedule.comment"?: string | null;
  "practitioner_schedule.comment:startsWith"?: string | null;
  "practitioner_schedule.comment:endsWith"?: string | null;
  "practitioner_schedule.comment:contains"?: string | null;
  "practitioner_schedule.comment:in"?: string;
  "practitioner_schedule.practitioner_id"?: number;
  "practitioner_schedule.practitioner_id:in"?: string;
  "practitioner_schedule.location_id"?: number | null;
  "practitioner_schedule.location_id:in"?: string;
  "practitioner_schedule.id"?: number;
  "practitioner_schedule.id:in"?: string;
  excludePractitionerSchedule?: boolean;
};
export type CreatePractitionerScheduleStatusApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleStatusOut;
export type CreatePractitionerScheduleStatusApiArg = {
  practitionerScheduleStatusCreate: PractitionerScheduleStatusCreate;
};
export type CreateBatchPractitionerScheduleStatusApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleStatusOutPopulated[];
export type CreateBatchPractitionerScheduleStatusApiArg = {
  inputs: PractitionerScheduleStatusCreate[];
};
export type DeleteBatchPractitionerScheduleStatusApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchPractitionerScheduleStatusApiArg = {
  ids: number[];
};
export type UpdateBatchPractitionerScheduleStatusApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleStatusOut[];
export type UpdateBatchPractitionerScheduleStatusApiArg = {
  inputs: BaseUpdateEntitySchemaPractitionerScheduleStatusCreate[];
};
export type DeletePractitionerScheduleStatusApiResponse =
  /** status 200 Successful Response */ any;
export type DeletePractitionerScheduleStatusApiArg = {
  id: number;
};
export type GetPractitionerScheduleStatusApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleStatusOutPopulated;
export type GetPractitionerScheduleStatusApiArg = {
  id: number;
  excludePractitionerSchedule?: boolean;
};
export type UpdatePractitionerScheduleStatusApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleStatusOut;
export type UpdatePractitionerScheduleStatusApiArg = {
  id: number;
  practitionerScheduleStatusCreate: PractitionerScheduleStatusCreate;
};
export type GetPractitionerSchedulesApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleOutPopulated[];
export type GetPractitionerSchedulesApiArg = {
  startTime?: string | null;
  "start_time:gt"?: string | null;
  "start_time:lt"?: string | null;
  "start_time:gte"?: string | null;
  "start_time:lte"?: string | null;
  "start_time:in"?: string;
  endTime?: string | null;
  "end_time:gt"?: string | null;
  "end_time:lt"?: string | null;
  "end_time:gte"?: string | null;
  "end_time:lte"?: string | null;
  "end_time:in"?: string;
  duration?: string | null;
  "duration:eq"?: string | null;
  tzid?: string | null;
  "tzid:startsWith"?: string | null;
  "tzid:endsWith"?: string | null;
  "tzid:contains"?: string | null;
  "tzid:in"?: string;
  allDay?: boolean | null;
  "all_day:eq"?: boolean | null;
  comment?: string | null;
  "comment:startsWith"?: string | null;
  "comment:endsWith"?: string | null;
  "comment:contains"?: string | null;
  "comment:in"?: string;
  practitionerId?: number;
  "practitioner_id:in"?: string;
  locationId?: number | null;
  "location_id:in"?: string;
  id?: number;
  "id:in"?: string;
  "location.id"?: number;
  "location.id:in"?: string;
  "location.room_code"?: string;
  "location.room_code:in"?: string;
  excludeLocation?: boolean;
  "practitioner.short_name"?: string;
  "practitioner.short_name:in"?: string;
  "practitioner.id"?: number;
  "practitioner.id:in"?: string;
  excludePractitioner?: boolean;
  "statuses.practitioner_schedule_id"?: number;
  "statuses.practitioner_schedule_id:in"?: string;
  "statuses.status_code"?: string;
  "statuses.status_code:startsWith"?: string;
  "statuses.status_code:endsWith"?: string;
  "statuses.status_code:contains"?: string;
  "statuses.status_code:in"?: string;
  "statuses.id"?: number;
  "statuses.id:in"?: string;
  excludeStatuses?: boolean;
  "locations.practitioner_schedule_id"?: number;
  "locations.practitioner_schedule_id:in"?: string;
  "locations.location_id"?: number;
  "locations.location_id:in"?: string;
  "locations.id"?: number;
  "locations.id:in"?: string;
  excludeLocations?: boolean;
};
export type CreatePractitionerScheduleApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleOut;
export type CreatePractitionerScheduleApiArg = {
  practitionerScheduleCreate: PractitionerScheduleCreate;
};
export type CreateBatchPractitionerScheduleApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleOutPopulated[];
export type CreateBatchPractitionerScheduleApiArg = {
  inputs: PractitionerScheduleCreate[];
};
export type DeleteBatchPractitionerScheduleApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchPractitionerScheduleApiArg = {
  ids: number[];
};
export type UpdateBatchPractitionerScheduleApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleOut[];
export type UpdateBatchPractitionerScheduleApiArg = {
  inputs: BaseUpdateEntitySchemaPractitionerScheduleCreate[];
};
export type DeletePractitionerScheduleApiResponse =
  /** status 200 Successful Response */ any;
export type DeletePractitionerScheduleApiArg = {
  id: number;
};
export type GetPractitionerScheduleApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleOutPopulated;
export type GetPractitionerScheduleApiArg = {
  id: number;
  excludeLocation?: boolean;
  excludePractitioner?: boolean;
  excludeStatuses?: boolean;
  excludeLocations?: boolean;
};
export type UpdatePractitionerScheduleApiResponse =
  /** status 200 Successful Response */ PractitionerScheduleOut;
export type UpdatePractitionerScheduleApiArg = {
  id: number;
  practitionerScheduleCreate: PractitionerScheduleCreate;
};
export type GetPractitionersApiResponse =
  /** status 200 Successful Response */ PractitionerOutPopulated[];
export type GetPractitionersApiArg = {
  shortName?: string;
  "short_name:in"?: string;
  id?: number;
  "id:in"?: string;
  "departments.id"?: number;
  "departments.id:in"?: string;
  "departments.name"?: string;
  "departments.name:startsWith"?: string;
  "departments.name:endsWith"?: string;
  "departments.name:contains"?: string;
  "departments.name:in"?: string;
  "departments.dips_id"?: number | null;
  "departments.dips_id:in"?: string;
  excludeDepartments?: boolean;
};
export type CreatePractitionerApiResponse =
  /** status 200 Successful Response */ PractitionerOut;
export type CreatePractitionerApiArg = {
  practitionerCreate: PractitionerCreate;
};
export type CreateBatchPractitionerApiResponse =
  /** status 200 Successful Response */ PractitionerOutPopulated[];
export type CreateBatchPractitionerApiArg = {
  inputs: PractitionerCreate[];
};
export type DeleteBatchPractitionerApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchPractitionerApiArg = {
  ids: number[];
};
export type UpdateBatchPractitionerApiResponse =
  /** status 200 Successful Response */ PractitionerOut[];
export type UpdateBatchPractitionerApiArg = {
  inputs: BaseUpdateEntitySchemaPractitionerCreate[];
};
export type DeletePractitionerApiResponse =
  /** status 200 Successful Response */ any;
export type DeletePractitionerApiArg = {
  id: number;
};
export type GetPractitionerApiResponse =
  /** status 200 Successful Response */ PractitionerOutPopulated;
export type GetPractitionerApiArg = {
  id: number;
  excludeDepartments?: boolean;
};
export type UpdatePractitionerApiResponse =
  /** status 200 Successful Response */ PractitionerOut;
export type UpdatePractitionerApiArg = {
  id: number;
  practitionerCreate: PractitionerCreate;
};
export type GetRuleDefinitionsApiResponse =
  /** status 200 Successful Response */ RuleDefinitionOut[];
export type GetRuleDefinitionsApiArg = {
  ruleId?: string;
  "rule_id:in"?: string;
  type?: string;
  "type:in"?: string;
  name?: string;
  "name:startsWith"?: string;
  "name:endsWith"?: string;
  "name:contains"?: string;
  "name:in"?: string;
  id?: number;
  "id:in"?: string;
};
export type CreateRuleDefinitionApiResponse =
  /** status 200 Successful Response */ RuleDefinitionOut;
export type CreateRuleDefinitionApiArg = {
  ruleDefinitionCreate: RuleDefinitionCreate;
};
export type CreateBatchRuleDefinitionApiResponse =
  /** status 200 Successful Response */ RuleDefinitionOut[];
export type CreateBatchRuleDefinitionApiArg = {
  inputs: RuleDefinitionCreate[];
};
export type DeleteBatchRuleDefinitionApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchRuleDefinitionApiArg = {
  ids: number[];
};
export type UpdateBatchRuleDefinitionApiResponse =
  /** status 200 Successful Response */ RuleDefinitionOut[];
export type UpdateBatchRuleDefinitionApiArg = {
  inputs: BaseUpdateEntitySchemaRuleDefinitionCreate[];
};
export type DeleteRuleDefinitionApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteRuleDefinitionApiArg = {
  id: number;
};
export type GetRuleDefinitionApiResponse =
  /** status 200 Successful Response */ RuleDefinitionOut;
export type GetRuleDefinitionApiArg = {
  id: number;
};
export type UpdateRuleDefinitionApiResponse =
  /** status 200 Successful Response */ RuleDefinitionOut;
export type UpdateRuleDefinitionApiArg = {
  id: number;
  ruleDefinitionCreate: RuleDefinitionCreate;
};
export type GetSectionsApiResponse =
  /** status 200 Successful Response */ SectionOut[];
export type GetSectionsApiArg = {
  id?: number;
  "id:in"?: string;
  dipsSectionId?: number;
  "dips_section_id:gt"?: number;
  "dips_section_id:lt"?: number;
  "dips_section_id:gte"?: number;
  "dips_section_id:lte"?: number;
  "dips_section_id:in"?: string;
  name?: string;
  "name:startsWith"?: string;
  "name:endsWith"?: string;
  "name:contains"?: string;
  "name:in"?: string;
  departmentId?: number | null;
  "department_id:gt"?: number | null;
  "department_id:lt"?: number | null;
  "department_id:gte"?: number | null;
  "department_id:lte"?: number | null;
  "department_id:in"?: string;
};
export type CreateSectionApiResponse =
  /** status 200 Successful Response */ SectionOut;
export type CreateSectionApiArg = {
  sectionCreate: SectionCreate;
};
export type CreateBatchSectionApiResponse =
  /** status 200 Successful Response */ SectionOut[];
export type CreateBatchSectionApiArg = {
  inputs: SectionCreate[];
};
export type DeleteBatchSectionApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchSectionApiArg = {
  ids: number[];
};
export type UpdateBatchSectionApiResponse =
  /** status 200 Successful Response */ SectionOut[];
export type UpdateBatchSectionApiArg = {
  inputs: BaseUpdateEntitySchemaSectionCreate[];
};
export type DeleteSectionApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteSectionApiArg = {
  id: number;
};
export type GetSectionApiResponse =
  /** status 200 Successful Response */ SectionOut;
export type GetSectionApiArg = {
  id: number;
};
export type UpdateSectionApiResponse =
  /** status 200 Successful Response */ SectionOut;
export type UpdateSectionApiArg = {
  id: number;
  sectionCreate: SectionCreate;
};
export type GetSpecialitiesApiResponse =
  /** status 200 Successful Response */ SpecialityOut[];
export type GetSpecialitiesApiArg = {
  name?: string;
  "name:startsWith"?: string;
  "name:endsWith"?: string;
  "name:contains"?: string;
  "name:in"?: string;
  id?: number;
  "id:in"?: string;
};
export type CreateSpecialityApiResponse =
  /** status 200 Successful Response */ SpecialityOut;
export type CreateSpecialityApiArg = {
  specialityCreate: SpecialityCreate;
};
export type CreateBatchSpecialityApiResponse =
  /** status 200 Successful Response */ SpecialityOut[];
export type CreateBatchSpecialityApiArg = {
  inputs: SpecialityCreate[];
};
export type DeleteBatchSpecialityApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchSpecialityApiArg = {
  ids: number[];
};
export type UpdateBatchSpecialityApiResponse =
  /** status 200 Successful Response */ SpecialityOut[];
export type UpdateBatchSpecialityApiArg = {
  inputs: BaseUpdateEntitySchemaSpecialityCreate[];
};
export type DeleteSpecialityApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteSpecialityApiArg = {
  id: number;
};
export type GetSpecialityApiResponse =
  /** status 200 Successful Response */ SpecialityOut;
export type GetSpecialityApiArg = {
  id: number;
};
export type UpdateSpecialityApiResponse =
  /** status 200 Successful Response */ SpecialityOut;
export type UpdateSpecialityApiArg = {
  id: number;
  specialityCreate: SpecialityCreate;
};
export type GetSurgeryTypeGroupAgeRestrictionsApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupAgeRestrictionOut[];
export type GetSurgeryTypeGroupAgeRestrictionsApiArg = {
  id?: number;
  "id:in"?: string;
  surgeryTypeGroupId?: number;
  "surgery_type_group_id:gt"?: number;
  "surgery_type_group_id:lt"?: number;
  "surgery_type_group_id:gte"?: number;
  "surgery_type_group_id:lte"?: number;
  "surgery_type_group_id:in"?: string;
  ageGroupId?: number;
  "age_group_id:gt"?: number;
  "age_group_id:lt"?: number;
  "age_group_id:gte"?: number;
  "age_group_id:lte"?: number;
  "age_group_id:in"?: string;
};
export type CreateSurgeryTypeGroupAgeRestrictionApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupAgeRestrictionOut;
export type CreateSurgeryTypeGroupAgeRestrictionApiArg = {
  surgeryTypeGroupAgeRestrictionCreate: SurgeryTypeGroupAgeRestrictionCreate;
};
export type CreateBatchSurgeryTypeGroupAgeRestrictionApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupAgeRestrictionOut[];
export type CreateBatchSurgeryTypeGroupAgeRestrictionApiArg = {
  inputs: SurgeryTypeGroupAgeRestrictionCreate[];
};
export type DeleteBatchSurgeryTypeGroupAgeRestrictionApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchSurgeryTypeGroupAgeRestrictionApiArg = {
  ids: number[];
};
export type UpdateBatchSurgeryTypeGroupAgeRestrictionApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupAgeRestrictionOut[];
export type UpdateBatchSurgeryTypeGroupAgeRestrictionApiArg = {
  inputs: BaseUpdateEntitySchemaSurgeryTypeGroupAgeRestrictionCreate[];
};
export type DeleteSurgeryTypeGroupAgeRestrictionApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteSurgeryTypeGroupAgeRestrictionApiArg = {
  id: number;
};
export type GetSurgeryTypeGroupAgeRestrictionApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupAgeRestrictionOut;
export type GetSurgeryTypeGroupAgeRestrictionApiArg = {
  id: number;
};
export type UpdateSurgeryTypeGroupAgeRestrictionApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupAgeRestrictionOut;
export type UpdateSurgeryTypeGroupAgeRestrictionApiArg = {
  id: number;
  surgeryTypeGroupAgeRestrictionCreate: SurgeryTypeGroupAgeRestrictionCreate;
};
export type GetSurgeryTypeGroupHierarchiesApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupHierarchyOut[];
export type GetSurgeryTypeGroupHierarchiesApiArg = {
  id?: number;
  "id:in"?: string;
  parentSurgeryTypeGroupId?: number;
  "parent_surgery_type_group_id:in"?: string;
  childSurgeryTypeGroupId?: number;
  "child_surgery_type_group_id:in"?: string;
};
export type CreateSurgeryTypeGroupHierarchyApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupHierarchyOut;
export type CreateSurgeryTypeGroupHierarchyApiArg = {
  surgeryTypeGroupHierarchyCreate: SurgeryTypeGroupHierarchyCreate;
};
export type CreateBatchSurgeryTypeGroupHierarchyApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupHierarchyOut[];
export type CreateBatchSurgeryTypeGroupHierarchyApiArg = {
  inputs: SurgeryTypeGroupHierarchyCreate[];
};
export type DeleteBatchSurgeryTypeGroupHierarchyApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchSurgeryTypeGroupHierarchyApiArg = {
  ids: number[];
};
export type UpdateBatchSurgeryTypeGroupHierarchyApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupHierarchyOut[];
export type UpdateBatchSurgeryTypeGroupHierarchyApiArg = {
  inputs: BaseUpdateEntitySchemaSurgeryTypeGroupHierarchyCreate[];
};
export type DeleteSurgeryTypeGroupHierarchyApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteSurgeryTypeGroupHierarchyApiArg = {
  id: number;
};
export type GetSurgeryTypeGroupHierarchyApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupHierarchyOut;
export type GetSurgeryTypeGroupHierarchyApiArg = {
  id: number;
};
export type UpdateSurgeryTypeGroupHierarchyApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupHierarchyOut;
export type UpdateSurgeryTypeGroupHierarchyApiArg = {
  id: number;
  surgeryTypeGroupHierarchyCreate: SurgeryTypeGroupHierarchyCreate;
};
export type GetSurgeryTypeGroupSpecialitiesApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupSpecialityOut[];
export type GetSurgeryTypeGroupSpecialitiesApiArg = {
  id?: number;
  "id:in"?: string;
  surgeryTypeGroupId?: number;
  "surgery_type_group_id:in"?: string;
  specialityId?: number;
  "speciality_id:in"?: string;
};
export type CreateSurgeryTypeGroupSpecialityApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupSpecialityOut;
export type CreateSurgeryTypeGroupSpecialityApiArg = {
  surgeryTypeGroupSpecialityCreate: SurgeryTypeGroupSpecialityCreate;
};
export type CreateBatchSurgeryTypeGroupSpecialityApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupSpecialityOut[];
export type CreateBatchSurgeryTypeGroupSpecialityApiArg = {
  inputs: SurgeryTypeGroupSpecialityCreate[];
};
export type DeleteBatchSurgeryTypeGroupSpecialityApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchSurgeryTypeGroupSpecialityApiArg = {
  ids: number[];
};
export type UpdateBatchSurgeryTypeGroupSpecialityApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupSpecialityOut[];
export type UpdateBatchSurgeryTypeGroupSpecialityApiArg = {
  inputs: BaseUpdateEntitySchemaSurgeryTypeGroupSpecialityCreate[];
};
export type DeleteSurgeryTypeGroupSpecialityApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteSurgeryTypeGroupSpecialityApiArg = {
  id: number;
};
export type GetSurgeryTypeGroupSpecialityApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupSpecialityOut;
export type GetSurgeryTypeGroupSpecialityApiArg = {
  id: number;
};
export type UpdateSurgeryTypeGroupSpecialityApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupSpecialityOut;
export type UpdateSurgeryTypeGroupSpecialityApiArg = {
  id: number;
  surgeryTypeGroupSpecialityCreate: SurgeryTypeGroupSpecialityCreate;
};
export type GetSurgeryTypeGroupsApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupOut[];
export type GetSurgeryTypeGroupsApiArg = {
  code?: string;
  "code:in"?: string;
  displayName?: string;
  "display_name:startsWith"?: string;
  "display_name:endsWith"?: string;
  "display_name:contains"?: string;
  "display_name:in"?: string;
  displayChildren?: boolean;
  "display_children:eq"?: boolean;
  hidden?: boolean;
  "hidden:eq"?: boolean;
  groupType?: SurgeryTypeGroupEnum;
  "group_type:startsWith"?: SurgeryTypeGroupEnum;
  "group_type:endsWith"?: SurgeryTypeGroupEnum;
  "group_type:contains"?: SurgeryTypeGroupEnum;
  "group_type:in"?: string;
  id?: number;
  "id:in"?: string;
};
export type CreateSurgeryTypeGroupApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupOut;
export type CreateSurgeryTypeGroupApiArg = {
  surgeryTypeGroupCreate: SurgeryTypeGroupCreate;
};
export type CreateBatchSurgeryTypeGroupApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupOut[];
export type CreateBatchSurgeryTypeGroupApiArg = {
  inputs: SurgeryTypeGroupCreate[];
};
export type DeleteBatchSurgeryTypeGroupApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchSurgeryTypeGroupApiArg = {
  ids: number[];
};
export type UpdateBatchSurgeryTypeGroupApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupOut[];
export type UpdateBatchSurgeryTypeGroupApiArg = {
  inputs: BaseUpdateEntitySchemaSurgeryTypeGroupCreate[];
};
export type DeleteSurgeryTypeGroupApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteSurgeryTypeGroupApiArg = {
  id: number;
};
export type GetSurgeryTypeGroupApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupOut;
export type GetSurgeryTypeGroupApiArg = {
  id: number;
};
export type UpdateSurgeryTypeGroupApiResponse =
  /** status 200 Successful Response */ SurgeryTypeGroupOut;
export type UpdateSurgeryTypeGroupApiArg = {
  id: number;
  surgeryTypeGroupCreate: SurgeryTypeGroupCreate;
};
export type VersionApiResponse = /** status 200 Successful Response */ Version;
export type VersionApiArg = void;
export type GetWaitingListItemsApiResponse =
  /** status 200 Successful Response */ WaitingListItemOut[];
export type GetWaitingListItemsApiArg = {
  id?: number;
  "id:in"?: string;
  bookingId?: string;
  "booking_id:startsWith"?: string;
  "booking_id:endsWith"?: string;
  "booking_id:contains"?: string;
  "booking_id:in"?: string;
  comment?: string;
  "comment:startsWith"?: string;
  "comment:endsWith"?: string;
  "comment:contains"?: string;
  "comment:in"?: string;
};
export type CreateWaitingListItemApiResponse =
  /** status 200 Successful Response */ WaitingListItemOut;
export type CreateWaitingListItemApiArg = {
  waitingListItemCreate: WaitingListItemCreate;
};
export type CreateBatchWaitingListItemApiResponse =
  /** status 200 Successful Response */ WaitingListItemOut[];
export type CreateBatchWaitingListItemApiArg = {
  inputs: WaitingListItemCreate[];
};
export type DeleteBatchWaitingListItemApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteBatchWaitingListItemApiArg = {
  ids: number[];
};
export type UpdateBatchWaitingListItemApiResponse =
  /** status 200 Successful Response */ WaitingListItemOut[];
export type UpdateBatchWaitingListItemApiArg = {
  inputs: BaseUpdateEntitySchemaWaitingListItemCreate[];
};
export type DeleteWaitingListItemApiResponse =
  /** status 200 Successful Response */ any;
export type DeleteWaitingListItemApiArg = {
  id: number;
};
export type GetWaitingListItemApiResponse =
  /** status 200 Successful Response */ WaitingListItemOut;
export type GetWaitingListItemApiArg = {
  id: number;
};
export type UpdateWaitingListItemApiResponse =
  /** status 200 Successful Response */ WaitingListItemOut;
export type UpdateWaitingListItemApiArg = {
  id: number;
  waitingListItemCreate: WaitingListItemCreate;
};
export type AgeGroupCode = "child" | "adult" | "unspecified";
export type AgeGroupOut = {
  age_group_code: AgeGroupCode;
  created_at: string;
  display_name: string | null;
  entity_type: "age_group";
  generated: boolean;
  id: number;
  is_active: boolean;
  maximum_age?: number | null;
  minimum_age?: number | null;
  order?: number;
  updated_at: string;
  updated_by?: string | null;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type AgeGroupCreate = {
  age_group_code: AgeGroupCode;
  display_name: string | null;
  maximum_age?: number | null;
  minimum_age?: number | null;
  order?: number;
};
export type BaseUpdateEntitySchemaAgeGroupCreate = {
  body: AgeGroupCreate;
  id: number;
};
export type PatientGroup = {
  age_group?: AgeGroupCode | null;
  patient_group_id: string;
};
export type MaxCount = {
  max_count: number;
  patient_group: PatientGroup;
};
export type MaxCountOfSurgeriesPerSurgeryTypeGroup = {
  constraints: MaxCount[];
};
export type MaxCountRuleOut = {
  comment?: string | null;
  created_at: string;
  generated: boolean;
  id: number;
  is_active: boolean;
  name: string;
  parameters: MaxCountOfSurgeriesPerSurgeryTypeGroup;
  rule_definition_id: "max_count_of_surgeries_per_patient_group";
  type?: "capacity";
  updated_at: string;
  updated_by?: string | null;
};
export type MaxWeight = {
  max_weight: number;
  patient_group: PatientGroup;
};
export type MaxWeightOfSurgeriesPerSurgeryTypeGroup = {
  constraints: MaxWeight[];
};
export type MaxWeightRuleOut = {
  comment?: string | null;
  created_at: string;
  generated: boolean;
  id: number;
  is_active: boolean;
  name: string;
  parameters: MaxWeightOfSurgeriesPerSurgeryTypeGroup;
  rule_definition_id: "max_weight_of_surgeries_per_patient_group";
  type?: "capacity";
  updated_at: string;
  updated_by?: string | null;
};
export type PatientGroupContainer = {
  patient_group: PatientGroup;
};
export type MaxDuration = {
  max_duration: string;
  patient_groups: PatientGroupContainer[];
};
export type MaxDurationOfSurgeriesPerSurgeryTypeGroup = {
  constraints: MaxDuration;
};
export type MaxDurationRuleOut = {
  comment?: string | null;
  created_at: string;
  generated: boolean;
  id: number;
  is_active: boolean;
  name: string;
  parameters: MaxDurationOfSurgeriesPerSurgeryTypeGroup;
  rule_definition_id: "max_duration_of_surgeries_per_patient_group";
  type?: "capacity";
  updated_at: string;
  updated_by?: string | null;
};
export type MaxCountPerPatientGroup = {
  max_count: number;
  patient_group: PatientGroup;
};
export type MaxDurationAndMaxCount = {
  max_duration: string;
  patient_groups: MaxCountPerPatientGroup[];
};
export type MaxDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup = {
  constraints: MaxDurationAndMaxCount;
};
export type MaxDurationAndCountRuleOut = {
  comment?: string | null;
  created_at: string;
  generated: boolean;
  id: number;
  is_active: boolean;
  name: string;
  parameters: MaxDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup;
  rule_definition_id: "max_duration_and_max_count_of_surgeries_per_patient_group";
  type?: "capacity";
  updated_at: string;
  updated_by?: string | null;
};
export type MaxKnifeDuration = {
  max_duration: string;
  patient_groups: PatientGroupContainer[];
};
export type MaxKnifeDurationOfSurgeriesPerSurgeryTypeGroup = {
  constraints: MaxKnifeDuration;
};
export type MaxKnifeDurationRuleOut = {
  comment?: string | null;
  created_at: string;
  generated: boolean;
  id: number;
  is_active: boolean;
  name: string;
  parameters: MaxKnifeDurationOfSurgeriesPerSurgeryTypeGroup;
  rule_definition_id: "max_knife_time_of_surgeries_per_patient_group";
  type?: "capacity";
  updated_at: string;
  updated_by?: string | null;
};
export type MaxKnifeDurationAndMaxCount = {
  max_duration: string;
  patient_groups: MaxCountPerPatientGroup[];
};
export type MaxKnifeDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup = {
  constraints: MaxKnifeDurationAndMaxCount;
};
export type MaxKnifeDurationAndCountRuleOut = {
  comment?: string | null;
  created_at: string;
  generated: boolean;
  id: number;
  is_active: boolean;
  name: string;
  parameters: MaxKnifeDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup;
  rule_definition_id: "max_knife_time_and_max_count_of_surgeries_per_patient_group";
  type?: "capacity";
  updated_at: string;
  updated_by?: string | null;
};
export type BlockExceptionOutPopulated = {
  block_schedule_id: number;
  created_at: string;
  end_time: string;
  entity_type: "block_exception";
  generated: boolean;
  id: number;
  is_active: boolean;
  rule_instance?:
    | MaxCountRuleOut
    | MaxWeightRuleOut
    | MaxDurationRuleOut
    | MaxDurationAndCountRuleOut
    | MaxKnifeDurationRuleOut
    | MaxKnifeDurationAndCountRuleOut
    | null;
  rule_instance_id: number;
  start_time: string;
  updated_at: string;
  updated_by?: string | null;
};
export type BlockExceptionOut = {
  block_schedule_id: number;
  created_at: string;
  end_time: string;
  entity_type: "block_exception";
  generated: boolean;
  id: number;
  is_active: boolean;
  rule_instance?:
    | MaxCountRuleOut
    | MaxWeightRuleOut
    | MaxDurationRuleOut
    | MaxDurationAndCountRuleOut
    | MaxKnifeDurationRuleOut
    | MaxKnifeDurationAndCountRuleOut
    | null;
  rule_instance_id: number;
  start_time: string;
  updated_at: string;
  updated_by?: string | null;
};
export type MaxCount2 = {
  max_count: number;
  patient_group: PatientGroup;
};
export type MaxCountOfSurgeriesPerSurgeryTypeGroup2 = {
  constraints: MaxCount2[];
};
export type MaxCountRuleCreate = {
  comment?: string | null;
  name: string;
  parameters: MaxCountOfSurgeriesPerSurgeryTypeGroup2;
  rule_definition_id: "max_count_of_surgeries_per_patient_group";
};
export type MaxCountPerPatientGroup2 = {
  max_count: number;
  patient_group: PatientGroup;
};
export type MaxDurationAndMaxCount2 = {
  max_duration: string;
  patient_groups: MaxCountPerPatientGroup2[];
};
export type MaxDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup2 = {
  constraints: MaxDurationAndMaxCount2;
};
export type MaxDurationAndCountRuleCreate = {
  comment?: string | null;
  name: string;
  parameters: MaxDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup2;
  rule_definition_id: "max_duration_and_max_count_of_surgeries_per_patient_group";
};
export type PatientGroupContainer2 = {
  patient_group: PatientGroup;
};
export type MaxDuration2 = {
  max_duration: string;
  patient_groups: PatientGroupContainer2[];
};
export type MaxDurationOfSurgeriesPerSurgeryTypeGroup2 = {
  constraints: MaxDuration2;
};
export type MaxDurationRuleCreate = {
  comment?: string | null;
  name: string;
  parameters: MaxDurationOfSurgeriesPerSurgeryTypeGroup2;
  rule_definition_id: "max_duration_of_surgeries_per_patient_group";
};
export type MaxKnifeDurationAndMaxCount2 = {
  max_duration: string;
  patient_groups: MaxCountPerPatientGroup2[];
};
export type MaxKnifeDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup2 = {
  constraints: MaxKnifeDurationAndMaxCount2;
};
export type MaxKnifeDurationAndCountRuleCreate = {
  comment?: string | null;
  name: string;
  parameters: MaxKnifeDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup2;
  rule_definition_id: "max_knife_time_and_max_count_of_surgeries_per_patient_group";
};
export type MaxKnifeDuration2 = {
  max_duration: string;
  patient_groups: PatientGroupContainer2[];
};
export type MaxKnifeDurationOfSurgeriesPerSurgeryTypeGroup2 = {
  constraints: MaxKnifeDuration2;
};
export type MaxKnifeDurationRuleCreate = {
  comment?: string | null;
  name: string;
  parameters: MaxKnifeDurationOfSurgeriesPerSurgeryTypeGroup2;
  rule_definition_id: "max_knife_time_of_surgeries_per_patient_group";
};
export type MaxWeight2 = {
  max_weight: number;
  patient_group: PatientGroup;
};
export type MaxWeightOfSurgeriesPerSurgeryTypeGroup2 = {
  constraints: MaxWeight2[];
};
export type MaxWeightRuleCreate = {
  comment?: string | null;
  name: string;
  parameters: MaxWeightOfSurgeriesPerSurgeryTypeGroup2;
  rule_definition_id: "max_weight_of_surgeries_per_patient_group";
};
export type BlockExceptionCreate = {
  block_schedule_id: number;
  end_time: string;
  rule_instance:
    | (
        | ({
            rule_definition_id: "max_count_of_surgeries_per_patient_group";
          } & MaxCountRuleCreate)
        | ({
            rule_definition_id: "max_duration_and_max_count_of_surgeries_per_patient_group";
          } & MaxDurationAndCountRuleCreate)
        | ({
            rule_definition_id: "max_duration_of_surgeries_per_patient_group";
          } & MaxDurationRuleCreate)
        | ({
            rule_definition_id: "max_knife_time_and_max_count_of_surgeries_per_patient_group";
          } & MaxKnifeDurationAndCountRuleCreate)
        | ({
            rule_definition_id: "max_knife_time_of_surgeries_per_patient_group";
          } & MaxKnifeDurationRuleCreate)
        | ({
            rule_definition_id: "max_weight_of_surgeries_per_patient_group";
          } & MaxWeightRuleCreate)
      )
    | null;
  start_time: string;
};
export type BaseUpdateEntitySchemaBlockExceptionCreate = {
  body: BlockExceptionCreate;
  id: number;
};
export type LocationOut = {
  created_at: string;
  entity_type: "location";
  generated: boolean;
  id: number;
  is_active: boolean;
  room_code: string;
  updated_at: string;
  updated_by?: string | null;
};
export type BlockLockOutPopulated = {
  created_at: string;
  end_time: string;
  entity_type: "block_lock";
  fingerprint?: string | null;
  generated: boolean;
  id: number;
  is_active: boolean;
  location?: LocationOut | null;
  location_id: number;
  start_time: string;
  updated_at: string;
  updated_by?: string | null;
};
export type BlockLockOut = {
  created_at: string;
  end_time: string;
  entity_type: "block_lock";
  fingerprint?: string | null;
  generated: boolean;
  id: number;
  is_active: boolean;
  location_id: number;
  start_time: string;
  updated_at: string;
  updated_by?: string | null;
};
export type BlockLockCreate = {
  end_time: string;
  fingerprint?: string | null;
  location_id: number;
  start_time: string;
};
export type BaseUpdateEntitySchemaBlockLockCreate = {
  body: BlockLockCreate;
  id: number;
};
export type BlockScheduleOutPopulated = {
  all_day?: boolean | null;
  comment?: string | null;
  created_at: string;
  date?: string | null;
  duration?: string | null;
  end_time?: string | null;
  entity_type: "block_schedule";
  generated: boolean;
  id: number;
  is_active: boolean;
  location?: LocationOut | null;
  location_id?: number | null;
  rule_instance?:
    | MaxCountRuleOut
    | MaxWeightRuleOut
    | MaxDurationRuleOut
    | MaxDurationAndCountRuleOut
    | MaxKnifeDurationRuleOut
    | MaxKnifeDurationAndCountRuleOut
    | null;
  rule_instance_id?: number | null;
  start_time?: string | null;
  tzid?: string | null;
  updated_at: string;
  updated_by?: string | null;
};
export type MaxCountRuleCreate2 = {
  comment?: string | null;
  name: string;
  parameters: MaxCountOfSurgeriesPerSurgeryTypeGroup;
  rule_definition_id: "max_count_of_surgeries_per_patient_group";
};
export type MaxWeightRuleCreate2 = {
  comment?: string | null;
  name: string;
  parameters: MaxWeightOfSurgeriesPerSurgeryTypeGroup;
  rule_definition_id: "max_weight_of_surgeries_per_patient_group";
};
export type MaxDurationRuleCreate2 = {
  comment?: string | null;
  name: string;
  parameters: MaxDurationOfSurgeriesPerSurgeryTypeGroup;
  rule_definition_id: "max_duration_of_surgeries_per_patient_group";
};
export type MaxDurationAndCountRuleCreate2 = {
  comment?: string | null;
  name: string;
  parameters: MaxDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup;
  rule_definition_id: "max_duration_and_max_count_of_surgeries_per_patient_group";
};
export type MaxKnifeDurationRuleCreate2 = {
  comment?: string | null;
  name: string;
  parameters: MaxKnifeDurationOfSurgeriesPerSurgeryTypeGroup;
  rule_definition_id: "max_knife_time_of_surgeries_per_patient_group";
};
export type MaxKnifeDurationAndCountRuleCreate2 = {
  comment?: string | null;
  name: string;
  parameters: MaxKnifeDurationAndMaxCountOfSurgeriesPerSurgeryTypeGroup;
  rule_definition_id: "max_knife_time_and_max_count_of_surgeries_per_patient_group";
};
export type BlockScheduleOut = {
  all_day?: boolean | null;
  comment?: string | null;
  created_at: string;
  date?: string | null;
  duration?: string | null;
  end_time?: string | null;
  entity_type: "block_schedule";
  generated: boolean;
  id: number;
  is_active: boolean;
  location_id?: number | null;
  rule_instance?:
    | MaxCountRuleCreate2
    | MaxWeightRuleCreate2
    | MaxDurationRuleCreate2
    | MaxDurationAndCountRuleCreate2
    | MaxKnifeDurationRuleCreate2
    | MaxKnifeDurationAndCountRuleCreate2
    | null;
  rule_instance_id?: number | null;
  start_time?: string | null;
  tzid?: string | null;
  updated_at: string;
  updated_by?: string | null;
};
export type BlockScheduleCreate = {
  all_day?: boolean | null;
  comment?: string | null;
  duration?: string | null;
  end_time?: string | null;
  location_id?: number | null;
  rule_instance?:
    | MaxCountRuleCreate
    | MaxWeightRuleCreate
    | MaxDurationRuleCreate
    | MaxDurationAndCountRuleCreate
    | MaxKnifeDurationRuleCreate
    | MaxKnifeDurationAndCountRuleCreate
    | null;
  start_time?: string | null;
  tzid?: string | null;
};
export type BaseUpdateEntitySchemaBlockScheduleCreate = {
  body: BlockScheduleCreate;
  id: number;
};
export type DepartmentLocationAssignmentOut = {
  created_at: string;
  department_id: number;
  entity_type: "department_location_assignment";
  generated: boolean;
  id: number;
  is_active: boolean;
  location_id: number;
  updated_at: string;
  updated_by?: string | null;
};
export type DepartmentLocationAssignmentCreate = {
  department_id: number;
  location_id: number;
};
export type BaseUpdateEntitySchemaDepartmentLocationAssignmentCreate = {
  body: DepartmentLocationAssignmentCreate;
  id: number;
};
export type DepartmentPractitionerAssignmentOut = {
  created_at: string;
  department_id: number;
  entity_type: "department_practitioner_assignment";
  generated: boolean;
  id: number;
  is_active: boolean;
  order?: number | null;
  practitioner_id: number;
  updated_at: string;
  updated_by?: string | null;
};
export type DepartmentPractitionerAssignmentCreate = {
  department_id: number;
  order?: number | null;
  practitioner_id: number;
};
export type BaseUpdateEntitySchemaDepartmentPractitionerAssignmentCreate = {
  body: DepartmentPractitionerAssignmentCreate;
  id: number;
};
export type PractitionerOut = {
  created_at: string;
  entity_type: "practitioner";
  generated: boolean;
  id: number;
  is_active: boolean;
  short_name: string;
  updated_at: string;
  updated_by?: string | null;
};
export type SectionOut = {
  created_at: string;
  department_id?: number | null;
  dips_section_id: number;
  entity_type: "section";
  generated: boolean;
  id: number;
  is_active: boolean;
  name: string;
  updated_at: string;
  updated_by?: string | null;
};
export type DepartmentOutPopulated = {
  created_at: string;
  dips_id?: number | null;
  entity_type: "department";
  generated: boolean;
  id: number;
  is_active: boolean;
  locations?: LocationOut[] | null;
  name: string;
  practitioners?: PractitionerOut[] | null;
  sections?: SectionOut[] | null;
  updated_at: string;
  updated_by?: string | null;
};
export type DepartmentOut = {
  created_at: string;
  dips_id?: number | null;
  entity_type: "department";
  generated: boolean;
  id: number;
  is_active: boolean;
  name: string;
  updated_at: string;
  updated_by?: string | null;
};
export type DepartmentCreate = {
  dips_id?: number | null;
  name: string;
};
export type BaseUpdateEntitySchemaDepartmentCreate = {
  body: DepartmentCreate;
  id: number;
};
export type HospitalSurgeryTypeOut = {
  created_at: string;
  entity_type: "hospital_surgery_type";
  generated: boolean;
  hospital_surgery_type_id: number;
  hospital_surgery_type_name: string;
  id: number;
  is_active: boolean;
  updated_at: string;
  updated_by?: string | null;
};
export type HospitalSurgeryTypeGroupAssociationOut = {
  created_at: string;
  entity_type: "hospital_surgery_type_group_association";
  generated: boolean;
  hospital_surgery_type_id: number;
  id: number;
  is_active: boolean;
  surgery_type?: HospitalSurgeryTypeOut | null;
  surgery_type_group_id: number;
  updated_at: string;
  updated_by?: string | null;
  weight?: number;
};
export type HospitalSurgeryTypeGroupAssociationCreate = {
  hospital_surgery_type_id: number;
  surgery_type_group_id: number;
  weight?: number;
};
export type BaseUpdateEntitySchemaHospitalSurgeryTypeGroupAssociationCreate = {
  body: HospitalSurgeryTypeGroupAssociationCreate;
  id: number;
};
export type HospitalSurgeryTypeCreate = {
  hospital_surgery_type_id: number;
  hospital_surgery_type_name: string;
};
export type BaseUpdateEntitySchemaHospitalSurgeryTypeCreate = {
  body: HospitalSurgeryTypeCreate;
  id: number;
};
export type LocationScheduleOutPopulated = {
  all_day?: boolean | null;
  comment?: string | null;
  created_at: string;
  date?: string | null;
  duration?: string | null;
  end_time?: string | null;
  entity_type: "location_schedule";
  generated: boolean;
  id: number;
  is_active: boolean;
  location?: LocationOut | null;
  location_id: number;
  start_time?: string | null;
  tzid?: string | null;
  updated_at: string;
  updated_by?: string | null;
};
export type LocationScheduleOut = {
  all_day?: boolean | null;
  comment?: string | null;
  created_at: string;
  date?: string | null;
  duration?: string | null;
  end_time?: string | null;
  entity_type: "location_schedule";
  generated: boolean;
  id: number;
  is_active: boolean;
  location_id: number;
  start_time?: string | null;
  tzid?: string | null;
  updated_at: string;
  updated_by?: string | null;
};
export type LocationScheduleCreate = {
  all_day?: boolean | null;
  comment?: string | null;
  duration?: string | null;
  end_time?: string | null;
  location_id: number;
  start_time?: string | null;
  tzid?: string | null;
};
export type BaseUpdateEntitySchemaLocationScheduleCreate = {
  body: LocationScheduleCreate;
  id: number;
};
export type LocationOutPopulated = {
  created_at: string;
  departments?: DepartmentOut[] | null;
  entity_type: "location";
  generated: boolean;
  id: number;
  is_active: boolean;
  room_code: string;
  updated_at: string;
  updated_by?: string | null;
};
export type LocationCreate = {
  room_code: string;
};
export type BaseUpdateEntitySchemaLocationCreate = {
  body: LocationCreate;
  id: number;
};
export type OAuthIssuerName =
  | "dfs"
  | "dex"
  | "google"
  | "ext_oidc_provider"
  | "mock";
export type AccessTokenResponse = {
  access_token: string;
  issuer: OAuthIssuerName;
  token_exp: number | null;
};
export type UserInfo = {
  name: string;
};
export type UserInfoResponse = {
  access_token: string;
  issuer: OAuthIssuerName;
  token_exp: number | null;
  userinfo: UserInfo;
};
export type OnCallScheduleOutPopulated = {
  all_day?: boolean | null;
  comment?: string | null;
  created_at: string;
  date?: string | null;
  duration?: string | null;
  end_time?: string | null;
  entity_type: "on_call_schedule";
  generated: boolean;
  id: number;
  is_active: boolean;
  practitioner?: PractitionerOut | null;
  practitioner_id: number;
  start_time?: string | null;
  tzid?: string | null;
  updated_at: string;
  updated_by?: string | null;
};
export type OnCallScheduleOut = {
  all_day?: boolean | null;
  comment?: string | null;
  created_at: string;
  date?: string | null;
  duration?: string | null;
  end_time?: string | null;
  entity_type: "on_call_schedule";
  generated: boolean;
  id: number;
  is_active: boolean;
  practitioner_id: number;
  start_time?: string | null;
  tzid?: string | null;
  updated_at: string;
  updated_by?: string | null;
};
export type OnCallScheduleCreate = {
  all_day?: boolean | null;
  comment?: string | null;
  duration?: string | null;
  end_time?: string | null;
  practitioner_id: number;
  start_time?: string | null;
  tzid?: string | null;
};
export type BaseUpdateEntitySchemaOnCallScheduleCreate = {
  body: OnCallScheduleCreate;
  id: number;
};
export type PatientGroupAgeRestrictionOut = {
  age_group_id: number;
  created_at: string;
  entity_type: "patient_group_age_restriction";
  generated: boolean;
  id: number;
  is_active: boolean;
  patient_group_id: number;
  updated_at: string;
  updated_by?: string | null;
};
export type PatientGroupAgeRestrictionCreate = {
  age_group_id: number;
  patient_group_id: number;
};
export type BaseUpdateEntitySchemaPatientGroupAgeRestrictionCreate = {
  body: PatientGroupAgeRestrictionCreate;
  id: number;
};
export type Implementation = {
  operator: string;
  path: string;
  type: string;
  value: string;
};
export type PatientGroupDefinitionOut = {
  created_at: string;
  entity_type: "patient_group_definition";
  generated: boolean;
  id: number;
  implementation: Implementation;
  is_active: boolean;
  name: string;
  param_schema: object;
  patient_group_definition_id: string;
  updated_at: string;
  updated_by?: string | null;
};
export type PatientGroupDefinitionCreate = {
  implementation: Implementation;
  name: string;
  param_schema: object;
  patient_group_definition_id: string;
};
export type BaseUpdateEntitySchemaPatientGroupDefinitionCreate = {
  body: PatientGroupDefinitionCreate;
  id: number;
};
export type PatientGroupOutPopulated = {
  age_restrictions?: AgeGroupOut[] | null;
  created_at: string;
  display_name: string;
  entity_type: "patient_group";
  generated: boolean;
  id: number;
  is_active: boolean;
  parameters: object;
  patient_group_definition_id: string;
  patient_group_id: string;
  updated_at: string;
  updated_by?: string | null;
};
export type PatientGroupOut = {
  created_at: string;
  display_name: string;
  entity_type: "patient_group";
  generated: boolean;
  id: number;
  is_active: boolean;
  parameters: object;
  patient_group_definition_id: string;
  patient_group_id: string;
  updated_at: string;
  updated_by?: string | null;
};
export type PatientGroupCreate = {
  display_name: string;
  parameters: object;
  patient_group_definition_id: string;
  patient_group_id: string;
};
export type BaseUpdateEntitySchemaPatientGroupCreate = {
  body: PatientGroupCreate;
  id: number;
};
export type PractitionerScheduleOut = {
  all_day?: boolean | null;
  comment?: string | null;
  created_at: string;
  date?: string | null;
  duration?: string | null;
  end_time?: string | null;
  entity_type: "practitioner_schedule";
  generated: boolean;
  id: number;
  is_active: boolean;
  location_id?: number | null;
  practitioner_id: number;
  start_time?: string | null;
  tzid?: string | null;
  updated_at: string;
  updated_by?: string | null;
};
export type PractitionerScheduleLocationOutPopulated = {
  created_at: string;
  entity_type: "practitioner_schedule_location";
  generated: boolean;
  id: number;
  is_active: boolean;
  location?: LocationOut | null;
  location_id: number;
  practitioner_schedule?: PractitionerScheduleOut | null;
  practitioner_schedule_id: number;
  updated_at: string;
  updated_by?: string | null;
};
export type PractitionerScheduleLocationOut = {
  created_at: string;
  entity_type: "practitioner_schedule_location";
  generated: boolean;
  id: number;
  is_active: boolean;
  location_id: number;
  practitioner_schedule_id: number;
  updated_at: string;
  updated_by?: string | null;
};
export type PractitionerScheduleLocationCreate = {
  location_id: number;
  practitioner_schedule_id: number;
};
export type BaseUpdateEntitySchemaPractitionerScheduleLocationCreate = {
  body: PractitionerScheduleLocationCreate;
  id: number;
};
export type PractitionerScheduleStatusOutPopulated = {
  created_at: string;
  entity_type: "practitioner_schedule_status";
  generated: boolean;
  id: number;
  is_active: boolean;
  practitioner_schedule?: PractitionerScheduleOut | null;
  practitioner_schedule_id: number;
  status_code: string;
  updated_at: string;
  updated_by?: string | null;
};
export type PractitionerScheduleStatusOut = {
  created_at: string;
  entity_type: "practitioner_schedule_status";
  generated: boolean;
  id: number;
  is_active: boolean;
  practitioner_schedule_id: number;
  status_code: string;
  updated_at: string;
  updated_by?: string | null;
};
export type PractitionerScheduleStatusCreate = {
  practitioner_schedule_id: number;
  status_code: string;
};
export type BaseUpdateEntitySchemaPractitionerScheduleStatusCreate = {
  body: PractitionerScheduleStatusCreate;
  id: number;
};
export type PractitionerScheduleOutPopulated = {
  all_day?: boolean | null;
  comment?: string | null;
  created_at: string;
  date?: string | null;
  duration?: string | null;
  end_time?: string | null;
  entity_type: "practitioner_schedule";
  generated: boolean;
  id: number;
  is_active: boolean;
  location?: LocationOut | null;
  location_id?: number | null;
  locations?: PractitionerScheduleLocationOut[] | null;
  practitioner?: PractitionerOut | null;
  practitioner_id: number;
  start_time?: string | null;
  statuses?: PractitionerScheduleStatusOut[] | null;
  tzid?: string | null;
  updated_at: string;
  updated_by?: string | null;
};
export type PractitionerScheduleCreate = {
  all_day?: boolean | null;
  comment?: string | null;
  duration?: string | null;
  end_time?: string | null;
  location_id?: number | null;
  practitioner_id: number;
  start_time?: string | null;
  tzid?: string | null;
};
export type BaseUpdateEntitySchemaPractitionerScheduleCreate = {
  body: PractitionerScheduleCreate;
  id: number;
};
export type PractitionerOutPopulated = {
  created_at: string;
  departments?: DepartmentOut[] | null;
  entity_type: "practitioner";
  generated: boolean;
  id: number;
  is_active: boolean;
  short_name: string;
  updated_at: string;
  updated_by?: string | null;
};
export type PractitionerCreate = {
  short_name: string;
};
export type BaseUpdateEntitySchemaPractitionerCreate = {
  body: PractitionerCreate;
  id: number;
};
export type RuleDefinitionOut = {
  composition: object;
  created_at: string;
  entity_type: "rule_definition";
  generated: boolean;
  id: number;
  is_active: boolean;
  name: string;
  param_schema: object;
  rule_id: string;
  type: string;
  updated_at: string;
  updated_by?: string | null;
};
export type RuleDefinitionCreate = {
  composition: object;
  name: string;
  param_schema: object;
  rule_id: string;
  type: string;
};
export type BaseUpdateEntitySchemaRuleDefinitionCreate = {
  body: RuleDefinitionCreate;
  id: number;
};
export type SectionCreate = {
  department_id?: number | null;
  dips_section_id: number;
  name: string;
};
export type BaseUpdateEntitySchemaSectionCreate = {
  body: SectionCreate;
  id: number;
};
export type SpecialityOut = {
  created_at: string;
  entity_type: "speciality";
  generated: boolean;
  id: number;
  is_active: boolean;
  name: string;
  updated_at: string;
  updated_by?: string | null;
};
export type SpecialityCreate = {
  name: string;
};
export type BaseUpdateEntitySchemaSpecialityCreate = {
  body: SpecialityCreate;
  id: number;
};
export type SurgeryTypeGroupAgeRestrictionOut = {
  age_group_id: number;
  created_at: string;
  entity_type: "surgery_type_group_age_restriction";
  generated: boolean;
  id: number;
  is_active: boolean;
  surgery_type_group_id: number;
  updated_at: string;
  updated_by?: string | null;
};
export type SurgeryTypeGroupAgeRestrictionCreate = {
  age_group_id: number;
  surgery_type_group_id: number;
};
export type BaseUpdateEntitySchemaSurgeryTypeGroupAgeRestrictionCreate = {
  body: SurgeryTypeGroupAgeRestrictionCreate;
  id: number;
};
export type SurgeryTypeGroupHierarchyOut = {
  child_surgery_type_group_id: number;
  created_at: string;
  entity_type: "surgery_type_group_hierarchy";
  generated: boolean;
  id: number;
  is_active: boolean;
  parent_surgery_type_group_id: number;
  updated_at: string;
  updated_by?: string | null;
  weight?: number | null;
};
export type SurgeryTypeGroupHierarchyCreate = {
  child_surgery_type_group_id: number;
  parent_surgery_type_group_id: number;
  weight?: number | null;
};
export type BaseUpdateEntitySchemaSurgeryTypeGroupHierarchyCreate = {
  body: SurgeryTypeGroupHierarchyCreate;
  id: number;
};
export type SurgeryTypeGroupSpecialityOut = {
  created_at: string;
  entity_type: "surgery_type_group_speciality";
  generated: boolean;
  id: number;
  is_active: boolean;
  speciality_id: number;
  surgery_type_group_id: number;
  updated_at: string;
  updated_by?: string | null;
};
export type SurgeryTypeGroupSpecialityCreate = {
  speciality_id: number;
  surgery_type_group_id: number;
};
export type BaseUpdateEntitySchemaSurgeryTypeGroupSpecialityCreate = {
  body: SurgeryTypeGroupSpecialityCreate;
  id: number;
};
export type SurgeryTypeGroupEnum =
  | "category"
  | "composite"
  | "speciality"
  | "subspeciality";
export type SurgeryTypeGroupOut = {
  code: string;
  created_at: string;
  display_children?: boolean;
  display_name: string;
  entity_type: "surgery_type_group";
  generated: boolean;
  group_type: SurgeryTypeGroupEnum;
  hidden?: boolean;
  id: number;
  is_active: boolean;
  updated_at: string;
  updated_by?: string | null;
};
export type SurgeryTypeGroupCreate = {
  code: string;
  display_children?: boolean;
  display_name: string;
  group_type: SurgeryTypeGroupEnum;
  hidden?: boolean;
};
export type BaseUpdateEntitySchemaSurgeryTypeGroupCreate = {
  body: SurgeryTypeGroupCreate;
  id: number;
};
export type Version = {
  sha: string;
  version: string;
};
export type WaitingListItemOut = {
  booking_id: string;
  comment: string;
  created_at: string;
  entity_type: "waiting_list_item";
  generated: boolean;
  id: number;
  is_active: boolean;
  updated_at: string;
  updated_by?: string | null;
};
export type WaitingListItemCreate = {
  booking_id: string;
  comment: string;
};
export type BaseUpdateEntitySchemaWaitingListItemCreate = {
  body: WaitingListItemCreate;
  id: number;
};
export const {
  useGetAgeGroupsQuery,
  useCreateAgeGroupMutation,
  useCreateBatchAgeGroupMutation,
  useDeleteBatchAgeGroupMutation,
  useUpdateBatchAgeGroupMutation,
  useDeleteAgeGroupMutation,
  useGetAgeGroupQuery,
  useUpdateAgeGroupMutation,
  useAuthRedirectAuthGetQuery,
  useGetBlockExceptionsQuery,
  useCreateBlockExceptionMutation,
  useCreateBatchBlockExceptionMutation,
  useDeleteBatchBlockExceptionMutation,
  useUpdateBatchBlockExceptionMutation,
  useDeleteBlockExceptionMutation,
  useGetBlockExceptionQuery,
  useUpdateBlockExceptionMutation,
  useGetBlockLocksQuery,
  useCreateBlockLockMutation,
  useCreateBatchBlockLockMutation,
  useDeleteBatchBlockLockMutation,
  useUpdateBatchBlockLockMutation,
  useDeleteBlockLockMutation,
  useGetBlockLockQuery,
  useUpdateBlockLockMutation,
  useGetBlockSchedulesQuery,
  useCreateBlockScheduleMutation,
  useCreateBatchBlockScheduleMutation,
  useDeleteBatchBlockScheduleMutation,
  useUpdateBatchBlockScheduleMutation,
  useDeleteBlockScheduleMutation,
  useGetBlockScheduleQuery,
  useUpdateBlockScheduleMutation,
  useGetDepartmentLocationAssignmentsQuery,
  useCreateDepartmentLocationAssignmentMutation,
  useCreateBatchDepartmentLocationAssignmentMutation,
  useDeleteBatchDepartmentLocationAssignmentMutation,
  useUpdateBatchDepartmentLocationAssignmentMutation,
  useDeleteDepartmentLocationAssignmentMutation,
  useGetDepartmentLocationAssignmentQuery,
  useUpdateDepartmentLocationAssignmentMutation,
  useGetDepartmentPractitionerAssignmentsQuery,
  useCreateDepartmentPractitionerAssignmentMutation,
  useCreateBatchDepartmentPractitionerAssignmentMutation,
  useDeleteBatchDepartmentPractitionerAssignmentMutation,
  useUpdateBatchDepartmentPractitionerAssignmentMutation,
  useDeleteDepartmentPractitionerAssignmentMutation,
  useGetDepartmentPractitionerAssignmentQuery,
  useUpdateDepartmentPractitionerAssignmentMutation,
  useGetDepartmentsQuery,
  useCreateDepartmentMutation,
  useCreateBatchDepartmentMutation,
  useDeleteBatchDepartmentMutation,
  useUpdateBatchDepartmentMutation,
  useDeleteDepartmentMutation,
  useGetDepartmentQuery,
  useUpdateDepartmentMutation,
  useLiveQuery,
  useReadyQuery,
  useGetHospitalSurgeryTypeGroupAssociationsQuery,
  useCreateHospitalSurgeryTypeGroupAssociationMutation,
  useCreateBatchHospitalSurgeryTypeGroupAssociationMutation,
  useDeleteBatchHospitalSurgeryTypeGroupAssociationMutation,
  useUpdateBatchHospitalSurgeryTypeGroupAssociationMutation,
  useDeleteHospitalSurgeryTypeGroupAssociationMutation,
  useGetHospitalSurgeryTypeGroupAssociationQuery,
  useUpdateHospitalSurgeryTypeGroupAssociationMutation,
  useGetHospitalSurgeryTypesQuery,
  useCreateHospitalSurgeryTypeMutation,
  useCreateBatchHospitalSurgeryTypeMutation,
  useDeleteBatchHospitalSurgeryTypeMutation,
  useUpdateBatchHospitalSurgeryTypeMutation,
  useDeleteHospitalSurgeryTypeMutation,
  useGetHospitalSurgeryTypeQuery,
  useUpdateHospitalSurgeryTypeMutation,
  useGetLocationSchedulesQuery,
  useCreateLocationScheduleMutation,
  useCreateBatchLocationScheduleMutation,
  useDeleteBatchLocationScheduleMutation,
  useUpdateBatchLocationScheduleMutation,
  useDeleteLocationScheduleMutation,
  useGetLocationScheduleQuery,
  useUpdateLocationScheduleMutation,
  useGetLocationsQuery,
  useCreateLocationMutation,
  useCreateBatchLocationMutation,
  useDeleteBatchLocationMutation,
  useUpdateBatchLocationMutation,
  useDeleteLocationMutation,
  useGetLocationQuery,
  useUpdateLocationMutation,
  useAuthOauthAuthGetQuery,
  useLoginQuery,
  useLogoutOauthLogoutGetQuery,
  useRevocationOauthRevocationGetQuery,
  useGetOrRefreshTokenOauthTokenGetQuery,
  useGetUserinfoOauthUserinfoGetQuery,
  useGetOnCallSchedulesQuery,
  useCreateOnCallScheduleMutation,
  useCreateBatchOnCallScheduleMutation,
  useDeleteBatchOnCallScheduleMutation,
  useUpdateBatchOnCallScheduleMutation,
  useDeleteOnCallScheduleMutation,
  useGetOnCallScheduleQuery,
  useUpdateOnCallScheduleMutation,
  useGetPatientGroupAgeRestrictionsQuery,
  useCreatePatientGroupAgeRestrictionMutation,
  useCreateBatchPatientGroupAgeRestrictionMutation,
  useDeleteBatchPatientGroupAgeRestrictionMutation,
  useUpdateBatchPatientGroupAgeRestrictionMutation,
  useDeletePatientGroupAgeRestrictionMutation,
  useGetPatientGroupAgeRestrictionQuery,
  useUpdatePatientGroupAgeRestrictionMutation,
  useGetPatientGroupDefinitionsQuery,
  useCreatePatientGroupDefinitionMutation,
  useCreateBatchPatientGroupDefinitionMutation,
  useDeleteBatchPatientGroupDefinitionMutation,
  useUpdateBatchPatientGroupDefinitionMutation,
  useDeletePatientGroupDefinitionMutation,
  useGetPatientGroupDefinitionQuery,
  useUpdatePatientGroupDefinitionMutation,
  useGetPatientGroupsQuery,
  useCreatePatientGroupMutation,
  useCreateBatchPatientGroupMutation,
  useDeleteBatchPatientGroupMutation,
  useUpdateBatchPatientGroupMutation,
  useDeletePatientGroupMutation,
  useGetPatientGroupQuery,
  useUpdatePatientGroupMutation,
  useGetPractitionerScheduleLocationsQuery,
  useCreatePractitionerScheduleLocationMutation,
  useCreateBatchPractitionerScheduleLocationMutation,
  useDeleteBatchPractitionerScheduleLocationMutation,
  useUpdateBatchPractitionerScheduleLocationMutation,
  useDeletePractitionerScheduleLocationMutation,
  useGetPractitionerScheduleLocationQuery,
  useUpdatePractitionerScheduleLocationMutation,
  useGetPractitionerScheduleStatusesQuery,
  useCreatePractitionerScheduleStatusMutation,
  useCreateBatchPractitionerScheduleStatusMutation,
  useDeleteBatchPractitionerScheduleStatusMutation,
  useUpdateBatchPractitionerScheduleStatusMutation,
  useDeletePractitionerScheduleStatusMutation,
  useGetPractitionerScheduleStatusQuery,
  useUpdatePractitionerScheduleStatusMutation,
  useGetPractitionerSchedulesQuery,
  useCreatePractitionerScheduleMutation,
  useCreateBatchPractitionerScheduleMutation,
  useDeleteBatchPractitionerScheduleMutation,
  useUpdateBatchPractitionerScheduleMutation,
  useDeletePractitionerScheduleMutation,
  useGetPractitionerScheduleQuery,
  useUpdatePractitionerScheduleMutation,
  useGetPractitionersQuery,
  useCreatePractitionerMutation,
  useCreateBatchPractitionerMutation,
  useDeleteBatchPractitionerMutation,
  useUpdateBatchPractitionerMutation,
  useDeletePractitionerMutation,
  useGetPractitionerQuery,
  useUpdatePractitionerMutation,
  useGetRuleDefinitionsQuery,
  useCreateRuleDefinitionMutation,
  useCreateBatchRuleDefinitionMutation,
  useDeleteBatchRuleDefinitionMutation,
  useUpdateBatchRuleDefinitionMutation,
  useDeleteRuleDefinitionMutation,
  useGetRuleDefinitionQuery,
  useUpdateRuleDefinitionMutation,
  useGetSectionsQuery,
  useCreateSectionMutation,
  useCreateBatchSectionMutation,
  useDeleteBatchSectionMutation,
  useUpdateBatchSectionMutation,
  useDeleteSectionMutation,
  useGetSectionQuery,
  useUpdateSectionMutation,
  useGetSpecialitiesQuery,
  useCreateSpecialityMutation,
  useCreateBatchSpecialityMutation,
  useDeleteBatchSpecialityMutation,
  useUpdateBatchSpecialityMutation,
  useDeleteSpecialityMutation,
  useGetSpecialityQuery,
  useUpdateSpecialityMutation,
  useGetSurgeryTypeGroupAgeRestrictionsQuery,
  useCreateSurgeryTypeGroupAgeRestrictionMutation,
  useCreateBatchSurgeryTypeGroupAgeRestrictionMutation,
  useDeleteBatchSurgeryTypeGroupAgeRestrictionMutation,
  useUpdateBatchSurgeryTypeGroupAgeRestrictionMutation,
  useDeleteSurgeryTypeGroupAgeRestrictionMutation,
  useGetSurgeryTypeGroupAgeRestrictionQuery,
  useUpdateSurgeryTypeGroupAgeRestrictionMutation,
  useGetSurgeryTypeGroupHierarchiesQuery,
  useCreateSurgeryTypeGroupHierarchyMutation,
  useCreateBatchSurgeryTypeGroupHierarchyMutation,
  useDeleteBatchSurgeryTypeGroupHierarchyMutation,
  useUpdateBatchSurgeryTypeGroupHierarchyMutation,
  useDeleteSurgeryTypeGroupHierarchyMutation,
  useGetSurgeryTypeGroupHierarchyQuery,
  useUpdateSurgeryTypeGroupHierarchyMutation,
  useGetSurgeryTypeGroupSpecialitiesQuery,
  useCreateSurgeryTypeGroupSpecialityMutation,
  useCreateBatchSurgeryTypeGroupSpecialityMutation,
  useDeleteBatchSurgeryTypeGroupSpecialityMutation,
  useUpdateBatchSurgeryTypeGroupSpecialityMutation,
  useDeleteSurgeryTypeGroupSpecialityMutation,
  useGetSurgeryTypeGroupSpecialityQuery,
  useUpdateSurgeryTypeGroupSpecialityMutation,
  useGetSurgeryTypeGroupsQuery,
  useCreateSurgeryTypeGroupMutation,
  useCreateBatchSurgeryTypeGroupMutation,
  useDeleteBatchSurgeryTypeGroupMutation,
  useUpdateBatchSurgeryTypeGroupMutation,
  useDeleteSurgeryTypeGroupMutation,
  useGetSurgeryTypeGroupQuery,
  useUpdateSurgeryTypeGroupMutation,
  useVersionQuery,
  useGetWaitingListItemsQuery,
  useCreateWaitingListItemMutation,
  useCreateBatchWaitingListItemMutation,
  useDeleteBatchWaitingListItemMutation,
  useUpdateBatchWaitingListItemMutation,
  useDeleteWaitingListItemMutation,
  useGetWaitingListItemQuery,
  useUpdateWaitingListItemMutation,
} = injectedRtkApi;

import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'

import { createBlockSchedule, deleteBlockSchedule, updateBlockSchedule } from '~/store/entity.api'
import { Location, selectGetBlockSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { createRuleInstance, RuleDefinitionId, RuleInstanceParameters } from '~/store/utils/ruleInstance'
import { format } from '~/utils/extendedDayjs'

export function useChangeBlockSchedule(date: Dayjs, location: Location, ruleId: RuleDefinitionId, parameters: RuleInstanceParameters) {
    const [isLoading, setIsLoading] = useState(false)
    const addToast = useStore(state => state.toaster.actions.addToast)
    const blockSchedule = useStore(selectGetBlockSchedules).byDateAndLocationId(date, location.id)

    async function removeBlockSchedule() {
        if (!blockSchedule) return

        setIsLoading(true)
        try {
            await deleteBlockSchedule(Number(blockSchedule.id))
        } catch (error) {
            addToast({ type: 'error', message: 'Det oppstod en feil' })
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    async function saveBlockSchedule() {
        if (!parameters) return

        setIsLoading(true)
        try {
            const ruleInstance = createRuleInstance(ruleId, parameters)
            if (!ruleInstance) return

            if (!blockSchedule) {
                await createBlockSchedule({
                    tzid: dayjs.tz.guess(),
                    all_day: true,
                    start_time: format(date),
                    location_id: location.id,
                    rule_instance: ruleInstance,
                })
            } else {
                await updateBlockSchedule(Number(blockSchedule.id), {
                    tzid: dayjs.tz.guess(),
                    all_day: true,
                    start_time: String(blockSchedule.start_time),
                    location_id: Number(blockSchedule.location?.id),
                    rule_instance: ruleInstance,
                })
            }
        } catch (error) {
            addToast({ type: 'error', message: 'Det oppstod en feil' })
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    return { saveBlockSchedule, removeBlockSchedule, isLoading }
}

import stringify from 'fast-safe-stringify'
import { useEffect, useState } from 'react'

import { withImport } from './entity.api'
import { dispatchErrorToast } from './slices/toasterSlice'

type ImportEntity = ReturnType<ReturnType<typeof withImport>>

const isErrorResult = (result: unknown): result is { error: unknown } => {
    return Boolean(result && typeof result === 'object' && 'error' in result)
}

function dispatchError() {
    dispatchErrorToast('Det oppstod en feil. Vennligst prøv igjen senere.')
}

type Options = {
    hasAlreadyData?: boolean
    refetchInterval?: number
    name?: string
}

export function useImportEntities(promisesCallback: () => ImportEntity[], dependencies: unknown[], options?: Options) {
    const [isLoading, setIsLoading] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        let refetchIntervalId: NodeJS.Timeout | null = null
        let hasLoadedOnce = false

        async function mainFetch() {
            // Acts as a check to see if the data is already loaded,
            // only shows loading if its the first time and data is undefined, or empty
            if (!hasLoadedOnce) {
                hasLoadedOnce = true
                setIsLoading(true)
            }

            setIsFetching(true)
            setIsError(false)

            try {
                const results = await Promise.all(promisesCallback())

                const errors = results
                    .filter(isErrorResult)
                    .map(result => result.error)
                    .filter(Boolean)
                if (errors.length) {
                    setIsError(true)
                    console.error(`${errors.length} errors: ${stringify(errors)}`)
                    dispatchError()
                }
            } catch (err) {
                setIsError(true)
                console.error(err)
                dispatchError()
            } finally {
                setIsFetching(false)
                setIsLoading(false)

                if (options?.refetchInterval) {
                    refetchIntervalId = setTimeout(mainFetch, options.refetchInterval)
                }
            }
        }

        const deferTimeoutId = setTimeout(mainFetch, 1)

        return () => {
            clearTimeout(deferTimeoutId)

            if (refetchIntervalId) {
                clearInterval(refetchIntervalId)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies)

    return { isFetching, isError, isLoading }
}

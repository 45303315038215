import { makePatientGroupKey, ResolvedPatientGroup, ScheduledSurgery } from '~/store/selectors'
import { isNotNullish } from '~/utils/guards'

import { CapacityRule } from '../../blocks/types'
import { getPatientGroupToDisplay } from '../../patient_groups/patientGroups'
import { newCountBasedRule, SurgeryCounts } from '../countBasedRule'

function getWeight(surgery: ScheduledSurgery, patientGroup: ResolvedPatientGroup | null): number {
    const surgeriesMetadata = patientGroup?.surgeryTypeGroup?.resolvedSurgeryTypes[surgery.surgeryType?.id ?? -1]
    return surgeriesMetadata?.weight ?? 1
}

export function maxWeightRule(maxWeights: number | null, patientGroup: ResolvedPatientGroup | null): CapacityRule {
    return newCountBasedRule(patientGroup, maxWeights, (surgery: ScheduledSurgery, patientGroup: ResolvedPatientGroup | null) => {
        const occupancy: SurgeryCounts = new Map()
        const weight = getWeight(surgery, patientGroup)
        const patientGroupToDisplay = getPatientGroupToDisplay(surgery, patientGroup)
        if (isNotNullish(patientGroupToDisplay)) {
            const key = makePatientGroupKey(patientGroupToDisplay)
            occupancy.set(key, { patientGroup: patientGroupToDisplay, value: weight, bookingIds: [surgery.id] })
        }
        return occupancy
    })
}

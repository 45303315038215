import { diApi } from '~/store/diApi'

export const entityNames = [
    'age_groups',
    'block_locks',
    'block_schedules',
    'department_location_assignments',
    'department_practitioner_assignments',
    'departments',
    'hospital_surgery_type_group_associations',
    'hospital_surgery_types',
    'location_schedules',
    'locations',
    'on_call_schedules',
    'patient_group_definitions',
    'patient_groups',
    'practitioner_schedule_locations',
    'practitioner_schedule_statuses',
    'practitioner_schedules',
    'practitioners',
    'rule_definitions',
    'sections',
    'specialties',
    'surgery_type_group_age_restrictions',
    'surgery_type_group_hierarchies',
    'surgery_type_group_specialities',
    'surgery_type_groups',
    'waiting_list_items',
] as const
export type EntityName = (typeof entityNames)[number]
export function isEntityName(value: unknown): value is EntityName {
    return typeof value === 'string' && entityNames.includes(value)
}

export const entities = {
    departments: {
        useGetListQuery: diApi.useGetDepartmentsQuery,
        useDeleteItemMutation: diApi.useDeleteDepartmentMutation,
    },
    sections: {
        useGetListQuery: diApi.useGetSectionsQuery,
        useDeleteItemMutation: diApi.useDeleteSectionMutation,
    },
    locations: {
        useGetListQuery: diApi.useGetLocationsQuery,
        useDeleteItemMutation: diApi.useDeleteLocationMutation,
    },
    department_location_assignments: {
        useGetListQuery: diApi.useGetDepartmentLocationAssignmentsQuery,
        useDeleteItemMutation: diApi.useDeleteDepartmentLocationAssignmentMutation,
    },
    practitioners: {
        useGetListQuery: diApi.useGetPractitionersQuery,
        useDeleteItemMutation: diApi.useDeletePractitionerMutation,
    },
    department_practitioner_assignments: {
        useGetListQuery: diApi.useGetDepartmentPractitionerAssignmentsQuery,
        useDeleteItemMutation: diApi.useDeleteDepartmentPractitionerAssignmentMutation,
    },
    patient_groups: {
        useGetListQuery: diApi.useGetPatientGroupsQuery,
        useDeleteItemMutation: diApi.useDeletePatientGroupMutation,
    },
    patient_group_definitions: {
        useGetListQuery: diApi.useGetPatientGroupDefinitionsQuery,
        useDeleteItemMutation: diApi.useDeletePatientGroupDefinitionMutation,
    },
    rule_definitions: {
        useGetListQuery: diApi.useGetRuleDefinitionsQuery,
        useDeleteItemMutation: diApi.useDeleteRuleDefinitionMutation,
    },
    block_schedules: {
        useGetListQuery: diApi.useGetBlockSchedulesQuery,
        useDeleteItemMutation: diApi.useDeleteBlockScheduleMutation,
    },
    block_locks: {
        useGetListQuery: diApi.useGetBlockLocksQuery,
        useDeleteItemMutation: diApi.useDeleteBlockLockMutation,
    },
    location_schedules: {
        useGetListQuery: diApi.useGetLocationSchedulesQuery,
        useDeleteItemMutation: diApi.useDeleteLocationScheduleMutation,
    },
    practitioner_schedules: {
        useGetListQuery: diApi.useGetPractitionerSchedulesQuery,
        useDeleteItemMutation: diApi.useDeletePractitionerScheduleMutation,
    },
    on_call_schedules: {
        useGetListQuery: diApi.useGetOnCallSchedulesQuery,
        useDeleteItemMutation: diApi.useDeleteOnCallScheduleMutation,
    },
    practitioner_schedule_statuses: {
        useGetListQuery: diApi.useGetPractitionerScheduleStatusesQuery,
        useDeleteItemMutation: diApi.useDeletePractitionerScheduleStatusMutation,
    },
    practitioner_schedule_locations: {
        useGetListQuery: diApi.useGetPractitionerScheduleLocationsQuery,
        useDeleteItemMutation: diApi.useDeletePractitionerScheduleLocationMutation,
    },
    waiting_list_items: {
        useGetListQuery: diApi.useGetWaitingListItemsQuery,
        useDeleteItemMutation: diApi.useDeleteWaitingListItemMutation,
    },
    age_groups: {
        useGetListQuery: diApi.useGetAgeGroupsQuery,
        useDeleteItemMutation: diApi.useDeleteAgeGroupMutation,
    },
    hospital_surgery_types: {
        useGetListQuery: diApi.useGetHospitalSurgeryTypesQuery,
        useDeleteItemMutation: diApi.useDeleteHospitalSurgeryTypeMutation,
    },
    surgery_type_groups: {
        useGetListQuery: diApi.useGetSurgeryTypeGroupsQuery,
        useDeleteItemMutation: diApi.useDeleteSurgeryTypeGroupMutation,
    },
    surgery_type_group_hierarchies: {
        useGetListQuery: diApi.useGetSurgeryTypeGroupHierarchiesQuery,
        useDeleteItemMutation: diApi.useDeleteSurgeryTypeGroupHierarchyMutation,
    },
    specialties: {
        useGetListQuery: diApi.useGetSpecialitiesQuery,
        useDeleteItemMutation: diApi.useDeleteSpecialityMutation,
    },
    surgery_type_group_specialities: {
        useGetListQuery: diApi.useGetSurgeryTypeGroupSpecialitiesQuery,
        useDeleteItemMutation: diApi.useDeleteSurgeryTypeGroupSpecialityMutation,
    },
    hospital_surgery_type_group_associations: {
        useGetListQuery: diApi.useGetHospitalSurgeryTypeGroupAssociationsQuery,
        useDeleteItemMutation: diApi.useDeleteHospitalSurgeryTypeGroupAssociationMutation,
    },
    surgery_type_group_age_restrictions: {
        useGetListQuery: diApi.useGetSurgeryTypeGroupAgeRestrictionsQuery,
        useDeleteItemMutation: diApi.useDeleteSurgeryTypeGroupAgeRestrictionMutation,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
} satisfies Record<EntityName, { useGetListQuery: unknown; useDeleteItemMutation: unknown }> as any

export const dateHeaders = ['start_time', 'end_time', 'created_at', 'updated_at']
export const metaHeaders = ['created_at', 'updated_at', 'is_active']

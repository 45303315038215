import clsx from 'clsx'
import { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { createSelector } from 'reselect'

import { getPageKeyByPath, pages, Path } from '~/app/routes'
import { LandingPage, landingPagesOrdered } from '~/store/slices/featureFlagsSlice'
import { AllSlices, useStore } from '~/store/store'
import { analytics } from '~/utils/analytics'

type DINavLinkProps = { to: Path; title: string }

const DINavLink = ({ to, title }: DINavLinkProps) => (
    <NavLink to={to}>
        {({ isActive, isPending }) => (
            <div
                data-test={`${to.trim().replace('/', '')}-link`}
                className={clsx('border-t-2 py-3 hover:border-diBlue-700', {
                    'border-diBlue-700 text-diBlue-700': isActive || isPending,
                    'border-transparent text-gray-900': !isActive && !isPending,
                })}
            >
                <span className="relative -top-0.5">{title}</span>
            </div>
        )}
    </NavLink>
)

const selectNavLinks = createSelector(
    (state: AllSlices) => state.app.activeViews,
    activeViews => {
        const navLinks: Record<LandingPage, DINavLinkProps> = {
            SURGEON_SCHEDULER: { to: activeViews.SURGEON_SCHEDULER, title: pages.SURGEON_SCHEDULER.title },
            WAITING_LIST: { to: activeViews.WAITING_LIST, title: pages.WAITING_LIST.title },
            OPERATIONAL_PLANNER: { to: activeViews.OPERATIONAL_PLANNER, title: pages.OPERATIONAL_PLANNER.title },
            MASTER_SURGERY_SCHEDULER: { to: activeViews.MASTER_SURGERY_SCHEDULER, title: pages.MASTER_SURGERY_SCHEDULER.title },
            ADMIN_PANEL: { to: activeViews.ADMIN_PANEL, title: pages.ADMIN_PANEL.title },
        }

        return navLinks
    }
)

export const Navigation = () => {
    const featureFlags = useStore(state => state.featureFlags)
    const navLinks = useStore(selectNavLinks)
    const setActiveViews = useStore(state => state.app.setActiveViews)

    const location = useLocation()

    useEffect(() => {
        // Put location.pathname into the store
        const pageKey = getPageKeyByPath(location.pathname)
        if (pageKey) {
            setActiveViews({ [pageKey]: location.pathname })
            void analytics?.page(pageKey)
        }
    }, [location, setActiveViews])

    return (
        <div className="flex gap-10" data-test="navigation-bar">
            {landingPagesOrdered.map(landingPage => {
                const navLink = navLinks[landingPage]

                return featureFlags[landingPage] && <DINavLink key={landingPage} to={navLink.to} title={navLink.title} />
            })}
        </div>
    )
}

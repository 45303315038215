import { AgeGroup, Tag, TagProps } from '~/components'
import { ResolvedConstraintObject } from '~/utils/rulesResolver'

type Props = TagProps & {
    resolvedConstraints: ResolvedConstraintObject[]
}

export const RuleValuesTags = ({ resolvedConstraints, ...customTagProps }: Props) => {
    return (
        <>
            {resolvedConstraints?.map((resolved, index) => (
                <Tag key={index} color={resolved.groupLevel} {...customTagProps}>
                    {resolved.value} {resolved.surgeryTypeGroupLabel}
                    <AgeGroup ageGroupCode={resolved.ageCategory} />
                </Tag>
            ))}
        </>
    )
}

import dayjs from 'dayjs'

import { MaxKnifeDurationOfSurgeriesPerSurgeryTypeGroup } from '~/store/diApi'
import { GetResolvedPatientGroups, ResolvedPatientGroup } from '~/store/selectors'
import { isNotNullish, isNullish } from '~/utils/guards'

import { maxCountRule } from '../../rules/implementations/maxCountRule'
import { maxKnifeTimeRule } from '../../rules/implementations/maxKnifeTimeRule'
import { CapacityRule } from '../types'

/**
 * implementation of the block rule: max_knife_time_of_surgeries_per_patient_group
 * @param parameters shall contain the parameters of the rule as an array of type {max_duration: number, patient_groups: string[]}
 */
export function getMaxKnifeTimeRules(parameters: MaxKnifeDurationOfSurgeriesPerSurgeryTypeGroup, getPatientGroups: GetResolvedPatientGroups): CapacityRule[] {
    const capacityRules: CapacityRule[] = []
    const patientGroups: ResolvedPatientGroup[] = []
    if (isNotNullish(parameters.constraints.patient_groups)) {
        for (const constraint of parameters.constraints.patient_groups) {
            const patientGroup = getPatientGroups.byConstraints(constraint.patient_group)

            if (isNullish(patientGroup?.surgeryTypeGroup)) {
                console.error(`Surgery Type group "${constraint.patient_group.patient_group_id}" not found (max_knife_time_of_surgeries_per_patient_group)`)
            } else {
                patientGroups.push(patientGroup)
            }
        }
    }

    const maxDurations = dayjs.duration(parameters.constraints.max_duration)
    capacityRules.push(maxKnifeTimeRule(maxDurations))

    for (const patientGroup of patientGroups) {
        capacityRules.push(maxCountRule(null, patientGroup))
    }

    return capacityRules
}

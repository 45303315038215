import { BlockSchedule, GetResolvedSurgeryTypeGroup } from '~/store/selectors'
import { ResolvedConstraintObject, rulesResolver } from '~/utils/rulesResolver'

type PatientCounts = {
    minCount: number
    maxCount: number
    unresolvedLocations: string[] // Rooms for which the rules could not resolve to a patient count (max duration only)
}

export function formatTotalCounts(patientCounts?: PatientCounts): string {
    if (!patientCounts) return '??'
    return patientCounts.maxCount === patientCounts.minCount ? `${patientCounts.maxCount}` : `${patientCounts.minCount}-${patientCounts.maxCount}`
}

function sumPatientCounts(result: { minCount: number; maxCount: number }, current: ResolvedConstraintObject) {
    return {
        minCount: (result.minCount += current.minCount),
        maxCount: (result.maxCount += current.maxCount),
    }
}

export function calculatePatientCounts(blockSchedules: BlockSchedule[], filteredLocationIds: number[], getSurgeryTypeGroup: GetResolvedSurgeryTypeGroup) {
    const patientCounts = { minCount: 0, maxCount: 0, unresolvedLocations: [] as string[] }

    for (const blockSchedule of blockSchedules) {
        const rules = blockSchedule.rule_instance
        const resolvedParameters = rulesResolver({ rules, getSurgeryTypeGroup })

        const blockScheduleTotals = resolvedParameters.reduce(sumPatientCounts, {
            minCount: 0,
            maxCount: 0,
        })

        // Check that we exclude block schedules that are not in the filtered location list from the total count
        if (!filteredLocationIds.includes(Number(blockSchedule?.location_id))) {
            continue
        }

        if (blockScheduleTotals.maxCount === 0) {
            // This probably came from a constraint type that didn't specify counts or weights (e.g. max duration).
            // We need to highlight these separately in the UI to make it clear there are surgeries scheduled
            // but not included in the total count estimates.
            patientCounts.unresolvedLocations.push(String(blockSchedule?.location?.room_code))
        } else {
            patientCounts.minCount += blockScheduleTotals.minCount
            patientCounts.maxCount += blockScheduleTotals.maxCount
        }
    }

    return patientCounts
}

import { immer } from 'zustand/middleware/immer'

import env from '~/env'

import { Slice } from '../store'

// can be expanded to hold other user preferences such as 'defaultDepartment'
type UserSettings = {
    hideSuggestionsInfo: boolean
}

type User = {
    name: string | null
    apiAccessToken: string | null
    dipsApiAccessToken: string | null
    dipsApiAccessTokenIsFromPKCE: boolean
    idTokenPKCE?: string
    settings: UserSettings
}

const initialState: User = {
    name: null,
    apiAccessToken: null,
    dipsApiAccessToken: null,
    dipsApiAccessTokenIsFromPKCE: false,
    idTokenPKCE: undefined,
    settings: { hideSuggestionsInfo: false },
}

type Actions = {
    actions: {
        setDipsApiAccessToken: (args: {
            dipsApiAccessToken: User['dipsApiAccessToken']
            dipsApiAccessTokenIsFromPKCE: User['dipsApiAccessTokenIsFromPKCE']
            idTokenPKCE: User['idTokenPKCE']
        }) => void
        setAuth: (args: { name?: string | null; accessToken: string | null }) => void
        setHideSuggestionsInfo: (hideInfo: UserSettings['hideSuggestionsInfo']) => void
    }
}

export type UserSliceType = {
    user: User & Actions
}

export const createUserSlice: Slice<UserSliceType> = immer(set => ({
    user: {
        ...initialState,
        actions: {
            setHideSuggestionsInfo: hideInfo => {
                set(state => {
                    state.user.settings.hideSuggestionsInfo = hideInfo
                })
            },
            setDipsApiAccessToken: ({ dipsApiAccessToken, dipsApiAccessTokenIsFromPKCE, idTokenPKCE }) => {
                set(state => {
                    state.user.dipsApiAccessToken = dipsApiAccessToken
                    state.user.dipsApiAccessTokenIsFromPKCE = dipsApiAccessTokenIsFromPKCE
                    state.user.idTokenPKCE = idTokenPKCE
                })
            },
            setAuth: ({ name, accessToken }) => {
                const update: Partial<User> = { apiAccessToken: accessToken }

                if (name !== undefined) {
                    update.name = name
                }

                if (!env.VITE_PKCE_AUTHORIZE_URL) {
                    update.dipsApiAccessToken = accessToken
                    update.dipsApiAccessTokenIsFromPKCE = !accessToken
                }

                set(state => {
                    Object.assign(state.user, update)
                })
            },
        },
    },
}))

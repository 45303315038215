import dayjs from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'

import { MinimalScheduledSurgery } from '~/store/dipsApi'

import { CapacityRule, CapacityRuleTypes } from '../../blocks/types'
import { newDurationBasedRule } from '../durationBasedRule'

const marginInMinutes = 30

export function getKnifeTime(surgery: MinimalScheduledSurgery): Duration {
    return surgery.plannedProcedureDuration ? dayjs.duration(surgery.plannedProcedureDuration) : dayjs.duration(0, 'minutes')
}

export function maxKnifeTimeRule(maxDurations: Duration): CapacityRule {
    return newDurationBasedRule(CapacityRuleTypes.KnifeTimeBased, null, maxDurations, getKnifeTime, marginInMinutes)
}
